import { LocationStrategy } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../common/constants';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Utils } from '../common/utils';
import { environment } from 'src/environments/environment';
import { Route } from '../common/route';
import { GUI } from '../common/gui';

declare function enableMobileToggle(): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(private router: Router, private route: Route, private ngZone: NgZone, private location: LocationStrategy, private utils: Utils, private constants: Constants, private tags: Tags, 
              private localStorage: LocalStorage, private gui: GUI) {
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  openSearchForm(): void {
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_SEARCH, this.utils.encryptString(this.constants.PAYMENT_STATUS)]));
  }

  public doUnload(): void {
    this.doBeforeUnload();
  }

  public doBeforeUnload(): void {
   this.localStorage.removeStorageKey(this.tags.IS_NOTICE_MODAL_OPEN);
  }

  callMobileView(): void {
    enableMobileToggle();
  }

  openTrackApplicationForm(): void {
    window.location.href = environment.trackApplicationUrl;
  }

}
