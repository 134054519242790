<app-header class="hide-at-print"></app-header>

<div class="banner-img-name hide-at-print"></div>

<main class="body_section">

  <div style="width:100%; float:left;">

    <div class="main">
    
      <h3 class="hide-at-print main-heading" style="text-align:center;">Cash At Bank</h3>
    
      <img src="assets/images/logo-nis.png" alt="logo" style="width:140px;float:left" class="h_logo">
    
      <h2 class="slip_txt">Acknowledgement Slip</h2>

      <div class="pay-recipt">

        <table class="payment-table">
          <tr>
            <th width="20%">Transaction No.</th>
            <th width="40%">Application Type</th>
            <th width="20%">Slip Expiry Date</th>
            <th width="20%" style="text-align: right;">Amount to Pay</th>
          </tr>

          <tr>
            <td width="20%" style="text-align: left;"><b>{{personalInfoDetailsObject.txnRefNumber}}</b></td>
            <td width="40%" style="text-align: left"><b>{{passportDetailsObject.passportType}}</b></td>
            <td width="20%" style="text-align: left"><b>{{personalInfoDetailsObject.expiryDate}}</b></td>
            <td width="20%" style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.totalFee}}</b></td>
          </tr>
        </table>

        <div>
          <h4>Application Details</h4>
          <table class="pay-recipt_table">
            <tr>
              <td>Application Number</td>
              <td style="text-align:right;"><b>{{personalInfoDetailsObject.applicationId}}</b></td>
            </tr>

            <tr>
              <td>{{paymentType === constants.APPLICATION_PAYMENT ? 'Reference Number' : 'Passcode'}}</td>
              <td style="text-align:right;"><b>{{personalInfoDetailsObject.referenceNumber}}</b></td>
            </tr>

            <tr>
              <td>Applicant Name</td>
              <td style="text-align:right;"><b>{{personalInfoDetailsObject.fullName}}</b></td>
            </tr>

            <tr>
              <td>Applicant Date Of Birth</td>
              <td style="text-align:right;"><b>{{personalInfoDetailsObject.dateOfBirth}}</b></td>
            </tr>

            <tr>
              <td>Slip Generated on</td>
              <td style="text-align:right;"><b>{{personalInfoDetailsObject.receiptDate}}</b></td>
            </tr>

            <tr>
              <td>Processing Office</td>
              <td style="text-align:right;"><b>{{processingInfoDetailsObject.processingOffice}}</b></td>
            </tr>
          </table>
        </div>

        <div>
          <h4>Payment Details</h4>
          <table class="pay-recipt_table">
            <tr>
              <td>Gateway Name</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.gatewayName}}</b></td>
            </tr>

            <tr>
              <td>Payment Mode</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.paymentMethod}}</b></td>
            </tr>

            <tr *ngIf="feeDetailsObject.codFee > 0">
              <td>COD Fees</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.codFee}}</b></td>
            </tr>

            <tr>
              <td>Application Charges</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.bookletFee}}</b></td>
            </tr>

            <tr *ngIf="feeDetailsObject.deliveryFee > 0">
              <td>Passport Delivery Fee</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.deliveryFee}}</b></td>
            </tr>

            <tr *ngIf="feeDetailsObject.transactionFee > 0">
              <td>Processing Fees</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.transactionFee}}</b></td>
            </tr>

            <tr *ngIf="feeDetailsObject.serviceCharge > 0">
              <td>Service Charge</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.serviceCharge}}</b></td>
            </tr>

            <tr *ngIf="feeDetailsObject.addressVerificationFee > 0">
              <td>Address Verification Charges</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.addressVerificationFee}}</b></td>
            </tr>

            <tr *ngIf="feeDetailsObject.addressVerificationServiceCharge > 0">
              <td>Address Verification Service Charges</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.addressVerificationServiceCharge}}</b></td>
            </tr>

            <tr>
              <td>Total Fee</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.totalFee}}</b></td>
            </tr>
          </table>
        </div>

        <div class="bank-notes" *ngIf="bankNotesList.length > 0">
          <h5>Bank Note</h5>
          <ul>
            <li *ngFor="let note of bankNotesList">{{note}}</li>
          </ul>
        </div>

        <div class="client-notes" style="margin-bottom:0;" *ngIf="clientNotesList.length > 0">
          <h5>Client Note</h5>
          <ul>
            <li *ngFor="let note of clientNotesList">{{note}}</li>
          </ul>
        </div>

      </div>

    </div>

    <div class="hide-at-print" style="text-align:center; margin-bottom: 25px;">
      <input type="button" (click)="backToPaymentPage()" value="Back" class="previous action-button-previous back_btn">

      <input type="button" (click)="printReceipt()" value="Print" class="next action-button custom_btn">
    </div>

  </div>

</main>

<app-footer class="hide-at-print"></app-footer>
