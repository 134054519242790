import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Constants } from '../common/constants';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit, AfterViewInit {

  @Input("currentForm") currentForm: NgForm;  

  @ViewChild('inputText') inputText : NgModel;

  @ViewChild('inputText', {read: ElementRef}) inputCaptchaText: ElementRef<HTMLInputElement>;

  @Output('callbackFunction') callbackFunction: EventEmitter<any> = new EventEmitter();

  config: any = {
    type: 1,
    length: 4,
    cssClass:'custom',
    back: { stroke:"#2F9688", solid:"#f2efd2"} ,
    font: { color:"#000000", size:"35px"}
  };
  
  enteredCaptchaText: string = null;

  code: any = null;

  resultCode: any = null;

  isVerified = false;

  autofocus = true;
  
  constructor(private httpUtils: HttpUtils, private loaderService: LoaderService, private constants: Constants, private localStorage: LocalStorage, private tags: Tags) {

  }

  ngOnInit(): void {
    if (this.config) {
      if (!this.config.font || !this.config.font.size) {
        this.config["font"]["size"] = "40px";
      }

      if (!this.config.font || !this.config.font.family) {
        this.config["font"]["family"] = "Arial";
      }

      if (!this.config.strokeColor) {
        this.config["strokeColor"] = "#f20c6c";
      }

      if (!this.config.length) {
        this.config["length"] = 6;
      }

      if (!this.config.cssClass) {
        this.config["cssClass"] = '';
      }

      if (!this.config.type) {
        this.config["type"] = 1;
      }
      
      if (!this.config.back || !this.config.back.stroke) {
        this.config["back"]["stroke"] = "";
      }

      if (!this.config.back || !this.config.back.solid) {
        this.config["back"]["solid"] = "#f2efd2";
      }

      this.createCaptcha();
    }
  }

  ngAfterViewInit(): void {
    this.currentForm.addControl(this.inputText);
  }

  createCaptcha(): void {
    switch(this.config.type) {
      case 1: 
        this.generateAlphaNumericKey();
        break;
      case 2:
        this.generateNumericKey();
        break;
    }
    setTimeout(() => { this.drawCaptcha();}, 100);
  }

  private generateAlphaNumericKey(): void {
    // let charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let charsArray = "0123456789";
    // let char = Math.random().toString(24).substring(2, this.config.length) + Math.random().toString(24).substring(2, 4);

    let char = [];
    let lengthCaptcha = this.config.length;

    for (var i = 0; i < lengthCaptcha; i++) {
      let index = Math.floor(Math.random() * charsArray.length); //get the next character from the array
      if (char.indexOf(charsArray[index]) == -1){
        char.push(charsArray[index]);
      }
      else i--;
    }

    this.code = this.resultCode = char.join("");
  }

  private generateNumericKey(): void {
    let num1 = Math.floor(Math.random() * 99);
    let num2 = Math.floor(Math.random() * 9);
    let operators = ['+','-'];
    let operator = operators[(Math.floor(Math.random() * operators.length))];
    this.code =  num1+operator+num2+'=?';
    this.resultCode = (operator == '+')? (num1+num2):(num1-num2);
  }

  private drawCaptcha(): void {
    let captcahCanvas: any = document.getElementById("captcahCanvas");
    var ctx = captcahCanvas.getContext("2d");
    ctx.fillStyle = this.config.back.solid;
    ctx.fillRect(0, 0, captcahCanvas.width, captcahCanvas.height);

    ctx.beginPath();

    captcahCanvas.style.letterSpacing = 5 + "px";
    ctx.font = this.config.font.size + " " + this.config.font.family;
    ctx.fillStyle = this.config.font.color;
    ctx.textBaseline = "middle";
    ctx.fillText(this.code, 40, 50);
    if (this.config.back.stroke) {
      ctx.strokeStyle = this.config.back.stroke;
      for (var i = 0; i < 150; i++) {
        ctx.moveTo(Math.random() * 300, Math.random() * 300);
        ctx.lineTo(Math.random() * 300, Math.random() * 300);
      }
      ctx.stroke();
    }
  }

  playCaptcha(): void {
    var msg = new SpeechSynthesisUtterance(this.code.split('').join(' '));
    msg.pitch = 0.1;
    msg.rate = 0.6;
    window.speechSynthesis.speak(msg);
  }

  verifyCaptcha(): void {
    this.isVerified = this.enteredCaptchaText === this.resultCode;

    if(this.isVerified === false){
      this.inputText.control.setErrors({hasError: true});
      return;
    }

    this.loaderService.startLoader(); //Loader will be stopped is callback function.
    this.httpUtils.fetchServerToken().then(serverResponse => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
        this.callbackFunction.emit();
      }
    });
  }

  resetCaptcha(): void {
    this.isVerified = false;
    this.enteredCaptchaText = null;
    this.createCaptcha();
  }

  callOnEnter(): void {
    this.inputCaptchaText.nativeElement.blur();
    this.currentForm.onSubmit(undefined);
  }
 
}
