import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Utils } from '../common/utils';
import { NoticeModalComponent } from '../notice-modal/notice-modal.component';
import { Title } from '@angular/platform-browser';
import { GUI } from '../common/gui';
import { environment } from 'src/environments/environment';
import { Route } from '../common/route';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  usdPassportApplicationUrl: string = environment.usdPassportApplicationUrl;

  passportPremiumServiceInfoUrl: string = environment.passportPremiumServiceInfoUrl;

  constructor(private router: Router, private route: Route, private ngZone: NgZone, private utils: Utils, public constants: Constants, private simpleModalService: SimpleModalService, 
              private tags: Tags, private localStorage: LocalStorage, private title: Title, public gui: GUI) {
    this.title.setTitle('Nigerian Immigration Services | Passport');

    const currentBuildDate = this.localStorage.getStorageKey(this.tags.PROJECT_BUILD_DATE);
    const latestBuildDate = environment.projectBuildDate;
    console.log('Current build date stored in storage is ', currentBuildDate, 'Latest build date is ', latestBuildDate);

    if(this.utils.isEmpty(currentBuildDate) === true){
      this.localStorage.setStorageKey(this.tags.PROJECT_BUILD_DATE, latestBuildDate);
      this.reloadPage();
    } else if(currentBuildDate !== latestBuildDate){
      this.localStorage.setStorageKey(this.tags.PROJECT_BUILD_DATE, latestBuildDate);
      this.reloadPage();
    }
  }

  ngOnInit(): void {
    this.openNoticeModal();
  }

  openApplicationForm(): void {
    this.ngZone.run(()=>this.router.navigate([]));
  }

  openSearchTypeForm(searchType: string): void {
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_SEARCH, this.utils.encryptString(searchType)]));
  }

  openBookAppointmentForm(): void {
    window.open(environment.appointmentApplicationUrl, '_blank');
  }

  openTrackApplicationForm(): void {
    window.location.href = environment.trackApplicationUrl;
  }

  openCentreAvailabilityForm(): void {
    window.open(environment.centreAvailabilityUrl, '_blank');
  }

  openGuarantorForm(): void {
    this.gui.openApplicationGuarantor();
  }

  openTestImageForm(): void {
    this.ngZone.run(()=>this.router.navigate([this.route.IMAGE_TEST]));
  }

  openNoticeModal(): void {
    const isNoticeModalOpenPrevious = this.localStorage.getStorageKey(this.tags.IS_NOTICE_MODAL_OPEN);
    if(this.utils.isEmpty(isNoticeModalOpenPrevious) === true){
      this.localStorage.setStorageKey(this.tags.IS_NOTICE_MODAL_OPEN, this.constants.TRUE.toString());
    } else if(Number(isNoticeModalOpenPrevious) === this.constants.TRUE){
      return;
    }
    const message = 'This is the Passport Application Portal for applicants applying from within Nigeria. If you are applying from within Nigeria, click on the <b>Continue</b> button. <br /> <br />To apply from outside Nigeria, click on <b>Apply from outside Nigeria</b>.'
    this.simpleModalService.addModal(NoticeModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === false){
        window.location.href = this.usdPassportApplicationUrl;
      }
    });
  }

  private reloadPage(): void {
    // window.location.reload();
    // window.location.replace('');
  }

  openPassportPremiumInfoURL(): void {
    window.open(this.passportPremiumServiceInfoUrl, '_blank');
  }

}
