<ng-container *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE">
  <app-header class="view-header"></app-header>
  <div class="banner-img-name view-top"></div>
</ng-container>

<main class="body_section">
  <section class="main profile-view-main" [ngStyle]="{
      width:
        requestResource === constants.REQUEST_RESOURCE_BACK_OFFICE
          ? '100%'
          : '90%'
    }">
    <div class="profile-view">
      <form action="" method="post" name="applicationViewForm" #applicationViewForm="ngForm" autocomplete="off"
        id="msform1">
        <input type="hidden" name="applicationId" value="{{ personalDetailsObject.applicationId }}" />
        <input type="hidden" name="downloadType" value="" />

        <ng-container *ngIf="
            isPaymentDetailsExist === true &&
            personalDetailsObject.applicationStatus === 200
          ">
          <input type="hidden" name="txnReference" value="{{ paymentDetailsObject.merchantRef }}" />
        </ng-container>

        <table class="h_logo" style="margin-bottom: 7px">
          <tr>
            <td style="text-align: center">
              <img src="assets/images/logo-nis.png" style="width: 140px" />
            </td>
          </tr>
        </table>

        <ng-container *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE">
          <div *ngIf="
              personalDetailsObject.approvalStatus === constants.STATUS_QUERY
            " class="hide">
            <h2 style="color: red">
              YOUR APPLICATION HAS BEEN QUERIED. TO RE-UPLOAD QUERIED
              DOCUMENT(S), PLEASE CLICK ON THIS
              <a [href]="personalDetailsObject.reuploadLink" target="_blank"
                style="color: #0087ff; text-decoration: underline">LINK</a>.
            </h2>
          </div>

          <div *ngIf="showPremiumPaymentAlert === true" class="hide">
            <h2 style="color: red">
              This is a Premium Passport Application. Please click
              <a [href]="passportPremiumServiceUrl" target="_blank"
                style="color: #0087ff; text-decoration: underline">here</a>
              to complete your premium application.
            </h2>
          </div>
        </ng-container>

        <div class="s-head">
          <div class="result-header">
            <div class="user_pic" *ngIf="personalDetailsObject.isV2Application === constants.TRUE" style="padding: 2px">
              <img [src]="personalDetailsObject.base64Data" alt="" />
            </div>

            <div class="user-info-detail">
              <div class="upper_bar" style="border-bottom: 1px solid #979797; align-items: center">
                <div *ngIf="isPaymentDetailsExist === true" class="barcode">
                  <ngx-barcode [bc-value]="barCodeValue" [bc-display-value]="true" [bc-width]="1" [bc-height]="40"
                    [bc-font-size]="14"></ngx-barcode>
                </div>

                <div class="visa_type" style="text-align: right">
                  <p>
                    {{ passportTypeDetailsObject.passportType }} Application
                  </p>
                </div>
              </div>

              <div class="common_view">
                <div class="common_view_item">
                  <label>Reference Number</label>
                  <p>{{ personalDetailsObject.referenceNumber }}</p>
                </div>

                <div class="common_view_item">
                  <label>Application Number</label>
                  <p>{{ personalDetailsObject.applicationId }}</p>
                </div>

                <div class="common_view_item">
                  <label>Application Date</label>
                  <p>{{ personalDetailsObject.applicationDate }}</p>
                </div>

                <div class="common_view_item">
                  <label>Payment Status</label>
                  <p>
                    <img [src]="personalDetailsObject.paymentStatusImageURL" class="status_img"
                      style="width: 100%; max-width: 51px" />
                  </p>
                </div>

                <ng-container *ngIf="
                    personalDetailsObject.isV2Application === constants.TRUE &&
                    personalDetailsObject.paymentStatus === 200
                  ">
                  <div class="common_view_item">
                    <label>Approval Status</label>
                    <p [innerHTML]="personalDetailsObject.approvalStatusLabel"></p>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <section id="applicationdetail" class="">
          <div class="s-content">
            <div class="border_line">
              <p class="p-body line pb10">Passport Details</p>
              <div class="sr-p-info">
                <div>
                  <label>Type of Citizenship</label>
                  <p>
                    {{ passportTypeDetailsObject.natureOfCitizenshipLabel }}
                  </p>
                </div>

                <div>
                  <label>Applying For</label>
                  <!-- <p>{{passportTypeDetailsObject.passportType}} {{passportTypeDetailsObject.applyingFor}}</p> -->
                  <p>
                    {{ personalDetailsObject.applyingFor }} -
                    {{ passportTypeDetailsObject.passportType }}
                  </p>
                </div>

                <div>
                  <label>Booklet Type / Validity</label>
                  <p>
                    {{ passportTypeDetailsObject.bookletType }} /
                    {{ passportTypeDetailsObject.passportValidity }}
                  </p>
                </div>

                <ng-container *ngIf="personalDetailsObject.isFreshApplication === false">
                  <div style="border-bottom: 1px solid #aeaeae !important">
                    <label>Passport Number /
                      {{ personalDetailsObject.renewalReasonLabel }}</label>
                    <p>
                      {{ passportTypeDetailsObject.passportNumber }} /
                      {{ passportTypeDetailsObject.changeType }}
                    </p>
                  </div>
                </ng-container>

                <div>
                  <label>Processing State / Processing Office</label>
                  <p>
                    {{ passportTypeDetailsObject.processingState }} /
                    {{ passportTypeDetailsObject.processingOffice }}
                  </p>
                </div>
              </div>
            </div>

            <div class="border_line">
              <p class="p-body line">Personal Details</p>
              <div class="sr-p-info">
                <div>
                  <label>Full Name</label>
                  <p class="p-body">{{ personalDetailsObject.fullName }}</p>
                </div>

                <div>
                  <label>Date of Birth</label>
                  <p>{{ personalDetailsObject.dateOfBirth }}</p>
                </div>

                <div>
                  <label>Gender</label>
                  <p>{{ personalDetailsObject.gender }}</p>
                </div>

                <div>
                  <label>Place of Birth</label>
                  <p>{{ personalDetailsObject.placeOfBirth }}</p>
                </div>

                <div>
                  <label>Country of Birth</label>
                  <p>{{ personalDetailsObject.countryOfBirth }}</p>
                </div>

                <div>
                  <label>Marital Status</label>
                  <p>{{ personalDetailsObject.maritalStatus }}</p>
                </div>

                <div>
                  <label>Occupation</label>
                  <p>{{ personalDetailsObject.occupation }}</p>
                </div>

                <div>
                  <label>Maiden Name</label>
                  <p>{{ personalDetailsObject.motherMaidenName }}</p>
                </div>

                <div>
                  <label>National Identification Number (NIN)</label>
                  <p>{{ personalDetailsObject.ninNumber }}</p>
                </div>

                <div>
                  <label>Email</label>
                  <p>{{ personalDetailsObject.emailId }}</p>
                </div>
              </div>
            </div>

            <div class="border_line mtop20">
              <p class="p-body line pb10">Contact Information</p>
              <div class="sr-p-info">
                <div>
                  <label>Home Town / Village</label>
                  <p>{{ contactDetailsObject.contactCity }}</p>
                </div>

                <div>
                  <label>State of Origin</label>
                  <p>{{ contactDetailsObject.contactState }}</p>
                </div>

                <div>
                  <label>Contact Number</label>
                  <p>
                    {{ contactDetailsObject.mobileNumberDialCode }}
                    {{ contactDetailsObject.mobileNumber }}
                  </p>
                </div>

                <div>
                  <label>Additional Contact Number</label>
                  <p>
                    {{ contactDetailsObject.phoneNumberDialCode }}
                    {{ contactDetailsObject.phoneNumber }}
                  </p>
                </div>

                <div>
                  <label>Permanent Address</label>
                  <p>{{ contactDetailsObject.fullAddress }}</p>
                </div>

                <div>
                  <label>Current Address</label>
                  <p>{{ contactDetailsObject.currentFullAddress }}</p>
                </div>
              </div>
            </div>

            <div class="border_line">
              <p class="p-body line pb10">Kin Information</p>
              <div class="sr-p-info">
                <div>
                  <label>Name of Kin</label>
                  <p>{{ kinDetailsObject.kinName }}</p>
                </div>

                <div>
                  <label>Relationship</label>
                  <p>{{ kinDetailsObject.kinRelation }}</p>
                </div>

                <div>
                  <label>Address</label>
                  <p>{{ kinDetailsObject.fullAddress }}</p>
                </div>

                <div>
                  <label>Contact Number</label>
                  <p>
                    {{ kinDetailsObject.mobileNumberDialCode }}
                    {{ kinDetailsObject.mobileNumber }}
                  </p>
                </div>
              </div>
            </div>

            <div class="border_line page-break" *ngIf="enablePassportDeliveryStage === true">
              <p class="p-body line pb10">Passport Delivery Options</p>
              <div class="sr-p-info">
                <ng-container *ngIf="passportDeliveryDetailsObject === null">
                  <div>
                    <label>Chosen Delivery Option</label>
                    <p>Self pickup from the processing center</p>
                  </div>
                </ng-container>

                <ng-container *ngIf="passportDeliveryDetailsObject !== null">
                  <div>
                    <label>Chosen Delivery Option</label>
                    <p>Delivery by Courier</p>
                  </div>
                  <div>
                    <label>Name of Receiver</label>
                    <p>{{ passportDeliveryDetailsObject.receiverName }}</p>
                  </div>

                  <div>
                    <label>Receiver's E-mail Address</label>
                    <p>{{ passportDeliveryDetailsObject.emailId }}</p>
                  </div>

                  <div>
                    <label>Receiver's Contact Number</label>
                    <p>{{ passportDeliveryDetailsObject.mobileNumberLabel }}</p>
                  </div>

                  <div>
                    <label>Alternate Phone Number</label>
                    <p>{{ passportDeliveryDetailsObject.phoneNumberLabel }}</p>
                  </div>

                  <div>
                    <label>Address</label>
                    <p>
                      {{ passportDeliveryDetailsObject.receiverFullAddress }}
                    </p>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="border_line" *ngIf="codFieldsList.length > 0">
              <div class="cod-change-head">
                <p class="p-body line">Fields to be Changed</p>
                <p class="p-body line">Current Data</p>
                <p class="p-body line">New Data</p>
              </div>
              <table class="cod-change-data">
                <tbody>
                  <tr *ngFor="let codObject of codFieldsList">
                    <td>{{ codObject.fieldLabel }}</td>
                    <td>{{ codObject.dataOnPassport }}</td>
                    <td>{{ codObject.dataOnNIN }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table class="profile_table profile_table_box">
              <tr>
                <td class="pright5">
                  <div class="half_col_box" *ngIf="
                      personalDetailsObject.isV2Application ===
                        constants.TRUE &&
                      applicableDocsList.length > constants.FALSE
                    ">
                    <p class="p-body line pb10">Supporting Documents</p>
                    <div class="tab50">
                      <div class="tab50-inner" *ngFor="
                          let applicableDocSectionObject of applicableDocsList
                        ">
                        <p>{{ applicableDocSectionObject.groupTypeLabel }}</p>

                        <table *ngFor="
                            let applicableDocObject of applicableDocSectionObject.groupDocs
                          " class="profile_table documentt_view" style="margin-top: 0 !important">
                          <tr *ngIf="applicableDocObject.isDocUploaded === true">
                            <td width="90%" style="height: auto !important">
                              <label>{{ applicableDocObject.count }}.
                                {{ applicableDocObject.docTypeLabel }}
                              </label>
                            </td>

                            <td width="10%" style="height: auto !important">
                              <span class="fr"><i (click)="
                                    openFilePreviewModal(applicableDocObject)
                                  " class="fa fa-eye"></i></span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <ng-template *ngIf="
                      personalDetailsObject.isV2Application === constants.FALSE
                    " [ngTemplateOutlet]="feeDetails"></ng-template>
                </td>

                <td>
                  <table class="profile_table" style="margin-top: 0 !important">
                    <tr *ngIf="
                        personalDetailsObject.isV2Application === constants.TRUE
                      ">
                      <td>
                        <ng-template [ngTemplateOutlet]="feeDetails"></ng-template>
                      </td>
                    </tr>

                    <tr class="pro-table-grid" *ngIf="isPaymentDetailsExist === true">
                      <td>
                        <div class="half_col_box">
                          <p class="p-body line pb10">Payment Details</p>
                          <div class="tab50">
                            <div>
                              <label>Payment Date</label>
                              <span class="fr">{{
                                paymentDetailsObject.paymentDate
                                }}</span>
                            </div>

                            <div>
                              <label>Payment Gateway</label>
                              <span class="fr">{{
                                paymentDetailsObject.paymentGateway
                                }}</span>
                            </div>

                            <div>
                              <label>Payment Mode</label>
                              <span class="fr">{{
                                paymentDetailsObject.paymentMethod
                                }}</span>
                            </div>

                            <div>
                              <label>Gateway Reference</label>
                              <span class="fr">{{
                                paymentDetailsObject.gatewayReference
                                }}</span>
                            </div>

                            <div *ngIf="
                                utils.isEmpty(
                                  paymentDetailsObject.validationNo
                                ) === false
                              ">
                              <label>Validation Number</label>
                              <span class="fr">{{
                                paymentDetailsObject.validationNo
                                }}</span>
                            </div>

                            <div *ngIf="
                                utils.isEmpty(paymentDetailsObject.pan) ===
                                false
                              ">
                              <label>PAN</label>
                              <span class="fr">{{
                                paymentDetailsObject.pan
                                }}</span>
                            </div>

                            <div *ngIf="
                                utils.isEmpty(paymentDetailsObject.authCode) ===
                                false
                              ">
                              <label>Auth Code</label>
                              <span class="fr">{{
                                paymentDetailsObject.authCode
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>

          <label *ngIf="personalDetailsObject.applicationStatus === 100" class="t_and_c pb10 hide">
            <input type="checkbox" name="termCondition" [(ngModel)]="isTermConditionSelect" #termCondition="ngModel"
              required="required" />
            <span style="padding: 0 3px">
              I hereby confirm that all details furnished herein are genuine,
              precise, and rightfully associated with my identity. I affirm the
              accuracy of this declaration to the best of my knowledge and
              conviction. In making this solemn affirmation, I do so
              conscientiously and sincerely, believing every piece of
              information provided to be true, in accordance with the Oaths Act,
              2004, Cap 01, Laws of the Federation of Nigeria. I hereby
              acknowledge the legal consequences of submitting inaccurate
              information or committing perjury and I willingly accept all
              lawful penalties applicable for any misinformation provided in
              this document.
            </span>
            <span class="alert alert-danger" role="alert" *ngIf="confirmationButtonClicked && termCondition.invalid">
              <span *ngIf="termCondition.errors.required">
                Confirmation is required
              </span>
            </span>
          </label>
        </section>

        <div *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE" class="view-buttons">
          <input type="button" (click)="backToPage()" value="Close" class="back_btn" />

          <input *ngIf="
              isPaymentDetailsExist === false &&
              personalDetailsObject.paymentStatus === 0
            " type="button" (click)="editApplication()" value="Edit" class="edit_btn" />


          <ng-container *ngIf="personalDetailsObject.applicationStatus === 100">
            <input type="button" (click)="
                showConfirmationForApplicationSubmission(constants.FALSE)
              " value="{{ isContactlessStatus ? 'Confirm':'Confirm & Pay Later' }}" class="save_btn"
              style="margin-right: 5px" />

            <input type="button" (click)="showConfirmationForApplicationSubmission(constants.TRUE)"
              *ngIf="!isContactlessStatus" value="Confirm & Pay Now" value="Confirm & Pay Now" class="custom_btn"
              style="margin-right: 5px" />
          </ng-container>


          <input *ngIf="
             ( isPaymentDetailsExist === false &&
              personalDetailsObject.applicationStatus === 200 &&
              personalDetailsObject.paymentStatus !== 200)
            " type="button" (click)="openPaymentPage()" value="Pay Now" class="custom_btn" style="margin-right: 5px" />



          <input *ngIf="personalDetailsObject.applicationStatus === 200" type="button" (click)="printApplication()"
            value="Print Application" class="save_btn" style="margin-right: 5px" />

          <ng-container *ngIf="
              isPaymentDetailsExist === true &&
              personalDetailsObject.applicationStatus === 200 &&
              personalDetailsObject.paymentStatus === 200
            ">
            <input type="button" (click)="downloadPDF()" value="Download Receipt" class="receipt_btn" />

            <input *ngIf="passportTypeDetailsObject.isPremium === constants.FALSE" type="button"
              (click)="bookAppointment()" value="Book Appointment" class="edit_btn" />

            <input *ngIf="passportTypeDetailsObject.isPremium === constants.TRUE" type="button"
              (click)="openPremiumApplication()" value="Book Premium Appointment" class="edit_btn" />
          </ng-container>
        </div>
      </form>
    </div>
  </section>
</main>

<ng-container *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE">
  <app-footer class="view-footer"></app-footer>
</ng-container>

<ng-template #feeDetails>
  <div class="half_col_box w100">
    <p class="p-body line pb10">Fee Details</p>
    <div class="tab50 fee-tb">
      <div *ngIf="feeDetailsObject.codFee > 0">
        <label>COD Fee</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{ feeDetailsObject.codFee | number : "1.2" : "en-US" }}</span>
      </div>

      <div>
        <label>Passport Booklet Fee</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{ feeDetailsObject.bookletFee | number : "1.2" : "en-US" }}</span>
      </div>

      <div *ngIf="feeDetailsObject.deliveryFee > 0">
        <label>Passport Delivery Fee</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{ feeDetailsObject.deliveryFee | number : "1.2" : "en-US" }}</span>
      </div>

      <div *ngIf="feeDetailsObject.deliveryServiceCharge > 0" class="d-none">
        <label>Passport Delivery Service Charge</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{
          feeDetailsObject.deliveryServiceCharge | number : "1.2" : "en-US"
          }}</span>
      </div>

      <div *ngIf="feeDetailsObject.transactionFee > 0">
        <label>Processing Fee</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{ (feeDetailsObject.deliveryServiceCharge ? (+feeDetailsObject.transactionFee +
          +feeDetailsObject.deliveryServiceCharge) : feeDetailsObject.transactionFee ) | number : "1.2" : "en-US"
          }}</span>
      </div>

      <div *ngIf="feeDetailsObject.addressVerificationFee > 0">
        <label>Address Verification Fee</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{
          feeDetailsObject.addressVerificationFee | number : "1.2" : "en-US"
          }}</span>
      </div>

      <div *ngIf="feeDetailsObject.addressVerificationServiceCharge > 0">
        <label>Address Verification Service Charges</label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{
          feeDetailsObject.addressVerificationServiceCharge
          | number : "1.2" : "en-US"
          }}</span>
      </div>

      <div *ngIf="isContactlessStatus ">
        <label>Contactless Fee </label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{
          50000
          | number : "1.2" : "en-US"
          }}</span>
      </div>

      <!-- <div class="total_box" [style.border-bottom]="
          feeDetailsObject.premiumFee > 0 ? '1px solid #dfdfdf' : '0px'
        ">
        <label>Total Fee</label>
        <span class="fr">

          <b *ngIf="feeDetailsObject.transactionFee === '0.00'">

            {{ feeDetailsObject.currencySymbol }}
            {{(feeDetailsObject.deliveryServiceCharge? feeDetailsObject.totalFee
            -feeDetailsObject.deliveryServiceCharge: feeDetailsObject.totalFee ) | number : "1.2" : "en-US"
            }}
          </b>

          <b *ngIf="feeDetailsObject.transactionFee > '0.00'">

            {{ feeDetailsObject.currencySymbol }}
            {{feeDetailsObject.totalFee | number : "1.2" : "en-US"
            }}
          </b>



        </span>
      </div> -->

      <div *ngIf="feeDetailsObject.premiumFee > 0">
        <label>Passport Premium Service Fee
          <span *ngIf="
              passportTypeDetailsObject.premiumPaymentStatus !==
              constants.STATUS_SUCCESS
            " style="padding: 0">(Payable while booking premium appointment)</span></label>
        <span class="fr">{{ feeDetailsObject.currencySymbol }}
          {{ feeDetailsObject.premiumFee | number : "1.2" : "en-US" }}</span>
      </div>
    </div>
  </div>
</ng-template>