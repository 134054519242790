import { Component, OnInit, ViewChild } from '@angular/core';
import { CroppieOptions, ResultOptions } from 'croppie';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { NgxSuperCroppieComponent } from 'ngx-super-croppie';
import { Constants } from '../common/constants';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

export interface FileUploadInterface {
  applicableDocObject: {};
}
@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.css']
})
export class FileUploadModalComponent extends SimpleModalComponent<FileUploadInterface, {}> implements FileUploadInterface, OnInit {
  
  @ViewChild('ngxSuperCroppie') ngxSuperCroppie: NgxSuperCroppieComponent;

  applicableDocObject: any = {};

  responseObject: any = {};

  showDummyImage: boolean = true;

  enableImageCropper: boolean = false;

  showPDFView: boolean = false;

  croppieOptions: CroppieOptions = this.utils.croppieOptions;

  orientation = this.utils.orientation;

  zoom = this.utils.zoom;
  
  croppedImage: string | HTMLElement | Blob | HTMLCanvasElement;

  resultOptions: ResultOptions = this.utils.resultOptions;

  private isFixedCropper: number = this.constants.FALSE;

  allowFileTypeList: string = 'image/jpg, image/png, image/jpeg, image/gif, application/pdf';

  uploadedFileBase64Data: string = this.constants.BLANK;

  isPDFDoc: boolean = false;

  fileUploadFormObject: any = {};

  croppedImageBase64Data : any = this.constants.BLANK;

  private docSubTypeLabel: string = this.constants.BLANK;

  constructor(public constants: Constants, private toast: Toast, private utils: Utils) { 
    super();
  }

  ngOnInit(): void {
    this.fileUploadFormObject.docSubType = this.constants.BLANK;
    this.croppieOptions.enableResize = this.isFixedCropper === this.constants.FALSE;
  }

  uploadFile(event: any){
    const file: File = event.target.files[0];
    const fileType = file.type;
    this.isPDFDoc = fileType === this.constants.FILE_TYPE_PDF;
    const FileSizeInBytes = file.size;
    const FileSizeInMB = FileSizeInBytes/1024/1024;
    if(FileSizeInMB > this.constants.USER_IMAGE_REQUIRED_SIZE_IN_MB){
      event.target.value = this.constants.BLANK;
      this.toast.showErrorToast("Document size should not be bigger than "+ this.constants.USER_IMAGE_REQUIRED_SIZE_IN_MB + " MB");
      return;
    }
    if(this.allowFileTypeList.includes(fileType) === false){
      event.target.value = this.constants.BLANK;
      this.toast.showErrorToast("File type "+ fileType + " is not allowed");
      return;
    }
    const reader = new FileReader();
    if(this.isPDFDoc === true){
      this.enableImageCropper = false;
      this.showPDFView = true;
      this.showDummyImage = false;
      reader.onloadend = this.handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    } else {
      reader.onloadend = this.handleImageDimensionsReader.bind(this);
      reader.readAsDataURL(file);
    }
  }

  private handleReaderLoaded(event: any) {
    const reader = event.target;
    this.uploadedFileBase64Data = reader.result;
  }

  private handleImageDimensionsReader(event: any) {
    const reader = event.target;
    this.uploadedFileBase64Data = reader.result;
    this.enableImageCropper = true;
    this.showPDFView = false;
    this.showDummyImage = false;
  }

  updateCroppedImage(crop: string | HTMLElement | Blob | HTMLCanvasElement): void {
    this.croppedImage = crop;
    this.croppedImageBase64Data = this.croppedImage.toString();
  }

  setDocSubTypeLabel(event: any): void {
   this.docSubTypeLabel = event.target.options[event.target.options.selectedIndex].text;
  }

  confirm() {
    let finalBase64Data = this.uploadedFileBase64Data;
    finalBase64Data = this.utils.isEmpty(this.croppedImageBase64Data) === false ? this.croppedImageBase64Data : finalBase64Data;
    this.result = {docSubType: this.fileUploadFormObject.docSubType, docSubTypeLabel: this.docSubTypeLabel, base64Data: finalBase64Data, isPDFDoc: this.isPDFDoc};
    this.close();
  }

  cancel() {
    this.result = {};
    this.close();
  }

}