import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Tags {

    LOGGED_USER_ID = 'PASSPORT_LOGGED_USER_ID';

    LOGGED_USER_NAME = 'PASSPORT_LOGGED_USER_NAME';

    AUTH_TOKEN = 'AUTH_TOKEN';

    KEYCLOAK_TOKEN = 'KEYCLOAK_TOKEN';

    KEYCLOAK_REFRESH_TOKEN = 'KEYCLOAK_REFRESH_TOKEN';

    IS_NOTICE_MODAL_OPEN = 'IS_NOTICE_MODAL_OPEN';

    EMAIL_ID = 'emailId';
    
    APPLICATION_TOKEN = 'APPLICATION_CREST';

    PROJECT_BUILD_DATE = 'PROJECT_BUILD_DATE';

    RESPONSE_CODE = 'responseCode';

    RESPONSE_MESSAGE = 'responseMsg';

    USER_TYPE_JSON_KEY = 'userTypeJSONKey';
    
}
