import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Constants } from '../common/constants';

@Directive({
  selector: '[appFocus]'
})
export class FocusDirective {

    constructor(private el: ElementRef, private constants: Constants) { }

    @HostListener('submit', ['$event'])
    public onSubmit(event: any): void {
        const invalidElements = this.el.nativeElement.querySelectorAll('.ng-invalid');
        if (invalidElements.length > 0) {
            const altAttribute = document.createAttribute("alt");
            altAttribute.value = this.constants.TRUE.toString();
            invalidElements[0].setAttributeNode(altAttribute);
            invalidElements[0].focus();
        }
    }

}