<div class="image_upload_container">

    <div class="modal-header">

        <h4>Upload Photo</h4>

    </div>

    <form #imageUploadForm="ngForm" (ngSubmit)="imageUploadForm.form.valid && confirm()" name="imageUploadForm" id="msform" autocomplete="off" style="padding: 0;">

        <ng-template #instructions>
            <ul class="details_photo">
                <li> Image size should be less than {{constants.USER_IMAGE_REQUIRED_SIZE_IN_MB}}MB.  </li>
                <li> Image resolution should be 600x800 px.</li>
                <li> Must have white background.</li>
                <li> Hair should not cover the face or ears.</li>
                <li> Don't use any apparel-headphone, mask etc.</li>
                <li> Should have proper lighting-Neither too dark, nor too bright.</li>
            </ul>
        </ng-template>
        
        <div class="modal-body">

            <div class="centered">

                <details>
                    <summary>Photo Instructions </summary>
                    <div class="content">
                        <img src="assets/images/dummy.png" alt="dummy">
                        <ng-template [ngTemplateOutlet]="instructions"></ng-template> 
                    </div>
                </details>
                
                <div class="grid-photo-two">

                    <div class="grid-boxs">

                        <div *ngIf="showDummyImage === true" class="photo_box icon_dummy">
                            <i class="fa fa-file-image-o" aria-hidden="true"></i>
                        </div>

                        <ngx-super-croppie
                            *ngIf="enableImageCropper"
                            #ngxSuperCroppie
                            [croppieOptions]="croppieOptions"
                            [url]="uploadedFileBase64Data"
                            [orientation]="orientation"
                            [zoom]="zoom"
                            [resultOptions]="resultOptions"
                            (result)="updateCroppedImage($event)"
                        ></ngx-super-croppie>
                        <p style="position: relative;padding-top: 13px;">
                            <span style="position: absolute; top: 0;left: 0;" class="alert alert-danger" role="alert" *ngIf="imageUploadForm.submitted && base64Data.invalid">
                                <span *ngIf="base64Data.errors.required">
                                    Photo is required!
                                </span>
                            </span>
                        </p>

                        <div class="file_upload1 w100 mb10">
                            <input type="hidden" name="base64Data" [(ngModel)]="uploadedFileBase64Data" #base64Data="ngModel" required="required">
                           
                            <button type="button" class="filt--upload">
                                <input type="file" [accept]="requestObject.allowFileTypeList" (change)="uploadFile($event)" id="myFile" class="upload up">
                                <span id="upload" class="upl">Browse</span>
                            </button>

                            <div style="margin-top: 10px;">
                                
                                <div class="flex_row image_upload_radio_container">
                                    <span>ICAO Exception: </span>

                                    <span class="flex_row radio-span">
                                        <input type="radio" name="faceDeformation" id="faceDeformation_false" [(ngModel)]="haveFaceDeformation" [value]="constants.FALSE" class="image_upload_radio">    
                                        <label for="faceDeformation_false">No</label>
                                    </span>

                                    <span class="flex_row radio-span">
                                        <input type="radio" name="faceDeformation" id="faceDeformation_true" [(ngModel)]="haveFaceDeformation" [value]="constants.TRUE" class="image_upload_radio">
                                        <label for="faceDeformation_true">Yes</label>
                                    </span>

                                </div>

                                <i class="image_upload_exception">ICAO Exception should be selected if there is any reason why you can't upload your photograph due to your facial appearance.</i>

                                <label *ngIf="haveFaceDeformation === constants.TRUE" style="color: red; text-wrap: nowrap;">Note: You will be required to upload a medical certificate.</label>
                            </div>
                        </div>

                        <div class="flex_row mt10 center-content">
                        
                        </div>

                    </div>

                    <div class="grid-boxs">
                        
                        <div class="instructionss">

                            <div class="element none_dis">
                                <div class="grid-two">
                                    <div class="grid-boxs">
                                        <h4>Passport Photo Dimensions</h4>
                                        <img src="assets/images/dummy.png" alt="dummy">
                                    </div>
                                    <div class="grid-boxs">
                                        <h4 >Note:</h4>
                                        <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                        
                                    </div>
                                </div>
                                
                            
                            </div>

                        </div>

                        <div *ngIf="compliants.length > constants.FALSE" class="issue_box">
                            
                            <h4 style="color:red;"> ICAO Compliance Issue(s) </h4>
                            
                            <ul class="issues">
                            
                                <li *ngFor="let compliant of compliants" >{{compliant}}</li>
                            
                            </ul>
                            
                        </div>

                    </div>

                </div>

            </div>
        
        </div>

        <div class="modal-footer">
            <button type="submit" class="btn">Submit</button>
            <button type="button" class="back_btn" (click)="cancel()">Cancel</button>
        </div>

    </form>

</div>
