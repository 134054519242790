import { Component, NgZone, OnInit } from '@angular/core';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Utils } from '../common/utils';
import { Route } from '../common/route';
import { Constants } from '../common/constants';
import { Toast } from '../common/toast';
import { HttpUtils } from '../common/http-utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-rave-payment-response',
  templateUrl: './rave-payment.component.html',
  styleUrls: ['./rave-payment.component.css']
})
export class RavePaymentComponent implements OnInit {
  applicationId: string = "";
  referenceId: string = "";
  private paymentSuccessForm : HTMLFormElement;

  private encryptedApplicantId : string = this.constants.BLANK;

  private encryptedTxnRefNumber : string = this.constants.BLANK;


  paymentStatus: 'success' | 'error' | null = null;
    isLoading = true;
    paymentInfo: any;



  paymentDetailsObject : any = {};
  constructor(private router: Router, private ngZone: NgZone,  private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants,
    private utils: Utils, private loaderService: LoaderService, private route: Route) {
this.activatedRoute.params.subscribe((params: Params) => {
this.encryptedApplicantId = params["paymentInfo"];
// this.encryptedTxnRefNumber = params["txnRefNumber"];
console.log("first", this.encryptedApplicantId)

});


// this.route.queryParams.subscribe(params => {
//   const paymentInfoString = params['paymentInfo'];
//   if (paymentInfoString) {
//       const paymentInfo = JSON.parse(paymentInfoString);
//       console.log(paymentInfo); // Now you can use paymentInfo as needed
//   }
// });


this.checkPaymentStatus();
}

  private getApplicationInfo(): void {
    this.applicationId = JSON.parse(localStorage.getItem("applicationId")) || null;
    this.referenceId = JSON.parse(localStorage.getItem("referenceId")) || null;
    this.loaderService.stopLoader();
    if (this.utils.isEmpty(this.applicationId) || this.utils.isEmpty(this.referenceId)) {
      // this.gui.openDashboardPage();
    }
  }

  ngOnInit(): void {
    this.getApplicationInfo();

   // Subscribe to route parameters
   this.activatedRoute.params.subscribe((params: Params) => {
    this.encryptedApplicantId = params["paymentInfo"];  // Accessing the route parameter 'paymentInfo'
    console.log("Encrypted Applicant ID:", this.encryptedApplicantId);
  });

  // Subscribe to query parameters
  this.activatedRoute.queryParams.subscribe((params) => {
    const paymentInfoString = params['paymentInfo'];  // Accessing the query parameter 'paymentInfo'
    console.log("Query Payment Info:", paymentInfoString);
  });

  
  }

  ngOnDestroy(): void {
    this.clearApplicationInfo();
  }

  private clearApplicationInfo(): void {
    localStorage.removeItem("applicationId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("contactlessStatus");
  }

  private checkPaymentStatus(){
    this.loaderService.startLoader();
    // const decryptedTxnRefNumber = this.utils.decryptServerEncryptedString(this.encryptedTxnRefNumber);
    // this.httpUtils.checkPaymentStatus(decryptedTxnRefNumber).then(serverResponse => {
    //   this.loaderService.stopLoader();
    //   if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
    //     this.toast.showSuccessToast("Your payment has been made successfully.");
    //     // this.paymentDetailsObject = serverResponse;
    //   } else {
    //     this.toast.showErrorToast(serverResponse.responseMsg);
    //     this.viewApplication();
    //   }
    // });
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate([this.route.HOME]));
  }

  openApplicationPage(){
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_ADD_EDIT, this.utils.encryptString(this.paymentDetailsObject.emailId)]));
  }

  viewApplication(){
    const serverDecryptedApplicantId = this.utils.decryptServerEncryptedString(this.encryptedApplicantId);
    this.ngZone.run(()=>this.router.navigate([this.route.APPLICATION_VIEW, this.utils.encryptString(serverDecryptedApplicantId)]));
  }

  downloadPDF(downloadType: string){
    this.loaderService.startLoader();
    this.paymentSuccessForm.action = environment.serverBaseUrl + "v1/application/downloadApplicationPDF";
    this.paymentSuccessForm.applicationId.value = this.paymentDetailsObject.applicationId;
    this.paymentSuccessForm.txnReference.value = this.paymentDetailsObject.txnRefNumber;
    this.paymentSuccessForm.downloadType.value = downloadType;
    this.paymentSuccessForm.submit();
    this.loaderService.stopLoader();
  }


}
