import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Route } from '../common/route';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';

@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.css']
})
export class ApplicationViewComponent implements OnInit {

  private applicationViewForm : HTMLFormElement;

  private encryptedApplicantId : string = this.constants.BLANK;

  applicantId : string = this.constants.BLANK;

  uploadedPhotoDetailsObject : any = {};

  passportTypeDetailsObject : any = {};

  personalDetailsObject : any = {};

  contactDetailsObject : any = {};

  kinDetailsObject : any = {};

  feeDetailsObject : any = {};

  paymentDetailsObject : any = {};

  passportDeliveryDetailsObject : any = {};


  applicableDocsList : any[] = [];

  codFieldsList: any = [];

  isPaymentDetailsExist : boolean = false;

  barCodeValue : string = this.constants.BLANK;

  isDisabledButton : boolean = false;

  private loggedUserId : string = this.constants.BLANK;

  isShowPaymentDetails : boolean = false;

  isPaymentDetailsFetchFromServer : boolean = false;

  isTermConditionSelect : boolean = false;

  confirmationButtonClicked : boolean = false;

  enablePassportDeliveryStage: boolean = environment.enablePassportDeliveryStage;


  isContactlessStatus: boolean = false;
  isEligible: number = -1;
  appId : string = this.constants.BLANK;
  refId : string = this.constants.BLANK;

  serverContactlessStatus: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private ngZone: NgZone, private router: Router, private route: Route, private httpUtils: HttpUtils, private toast: Toast,
              public constants: Constants, private loaderService: LoaderService, private simpleModalService: SimpleModalService, public utils: Utils, private localStorage: LocalStorage,
              private tags: Tags, private gui: GUI) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
    });

    this.applicantId = this.utils.decryptString(this.encryptedApplicantId);
    this.loggedUserId = this.localStorage.getLoggedUserId();
    this.fetchServerToken();
    this.storeApplicationViewData();
    //console.log("constructor(): isContactless: ", this.isContactlessStatus);
  }

  ngOnInit(): void {
    //this.getContactlessStatus();
    this.applicationViewForm = document.forms['applicationViewForm'];
  }

  private storeApplicationViewData(){
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationData(this.applicantId, this.loggedUserId).then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.uploadedPhotoDetailsObject = serverResponse.uploadPhotoDetails;
        this.personalDetailsObject = serverResponse.personalInfo;
        this.contactDetailsObject = serverResponse.contactInfo;
        this.kinDetailsObject = serverResponse.kinInformation;
        this.passportTypeDetailsObject = serverResponse.passportDetails;
        this.passportDeliveryDetailsObject = serverResponse.deliveryInfo;
        this.feeDetailsObject = serverResponse.feeDetails;
        this.applicableDocsList = serverResponse.applicableDocsList;
        this.codFieldsList = serverResponse.codFields;
        this.isShowPaymentDetails = this.personalDetailsObject.paymentStatus !== this.constants.STATUS_SUCCESS;
        this.barCodeValue = this.constants.PASSPORT_TYPE + '-' + this.personalDetailsObject.applicationIdV1;
        this.setViewLabels();
        this.isContactlessStatus = this.feeDetailsObject?.contactlessStatus;
        //console.log("Load(): isContactless: ", this.isContactlessStatus);
        //if(this.isContactlessStatus === false){
        this.getServerContactlessStatus();//}
        this.loaderService.stopLoader();
      } else {
        this.loaderService.stopLoader();
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setViewLabels(){
    this.personalDetailsObject.paymentStatusImageURL = "assets/images/"+this.personalDetailsObject.paymentStatusImageName;
    this.personalDetailsObject.occupationLabel = this.utils.isEmpty(this.personalDetailsObject.occupation) === false ? this.personalDetailsObject.occupation : this.constants.NOT_PROVIDED;
    this.personalDetailsObject.ninNumberLabel = this.utils.isEmpty(this.personalDetailsObject.ninNumber) === false ? this.personalDetailsObject.ninNumber : this.constants.NOT_PROVIDED;

    this.contactDetailsObject.mobileNumberLabel = this.contactDetailsObject.mobileNumberDialCode + "-" + this.contactDetailsObject.mobileNumber;
    this.contactDetailsObject.phoneNumberLabel = this.utils.isEmpty(this.contactDetailsObject.phoneNumber) === false ? this.contactDetailsObject.phoneNumberDialCode + "-" + this.contactDetailsObject.phoneNumber : this.constants.NOT_PROVIDED;

    this.kinDetailsObject.mobileNumberLabel = this.kinDetailsObject.mobileNumberDialCode + "-" + this.kinDetailsObject.mobileNumber;
  }

  backToPage(){
    window.scrollTo(0,0);
    this.gui.openDashboardPage();
  }

  editApplication(){
    window.scrollTo(0,0);
    this.gui.openApplicationAddPage(this.applicantId);
  }

  proceedToPay(){
    this.loaderService.startLoader();
    console.log("Making payment: ", this.applicantId);
    //this.openIOSPaymentPage();
    this.initiatePayment();
    // this.loaderService.stopLoader();
  }

  showConfirmationForApplicationSubmission(shallPayNow: number){
    if(this.isTermConditionSelect === false){
      return;
    }
    const message = "<span class='app-confirm'>Please check to ensure that your completed application is accurate before you submit the application and an application ID is generated.An application cannot be edited once an application ID has been generated.</span>";
    const modalParamsObject = {title: "Confirmation", message: message, confirmButtonLabel: "Submit Application", cancelButtonLabel: "Cancel", isShowCancelButton: true};
    this.simpleModalService.addModal(ConfirmationModalComponent, modalParamsObject, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.confirmApplicationSubmission(shallPayNow);
      }
    });
  }

  convertDate(dateString: string | null): string | null {
    if (!dateString) {
      return '';
    }

    const [day, month, year] = dateString.split('/');
    if (!day || !month || !year) {
      return '';
    }

    return `${year}-${month}-${day}`;
  }

  private formatDate(date: any): string | null {
    if (!date) return null;
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  private extractNumber(value: string): number | null {
    if (!value) return null;
    const match = value.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  private extractPassportSize(passportSize: string): number | null {
    if (!passportSize) return null;
    const match = passportSize.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  private createPayload(): any {
    //console.log("DOB: ", this.personalDetailsObject?.dateOfBirth);
    return {
      'application_idv1' : this.appId,
      'reference_idv1': this.refId,
      'application_id': this.personalDetailsObject?.applicationId,
      'applicant_id': this.applicantId,
      'user_id': this.loggedUserId,
      'reference_id': this.personalDetailsObject?.referenceNumber,
      'last_name': this.personalDetailsObject?.lastName,
      'first_name': this.personalDetailsObject?.firstName,
      'middle_name': this.personalDetailsObject?.middleName,
      'date_of_birth': this.convertDate(this.personalDetailsObject?.dateOfBirth), //TODO: this is retirning NaN-NaN-NAN this.formatDate(this.personalDetailsObject?.dateOfBirth),
      'sex': this.personalDetailsObject?.gender,
      'place_of_birth': this.personalDetailsObject?.placeOfBirth, //this.contactDetailsObject.permanentOriginStateLabel, //this.personalDetailsObject?.placeOfBirth,
      'nin': this.personalDetailsObject?.ninNumber,
      'email': this.personalDetailsObject?.emailId,
      'phone': `${this.contactDetailsObject?.mobileNumberDialCode}${this.contactDetailsObject?.mobileNumber}`,
      'processing_office': this.passportTypeDetailsObject?.processingEmbassyLabel,
      'processing_state': this.passportTypeDetailsObject?.processingEmbassyLabel,
      'processing_country': this.passportTypeDetailsObject?.processingCountryLabel,
      'application_type': this.passportTypeDetailsObject?.applyingFor,
      'reason': this.passportTypeDetailsObject?.changeType,
      'passport_size': this.extractPassportSize(this.passportTypeDetailsObject?.bookletType),
      'passport_type': this.passportTypeDetailsObject?.passportTypeLabel,
      'validity': this.extractNumber(this.passportTypeDetailsObject?.validityLabel),
      'old_document_number': this.passportTypeDetailsObject?.passportNumber,
      'amount': this.feeDetailsObject?.totalFee,
      'currency': this.feeDetailsObject?.currencyCode,
      'payment_date': this.convertDate(this.personalDetailsObject?.paymentDate) || null,
      'expiry_date': this.convertDate(this.personalDetailsObject?.expiryDate) || null,
      'applicant_photo': this.uploadedPhotoDetailsObject?.uploadedBase64Data,
      'contactless_status': this.isContactlessStatus ? 1 : 0,
    };
  }

  private processForEsb(){
    if (this.isContactlessStatus) {
      const payload = this.createPayload();
      this.httpUtils.submitToEsb(payload);
      this.updateContactlessStatus();
      this.storeApplicationInfo();
    }
  }

  private confirmApplicationSubmission(shallPayNow: number){
    this.isDisabledButton = true;
    this.loaderService.startLoader();

    this.httpUtils.confirmApplicationSubmission(this.loggedUserId, this.applicantId, shallPayNow).then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.appId = serverResponse?.applicationIdV1;
        this.refId = serverResponse?.referenceIdV1;
        this.processForEsb();

          if(shallPayNow === this.constants.TRUE){
            this.initiatePayment();
          } else {
            this.loaderService.stopLoader();
            if(!this.isContactlessStatus) {
              this.gui.openDashboardPage();
            }else{
              this.gui.openContactlessPage();
            }
          }
          this.loaderService.stopLoader();
      } else {
        this.loaderService.stopLoader();
        this.isDisabledButton = false;
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private initiatePayment(){
    console.log("Initiating payment for app: ", this.applicantId);
    console.log("Initiating payment for loggedinuser: ", this.loggedUserId);
    const applicationsList = [this.applicantId];
    this.httpUtils.initiatePayment(this.loggedUserId, applicationsList).then(serverResponse => {
      // console.log("Initiate payment, server response: ", serverResponse);
      // console.log("Initiate payment, server response code: ", serverResponse.responseCode);
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.openIOSPaymentPage();
      } else {
        this.loaderService.stopLoader();
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private openIOSPaymentPage(){
    this.applicationViewForm.action = environment.serverBaseUrl + 'v1/payments/openIOSPaymentPage';
    this.applicationViewForm.loggedUserId.value = this.loggedUserId;
    this.applicationViewForm.submit();
  }

  printApplication(){
    window.print()
  }

  printReceipt(): void {
    window.open(this.route.PAYMENT_RECEIPT + '/' + encodeURIComponent(this.encryptedApplicantId), "_blank");
  }

  private getContactlessStatus(): void {
    this.isContactlessStatus = JSON.parse(localStorage.getItem("contactlessStatus")) || false;
  }

  private getServerContactlessStatus(): void {
    this.httpUtils.fetchContactlessStatus(this.personalDetailsObject?.applicationIdV1, this.personalDetailsObject?.referenceIdV1)
    .then(serverResponse => {
      if (serverResponse){ //?.responseCode === this.constants.STATUS_SUCCESS) {
        if(serverResponse?.contactlessStatus){
          localStorage.setItem("contactlessStatus", serverResponse?.contactlessStatus);
          this.isContactlessStatus = serverResponse?.contactlessStatus;
        }

        if(serverResponse?.isEligible){
           this.isEligible = serverResponse?.isEligible;
        }

      }
    }).catch(err => console.log("service response", err)
    );
  }

  private storeApplicationInfo(): void {
    localStorage.setItem("applicationId", this.appId);
    localStorage.setItem("referenceId", this.refId);
  }

  private updateContactlessStatus(): void {
    localStorage.setItem("contactlessStatus", JSON.stringify(false));
  }

  storeFeeAndPaymentDataFromIOS(): void {
    this.isShowPaymentDetails = !this.isShowPaymentDetails;

    if(this.isPaymentDetailsFetchFromServer === true){
      return;
    }

    this.loaderService.startLoader();
    const applicationIdV1 = this.personalDetailsObject.applicationIdV1;
    const referenceIdV1 = this.personalDetailsObject.referenceIdV1;
    this.httpUtils.fetchFeeAndPaymentDataFromIOS(applicationIdV1, referenceIdV1).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        // this.feeDetailsObject = serverResponse.feeDetails;
        this.paymentDetailsObject = serverResponse.paymentDetails;
        this.isPaymentDetailsExist = this.utils.isEmpty(serverResponse.paymentDetails) === false;
        this.isPaymentDetailsFetchFromServer = true;
      } else {
        this.toast.showErrorToastWithCallbackFunction(serverResponse.responseMsg, this.openDashboard.bind(null, this.gui));
      }
    });
  }

  private openDashboard(gui: GUI): void {
    gui.openDashboardPage();
  }

  openFilePreviewModal(applicableDocObject: any = {}) : void {
    this.simpleModalService.addModal(FilePreviewModalComponent, {applicableDocObject}, {autoFocus: true, closeOnEscape: true}).subscribe();
  }

  private fetchServerToken(): void {
    this.httpUtils.fetchServerToken().then(serverResponse => {
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
      }
    });
  }

}
