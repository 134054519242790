import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';
import { Utils } from './utils';
import { LocalStorage } from './local-storage';
import { Tags } from './tags';
import { Toast } from './toast';
import { Route } from './route';
import { environment } from 'src/environments/environment';
import { GUI } from './gui';
import { LoaderService } from './loader-service';
import { SimpleModalService } from 'ngx-simple-modal';

@Injectable({ 
    providedIn: 'root'
})
export class HttpUtils {
    
    constructor(private gui: GUI, private httpClient: HttpClient, private constants: Constants, private utils: Utils, private localStorage: LocalStorage, 
                private tags: Tags, private toast: Toast, private route: Route, private loaderService: LoaderService, private simpleModalService: SimpleModalService){
    }

    private fetchRequestHeaders(authorizationWithKey: boolean){
        let applicationToken = this.constants.BLANK;

        if (authorizationWithKey === true) {
            applicationToken = this.utils.encryptServerString(environment.authorizationKey);
        } else {
            applicationToken = this.localStorage.getStorageKey(this.tags.APPLICATION_TOKEN);
        }

        const encryptedAuthorizationWithKey = this.utils.encryptServerString(authorizationWithKey.toString())

        let headers = new HttpHeaders();
        headers = headers.append(this.tags.AUTHORIZATION, encryptedAuthorizationWithKey);
        headers = headers.append(this.tags.SERVER_APPLICATION_TOKEN, applicationToken);
        return headers;
    }

    private async sendPostRequest(url: string, requestBody: {}, authorizationWithKey: boolean){
        url = environment.serverBaseUrl + url; 

        const headers = this.fetchRequestHeaders(authorizationWithKey);
        
        return this.httpClient.post(url, requestBody, {headers}).toPromise()
            .then(response => {
                if(response[this.tags.RESPONSE_CODE] === this.constants.STATUS_AUTH_TOKEN_NOT_VALID){
                    this.loaderService.stopLoader();
                    this.simpleModalService.removeAll();
                    const errorMessage = response[this.tags.RESPONSE_MESSAGE];
                    this.toast.showErrorToastWithCallbackFunction(errorMessage, this.openLogoutPage.bind(null, this.gui));
                    return;
                }
                return response;
            }).catch(error => {
                console.log('Server error come', error);
                return this.fetchErrorObject(error.message);
            });
    }

    private async sendRequest(url: string, method: string, requestBody: {} = {}): Promise<any> {
        url = environment.esbUrl + url;

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: method === 'GET' ? null : JSON.stringify(requestBody), // Only attach body for non-GET requests
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Server error');
            }

            return await response.json();
        } catch (error) {
            console.error('Server error occurred:', error);
            return this.fetchErrorObject(error.message);
        }
    }

    private async sendPayRequest(url: string, method: string, requestBody: {} = {}): Promise<any> {
        url = environment.raveUrl + url;

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: method === 'GET' ? null : JSON.stringify(requestBody), // Only attach body for non-GET requests
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Server error');
            }

            return await response.json();
        } catch (error) {
            console.error('Server error occurred:', error);
            return this.fetchErrorObject(error.message);
        }
    }


    private openLogoutPage(gui: GUI): void {
        gui.openLogoutPage();
    }

    private fetchErrorObject(errorMessage: string){
        const errorResponseObject: any = {[this.tags.RESPONSE_CODE]: this.constants.INTERNAL_SERVER_ERROR, [this.tags.RESPONSE_MESSAGE]: errorMessage};
        return errorResponseObject;
    }

    async fetchServerToken(){
        const requestBody = {};
       
        return await this.sendPostRequest('v1/auth/serverToken', requestBody, true);  
    }

    async sendVerificationCode(formData: {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/application/generateEmailToken', requestBody, false);
    }

    async validateVerificationCode(formData: {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/application/validateEmailToken', requestBody, false);
    }

    async fetchProcessingCountriesList() {
        const requestBody = {}; 
       
        return await this.sendPostRequest('v1/config/allProcessingCountries', requestBody, false);
    }

    async fetchCountryEmbassyList(processingCountry: string) {
        const requestBody = {}; 
       
        return await this.sendPostRequest('v1/config/fetchCountryEmbassy/'+processingCountry, requestBody, false);
    }

    async fetchEmbassyBookletList(formData: any = {}) {
        const requestBody = formData; 
       
        return await this.sendPostRequest('v1/config/fetchEmbassyBookletList', requestBody, false);
    }

    async fetchEmbassyBookletValidityList(processingEmbassy: string, passportType: string, subType: string, bookletType: string, applicantId: string) {
        const requestBody = {processingEmbassy, passportType, subType, bookletType, applicantId}; 
       
        return await this.sendPostRequest('v1/config/fetchEmbassyBookletValidityList', requestBody, false);
    }

    async fetchPassportFeeDetails(formData: {}) {
        const requestBody = formData; 
       
        return await this.sendPostRequest('v1/config/fetchPassportFee', requestBody, false);
    }

    async fetchCountriesList() {
        const requestBody = {}; 
       
        return await this.sendPostRequest('v1/config/fetchCountriesList', requestBody, false);
    }

    async fetchCountryStateList() {
        const requestBody = {}; 
       
        return await this.sendPostRequest('v1/config/fetchCountryStates', requestBody, false);
    }

    async fetchStateLGAList(stateId: string) {
        const requestBody = {}; 
       
        return await this.sendPostRequest('v1/config/fetchStateLGA/'+stateId, requestBody, false);
    }

    async updatePassportDetails(passportTypeFormObject: {}) {
        const requestBody = passportTypeFormObject;

        return await this.sendPostRequest('v1/application/updatePassportDetails', requestBody, false);
    }

    async savePersonalDetailsData(personalDetailsFormObject: {}, passportTypeFormObject: {}) {
        const requestBody = Object.assign(personalDetailsFormObject, passportTypeFormObject);

        return await this.sendPostRequest('v1/application/bioData', requestBody, false);
    }

    async saveContactInformationData(contactInformationFormObject: {}) {
        const requestBody = contactInformationFormObject; 
       
        return await this.sendPostRequest('v1/application/contactInfo', requestBody, false);
    }

    async saveKinInformationData(kinInformationFormObject: {}) {
        const requestBody = kinInformationFormObject; 

        return await this.sendPostRequest('v1/application/kinInfo', requestBody, false);
    }

    async fetchApplicationData(applicantId: string, loggedUserId: string){
        const requestBody = {applicantId, loggedUserId}; 
       
        return await this.sendPostRequest('v1/application/applicantStageData', requestBody, false);
    }
    
    async confirmApplicationSubmission(loggedUserId: string, applicantId: string, shallPayNow: number){
        const requestBody = {loggedUserId: loggedUserId, applicantId: applicantId, shallPayNow: shallPayNow}; 
       
        return await this.sendPostRequest('v1/application/confirmApplication', requestBody, false);
    }


    

    async submitToEsb(payload: any): Promise<any> {
        const requestBody = payload;
        const url = 'passport/contactless';
        return await this.sendRequest(url, 'POST', requestBody);
    }

    async startRavePayment(payload: any): Promise<any> {
        const requestBody = payload;
        const url = '/trans';
        return await this.sendPayRequest(url, 'POST', requestBody);
    }




    async fetchContactlessStatus(appId: string, refId: string): Promise<any> {
        const url = `passport/contactless/status/${appId}/${refId}`;
        return await this.sendRequest(url, 'GET');
    }

    async fetchApplicationFromEsb(appId: string, refId: string, status: number): Promise<any> {
        const url = `passport/contactless/${appId}/${refId}/${status}`;
        return await this.sendRequest(url, 'GET');
    }



    async searchApplication(formData: {}){
        const requestBody = formData;

        return await this.sendPostRequest('v1/application/searchApplication', requestBody, false);
    }

    async checkPaymentStatus(txnRef: string){
        const requestBody = {}; 

        return await this.sendPostRequest('v1/payments/checkPaymentStatus/'+txnRef, requestBody, false);
    }

    async fetchV1ApplicationData(applicationId: string, referenceId: string){
        const requestBody = {applicationId: applicationId, referenceId: referenceId}; 
       
        return await this.sendPostRequest('v1/application/fetchV1ApplicationData', requestBody, false);
    }
    
    async saveLoginDetails(id: string, email: string, username: string, firstName: string, lastName: string) {
        const requestBody = {id: id, email: email, username: username, firstName: firstName, lastName: lastName}; 
       
        return await this.sendPostRequest('user/saveUser', requestBody, false);
    }

    async fetchDashboardData(loggedUserId: string, applicationStatus: number) {
        const requestBody = {loggedUserId, applicationStatus}; 
       
        return await this.sendPostRequest('dashboard/fetchDashboardData', requestBody, false);
    }
    
    async saveApplicationToCart(loggedUserId: string, applicantId: string){
        const requestBody = {loggedUserId: loggedUserId, applicantId: applicantId};

        return await this.sendPostRequest("v1/application/addApplicationToCart", requestBody, false);
    }

    async deleteApplication(loggedUserId: string, applicantId: string, isDeleteIncomplete: number){
        const requestBody = {loggedUserId: loggedUserId, applicantId: applicantId, isDeleteIncomplete: isDeleteIncomplete};

        return await this.sendPostRequest("v1/application/deleteApplication", requestBody, false);
    }

    async initiatePayment(loggedUserId: string, applicationsList: any[]){
        const authToken = this.localStorage.getApplicationToken();

        const requestBody = {authToken: authToken, loggedUserId: loggedUserId, applicationsList: applicationsList};

        return await this.sendPostRequest("v1/payments/initiatePayment", requestBody, false);
    }

    async fetchUserPaidApplications(loggedUserId: string, data: string){
        const requestBody = {loggedUserId, data};

        return await this.sendPostRequest("v1/payments/fetchUserPaidApplications", requestBody, false);
    }

    async fetchV1ApplicationViewData(applicationId: string, referenceId: string){
        const requestBody = {applicationId: applicationId, referenceId: referenceId}; 
       
        return await this.sendPostRequest("v1/application/fetchV1ApplicationData", requestBody, false);
    }

    async checkPaymentStatusOnIOS(loggedUserId: string, applicantId: string, isNeedApplicationsList: boolean){
        const requestBody = {loggedUserId: loggedUserId, applicantId: applicantId, isNeedApplicationsList: isNeedApplicationsList};

        return await this.sendPostRequest("v1/payments/checkPaymentStatusOnIOS", requestBody, false);
    }

    async fetchFeeAndPaymentDataFromIOS(applicationId: string, referenceId: string){
        const requestBody = {applicationId: applicationId, referenceId: referenceId}; 
       
        return await this.sendPostRequest("v1/payments/fetchFeeAndPaymentDataFromIOS", requestBody, false);
    }

    async fetchPaymentReceiptData(applicantId: string, isV1Application: number){
        const requestBody = {applicantId, isV1Application}; 
       
        return await this.sendPostRequest("v1/application/fetchPaymentReceiptData", requestBody, false);
    }

    async fetchApplicationGuarantorFormData(applicantId: string){
        const requestBody = {applicantId: applicantId}; 
       
        return await this.sendPostRequest("v1/application/fetchApplicationGuarantorFormData", requestBody, false);
    }
    
    async fetchRefundStatus(applicantId: string, loggedUserId: string){
        const requestBody = {applicantId, loggedUserId}; 
       
        return await this.sendPostRequest('v1/application/fetchRefundStatus', requestBody, false);
    }

    async validateNIN(formData: any = {}) {
        const requestBody = formData; 
       
        return await this.sendPostRequest("v1/application/validateNIN", requestBody, false);
    }

    async saveNIN(formData: any = {}) {
        const requestBody = formData; 
       
        return await this.sendPostRequest("v1/application/saveNIN", requestBody, false);
    }

    async validatePhoto(formData: any = {}) {
        const requestBody = formData;
       
        return await this.sendPostRequest("v1/application/validateImage", requestBody, false);
    }

    async savePhoto(formData: any = {}) { 
        const requestBody = formData;
       
        return await this.sendPostRequest("v1/application/saveImage", requestBody, false);
    }

    async savePersonalDetailsDataV2(personalDetailsFormObject: {}) {
        const requestBody = personalDetailsFormObject;

        return await this.sendPostRequest('v1/application/bioData', requestBody, false);
    }

    async saveV2ProcessingInfoData(formData: any = {}) {
        const requestBody = formData; 

        return await this.sendPostRequest("v1/application/processingInfo", requestBody, false);
    }

    async fetchApplicableDocs(applicantId: string, loggedUserId: string) {
        const requestBody = {applicantId, loggedUserId}; 

        return await this.sendPostRequest("v1/application/fetchApplicableDocs", requestBody, false);
    }

    async saveApplicableDocs(formData: any = {}) {
        const requestBody = formData; 

        return await this.sendPostRequest("v1/application/saveApplicableDocs", requestBody, false);
    }

    async fetchDocBase64Data(applicantDocId: string) {
        const requestBody = {applicantDocId}; 

        return await this.sendPostRequest("v1/application/fetchDocBase64Data", requestBody, false);
    }

    async fetchCurrentPassportData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest("v1/cod/fetchExistingPassportDetails", requestBody, false);
    }

    async saveCurrentPassportData(formData: any = {}) {
        const requestBody = formData;
    
        return await this.sendPostRequest("v1/cod/saveCurrentPassportData", requestBody, false);
    }

    async fetchCODComparisonData(dataCorrectionId: string) {
        const requestBody = {dataCorrectionId}; 

        return await this.sendPostRequest("v1/cod/fetchCODComparisonData", requestBody, false);
    }

    async saveCODComparisonData(formData: any = {}) {
        const requestBody = formData; 

        return await this.sendPostRequest("v1/cod/saveCODData", requestBody, false);
    }

    async validateQueriedLink(token: string) {
        const requestBody = {token}; 

        return await this.sendPostRequest("v1/application/validateQueriedLink", requestBody, false);
    }

    async saveReuploadedDocs(formData: any = {}) {
        const requestBody = formData; 

        return await this.sendPostRequest("v1/application/saveReuploadedDocs", requestBody, false);
    }

    async fetchDocHistory(formData: any = {}) {
        const requestBody = formData;
    
        return await this.sendPostRequest("v1/application/fetchDocHistory", requestBody, false);
    }

    async validateImageBeforeUpload(base64Data: string) {
        const requestBody = {base64Data};
       
        return await this.sendPostRequest("v1/application/validateImageBeforeUpload", requestBody, false);
    }

    // async fetchPassportDeliveryFeeDetails(formData: any = {}) {
    //     const requestBody = formData; 
       
    //     return await this.sendPostRequest("v1/config/fetchDeliveryFee", requestBody, false);
    // }

    async savePassportDeliveryDetails(formData: any = {}) {
        const requestBody = formData; 

        return await this.sendPostRequest("v1/application/deliveryInfo", requestBody, false);  
    }

}

