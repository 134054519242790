<app-header></app-header>

<div class="banner-img-name">
  <div class="main" style="padding-bottom: 0">
    <h2>Application Form</h2>
  </div>
</div>

<main class="body_section">
  <section class="main">
    <div class="">
      <div class="info">
        <h3 class="">
          Please fill the application form to apply for {{ passportTypeLabel }}
        </h3>
        <p style="color: red; line-height: 20px" class="blinkingText">
          Please note down the Reference No.
          {{ personalDetailsFormObject.referenceNumber }}
        </p>
      </div>

      <div class="form_main">
        <ul id="progressbar">
          <ng-container *ngFor="let applicationStepObject of applicationStepsList">
            <li [id]="
                applicationHighestStage >= applicationStepObject.count &&
                applicationStage !== applicationStepObject.count
                  ? applicationStepObject.id + '-tick'
                  : applicationStepObject.id
              " [ngClass]="
                applicationHighestStage >= applicationStepObject.count
                  ? 'active allow-cursor'
                  : 'not-allow-cursor'
              " [class]="
                enablePassportDeliveryStage === true ? 'sevenstages' : ''
              " (click)="
                applicationHighestStage >= applicationStepObject.count
                  ? openFieldSet(applicationStepObject.count, $event)
                  : ''
              ">
              <p>{{ applicationStepObject.label }}</p>
            </li>
          </ng-container>
        </ul>

        <fieldset *ngIf="applicationStage === constants.PROCESSING_INFO_STAGE" class="passport_type">
          <div class="step_info">
            <span>Step {{ constants.PROCESSING_INFO_STAGE }}:
              {{
              applicationStepsList[constants.PROCESSING_INFO_STAGE - 1].label
              }}</span>
          </div>

          <form #passportTypeForm="ngForm" (ngSubmit)="
              passportTypeForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY && openImageUploadForm($event))
            " autocomplete="off" name="passportTypeForm" id="msform" appFocus>
            <input type="hidden" name="hasFieldValueChanged" [(ngModel)]="passportTypeFormObject.hasFieldValueChanged"
              #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName" [(ngModel)]="passportTypeFormObject.callableFunctionName"
              #callableFunctionName="ngModel" />

            <ng-template #instructions>
              <ul>
                <li>
                  <span>Check Centre Appointment Availability before proceeding to
                    the next step.</span>
                </li>

                <li>
                  <span>All fields that contain the Asterisk (*) sign are mandatory
                    and must be completed.</span>
                </li>

                <!-- <li><span>The Passport fee depends upon the selected booklet type and validity of passport.</span></li> -->

                <!-- <li><span>Please select Official Passport as Passport Type only if you are holding diplomatic status or deputed by the Government of Nigeria for official duty</span></li> -->
              </ul>
            </ng-template>

            <div class="grid-one-third">
              <div class="grid-onethird-box">
                <details>
                  <summary>Instructions for Applicant</summary>
                  <div class="content">
                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                  </div>
                </details>

                <div class="grid-two">
                  <div class="form_box">
                    <label class="fieldlabels">Type of Citizenship<span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="natureOfCitizenship" hint [identify-value-change]="passportTypeFormObject"
                        [(ngModel)]="passportTypeFormObject.natureOfCitizenship" #natureOfCitizenship="ngModel"
                        (ngModelChange)="
                          disableProcessingStatesAsPerReasons(true)
                        " required="required" class="effect">
                        <mat-option *ngFor="
                            let nature of natureOfCitizenshipSelectOptionsList
                          " [value]="nature.value">{{ nature.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Choose the Type of
                        Citizenship possessed by the applicant.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportTypeForm.submitted &&
                        natureOfCitizenship.invalid
                      ">
                      <span *ngIf="natureOfCitizenship.errors.required">
                        Type of Citizenship is required!
                      </span>
                    </span>
                  </div>

                  <ng-container *ngIf="
                      personalDetailsFormObject.isFreshApplication === false
                    ">
                    <div class="form_box">
                      <label class="fieldlabels">Current Passport Number
                        <span *ngIf="
                            passportTypeFormObject.changeType !==
                            constants.LOST_REPLACEMENT_REQUEST
                          " class="medatory">*</span></label>
                      <div class="input_box">
                        <input type="text" name="passportNumber" hint [identify-value-change]="passportTypeFormObject"
                          alphaNumericOnly class="effect" [required]="
                            passportTypeFormObject.changeType !==
                            constants.LOST_REPLACEMENT_REQUEST
                          " disabled="disabled" autocomplete="off" [pattern]="pattern.PASSPORT_NUMBER_PATTERN" (input)="
                            passportTypeFormObject.passportNumber =
                              passportTypeFormObject.passportNumber.toUpperCase()
                          " [(ngModel)]="passportTypeFormObject.passportNumber" #passportNumber="ngModel" minlength="9"
                          maxlength="9" />
                        <span class="tooltip"><span class="tooltipArrow"></span>Enter your current
                          passport number as it appears on your existing
                          passport.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          passportTypeForm.submitted && passportNumber.invalid
                        ">
                        <span *ngIf="passportNumber.errors?.required">
                          Current Passport Number is required!
                        </span>
                        <span *ngIf="passportNumber.errors?.minlength">
                          Current Passport Number should be 9 digits!
                        </span>
                        <span *ngIf="
                            !passportNumber.errors?.minlength &&
                            passportNumber.errors?.pattern
                          ">
                          Current Passport Number should start with alphabet!
                        </span>
                      </span>
                    </div>

                    <div class="form_box">
                      <label class="fieldlabels">{{ changeTypeLabel }}
                        <span class="medatory">*</span></label>
                      <div class="input_box">
                        <mat-select hint name="changeType" hint [identify-value-change]="passportTypeFormObject"
                          [(ngModel)]="passportTypeFormObject.changeType" #changeType="ngModel" required="required"
                          class="effect">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="
                                changeTypeSelectService.selectFilterControl
                              " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                              autocomplete="off"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="
                              let changeType of changeTypeSelectService.filteredOptions
                                | async
                            " [value]="changeType.value">{{ changeType.text }}</mat-option>
                        </mat-select>
                        <span class="tooltip"><span class="tooltipArrow"></span>Select the
                          appropriate reason that best describes the purpose of
                          renewing/re-issuing your passport.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert"
                        *ngIf="passportTypeForm.submitted && changeType.invalid">
                        <span *ngIf="changeType.errors.required">
                          {{ changeTypeLabel }} is required!
                        </span>
                      </span>
                    </div>
                  </ng-container>

                  <div class="form_box">
                    <label class="fieldlabels">Processing State <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="processingState" hint [identify-value-change]="passportTypeFormObject"
                        [(ngModel)]="passportTypeFormObject.processingState" #processingState="ngModel"
                        (ngModelChange)="storeStateProcessingOfficeList(true)" required="required" class="effect"
                        [disabled]="disableProcessingStateInputField">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              stateSelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let state of stateSelectService.filteredOptions
                              | async
                          " [value]="state.value">{{ state.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Choose the
                        administrative state where your passport application
                        should be processed.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportTypeForm.submitted && processingState.invalid
                      ">
                      <span *ngIf="processingState.errors.required">
                        Processing State is required!
                      </span>
                    </span>

                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Processing Office <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="processingOffice" hint [identify-value-change]="passportTypeFormObject"
                        [(ngModel)]="passportTypeFormObject.processingOffice" #processingOffice="ngModel"
                        (ngModelChange)="fetchBookletList(true)" (selectionChange)="
                          setAppointmentAvailabilityURLV2($event);
                          checkPremiumCenter($event)
                        " required="required" class="effect" [disabled]="disableProcessingStateInputField">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              processingOfficeSelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let processingOffice of processingOfficeSelectService.filteredOptions
                              | async
                          " [value]="processingOffice.value" [attr.data]="processingOffice.isPremium"
                          [attr.label]="processingOffice.name2">{{ processingOffice.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Choose the nearby
                        service center according to the state you have chosen
                        before and their availability to streamline your
                        application process.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span *ngIf="
                        utils.isEmpty(
                          passportTypeFormObject.processingOffice
                        ) === false
                      " class="book_center">
                      Ensure to check appointment availability at the center
                      <a [href]="appointmentAvailabilityURL" target="_blank" rel="noopener noreferrer"
                        style="color: #0087ff; text-decoration: underline">here.
                      </a>
                    </span>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportTypeForm.submitted && processingOffice.invalid
                      ">
                      <span *ngIf="processingOffice.errors.required">
                        Processing Office is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Booklet Type <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="bookletType" hint [identify-value-change]="passportTypeFormObject"
                        [(ngModel)]="passportTypeFormObject.bookletType" (ngModelChange)="fetchValidityList(true)"
                        #bookletType="ngModel" required="required" class="effect">
                        <mat-option *ngFor="let booklet of bookletSelectOptionsList" [value]="booklet.value">{{
                          booklet.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Select the number of
                        pages you require in your passport.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="passportTypeForm.submitted && bookletType.invalid">
                      <span *ngIf="bookletType.errors.required">
                        Booklet Type is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Validity <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="passportValidity" hint [identify-value-change]="passportTypeFormObject"
                        [(ngModel)]="passportTypeFormObject.passportValidity"
                        (ngModelChange)="fetchPassportFeeDetails()" #passportValidity="ngModel" required="required"
                        class="effect">
                        <mat-option *ngFor="let validity of validitySelectOptionsList" [value]="validity.value">{{
                          validity.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Please choose the
                        validity period for your passport.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportTypeForm.submitted && passportValidity.invalid
                      ">
                      <span *ngIf="passportValidity.errors.required">
                        Validity is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box mb30" *ngIf="isBiometricEnrollmentRequired">
                    <div class="radio">
                      <h4>How would you like your biometric enrolled?</h4>

                      <div class="radio-group">
                        <input type="radio" name="contactlessStatus" [value]="constants.FALSE" #isContactless="ngModel"
                          [(ngModel)]="passportDeliveryFormObject.contactlessStatus"
                          [required]="isBiometricEnrollmentRequired" class="infoyn" id="visitOffice"
                          (ngModelChange)="storeContactlessStatus(false)" />
                        <label for="visitOffice" class="infoyn">
                          Visit Immigration Processing Office
                        </label>
                      </div>

                      <div class="radio-group">
                        <input type="radio" name="contactlessStatus" [value]="constants.TRUE" #isContactless="ngModel"
                          [(ngModel)]="passportDeliveryFormObject.contactlessStatus"
                          [required]="isBiometricEnrollmentRequired" class="infoyn" id="selfEnrollment"
                          (ngModelChange)="storeContactlessStatus(true)" />
                        <label for="selfEnrollment" class="infoyn">
                          Self Enrollment (Additional fees will be charged)
                        </label>
                      </div>

                      <div *ngIf="passportTypeForm.submitted && isContactless.invalid" class="alert alert-danger"
                        role="alert">
                        <span *ngIf="isContactless.errors?.required">
                          Confirmation is required
                        </span>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="grid-onethird-box upload_inst">
                <div *ngIf="isShowFeesSection === false" class="element mt20 none_dis">
                  <h4>Instructions</h4>
                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>

                <div *ngIf="isShowFeesSection === true" class="grid-box">
                  <div *ngIf="feeDetailsObject.premiumFee > 0" class="element mt20">
                    <h4>Important Note</h4>
                    <ul>
                      <li>
                        <span>The Passport Premium Service is designed for
                          convenience and as a fast-track option for applicants
                          who urgently need their passports. This optional
                          service ensures that passports are ready within 48
                          hours of submission.</span>
                      </li>
                    </ul>
                  </div>

                  <div class="fee">
                    <div *ngIf="feeDetailsObject.codFee > 0">
                      COD Fee:
                      <span>{{ feeDetailsObject.currencySymbol }}
                        {{
                        feeDetailsObject.codFee | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div>
                      Passport Booklet Fee:
                      <span>{{ feeDetailsObject.currencySymbol }}
                        {{
                        feeDetailsObject.bookletFee | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div *ngIf="feeDetailsObject.premiumFee > 0">
                      Passport Premium Fee:
                      <span>{{ feeDetailsObject.currencySymbol }}
                        {{
                        feeDetailsObject.premiumFee | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div *ngIf="feeDetailsObject.deliveryFee > 0">
                      Passport Delivery Fee:
                      <span>{{ feeDetailsObject.currencySymbol }}
                        {{
                        feeDetailsObject.deliveryFee
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div *ngIf="feeDetailsObject.deliveryServiceCharge > 0">
                      Passport Delivery Service Charge:
                      <span>{{ feeDetailsObject.currencySymbol }}
                        {{
                        feeDetailsObject.deliveryServiceCharge
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div *ngIf="passportTypeFormObject.contactlessStatus ">
                      Contactless Fee:
                      <span>{{ feeDetailsObject.currencySymbol }}
                        {{
                        50000
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>


                    <div>
                      <b>Total Fee:</b>
                      <span><b>{{ feeDetailsObject.currencySymbol }}
                          {{
                          feeDetailsObject.totalFee + (passportTypeFormObject.contactlessStatus ? 50000 : 0) | number :
                          "1.2"
                          : "en-US"
                          }}</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <label *ngIf="
                isShowFeesSection === true &&
                passportTypeFormObject.passportType !==
                  constants.OFFICIAL_PASSPORT
              " class="t_and_c pointer">
              <input type="checkbox" name="termCondition" [(ngModel)]="passportTypeFormObject.isTermConditionSelect"
                #termCondition="ngModel" required="required" />
              <span style="padding: 0 3px">
                I hereby confirm that I have checked the appointment
                availability at the chosen processing office.</span>
              <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && termCondition.invalid">
                <span *ngIf="termCondition.errors.required">
                  Confirmation is required
                </span>
              </span>
            </label>

            <div style="width: 100%">
              <input type="submit" (click)="shallExitApplication = constants.TRUE" value="Save & Exit" class="save_btn"
                [disabled]="isDisablePassportFormSubmitButton" />

              <input type="submit" (click)="shallExitApplication = constants.FALSE" value="Continue"
                class="action-button custom_btn" [disabled]="isDisablePassportFormSubmitButton" />
            </div>
          </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === constants.UPLOAD_PHOTO_STAGE" class="passport_type">
          <ng-template #instructions>
            <h4>Fundamental rules for uploading picture</h4>
            <p>
              Please keep the following points in mind while uploading the
              picture.
            </p>

            <ul>
              <li>Image resolution should be atleast 600x800 px.</li>
              <li>
                The person must have a neutral expression with both eyes open
                and mouth closed. Smiling or frowning is not allowed, as it can
                alter facial features.
              </li>
              <li>
                The head and shoulders of the subject must be centered in the
                photo, with the face fully visible and not tilted. The person
                must be looking directly at the camera with a relaxed and
                natural head position.
              </li>
              <li>
                The photo must be taken in good lighting conditions, with no
                harsh shadows or reflections on the face.
              </li>
              <li>
                The photo must be in color, with accurate color representation
                and good contrast between the subject's face and the background.
              </li>
              <li>
                The photo should not have any red-eye effect or other image
                defects that could affect facial recognition accuracy.
              </li>
              <li>
                ICAO guidelines have relaxation for kids under the age of 5.
                Kids must look toward the camera and the face should be centered
              </li>
            </ul>
          </ng-template>

          <div class="step_info">
            <span>Step {{ constants.UPLOAD_PHOTO_STAGE }}: Upload a Colored
              Passport Sized Photograph
            </span>
          </div>

          <form #uploadPhotoForm="ngForm" (ngSubmit)="
              uploadPhotoForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY && openPersonalDetailsForm($event))
            " autocomplete="off" name="uploadPhotoForm" id="msform">
            <input type="hidden" name="hasFieldValueChanged" [(ngModel)]="uploadPhotoFormObject.hasFieldValueChanged"
              #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName" [(ngModel)]="uploadPhotoFormObject.callableFunctionName"
              #callableFunctionName="ngModel" />

            <details>
              <summary>Instructions for Applicant</summary>
              <div class="content">
                <ng-template [ngTemplateOutlet]="instructions"></ng-template>
              </div>
            </details>

            <div class="search_grid-upload-img">
              <h3 style="color: red">
                Note: The photo you are uploading here will be printed on your
                passport.
              </h3>
              <div class="grid-box">
                <div class="img_upload">
                  <div class="img_box" style="display: flex">
                    <input type="hidden" name="validatedBase64Data"
                      [(ngModel)]="uploadPhotoFormObject.validatedBase64Data" #validatedBase64Data="ngModel"
                      class="effect" required="required" />
                    <img [src]="
                        imageValidated === true
                          ? uploadPhotoFormObject.validatedBase64Data
                          : 'assets/images/defaultImage.png'
                      " class="cropped" />
                  </div>
                  <p style="position: relative; padding-top: 13px">
                    <span style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding-top: 0;
                      " class="alert alert-danger" role="alert" *ngIf="
                        uploadPhotoForm.submitted && validatedBase64Data.invalid
                      ">
                      <span *ngIf="validatedBase64Data.errors.required">
                        Image is required!
                      </span>
                    </span>
                  </p>

                  <div class="photo_icons">
                    <label class="w_auto icons_uplaod">
                      <input type="button" class="upload_btn" value="Upload Photo" (click)="
                          openImageUploadModal(
                            uploadPhotoFormObject,
                            'validatedBase64Data',
                            'passportImageFileType'
                          )
                        " />
                    </label>
                  </div>
                </div>
              </div>

              <div class="grid-box">
                <div class="verify-picture-grid">
                  <div class="verify-picture">
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img1.jpg" alt="image" />
                        <span class="icon-upload verify"><i aria-hidden="true" class="fa fa-check"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img2.jpg" alt="image" />
                        <span class="icon-upload not-verify"><i aria-hidden="true" class="fa fa-times"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img3.jpg" alt="image" />
                        <span class="icon-upload not-verify"><i aria-hidden="true" class="fa fa-times"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img4.png" alt="image" />
                        <span class="icon-upload verify"><i aria-hidden="true" class="fa fa-check"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img5.png" alt="image" />
                        <span class="icon-upload not-verify"><i aria-hidden="true" class="fa fa-times"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img6.png" alt="image" />
                        <span class="icon-upload not-verify"><i aria-hidden="true" class="fa fa-times"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img7.png" alt="image" />
                        <span class="icon-upload verify"><i aria-hidden="true" class="fa fa-check"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img8.png" alt="image" />
                        <span class="icon-upload not-verify"><i aria-hidden="true" class="fa fa-times"></i></span>
                      </div>
                    </li>
                    <li>
                      <div class="pic-upload">
                        <img src="assets/images/verify-img9.png" alt="image" />
                        <span class="icon-upload not-verify"><i aria-hidden="true" class="fa fa-times"></i></span>
                      </div>
                    </li>
                  </div>
                  <div class="guidlines mt10">
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <p>
                      You can go through some sample pictures for a better
                      understanding by clicking
                      <a href="javascript:void(0)" (click)="openPhotoGuidelineModal()">Read photo guidelines in
                        detail.</a>
                    </p>
                  </div>
                </div>
              </div>

              <div class="grid-box upload_inst">
                <div class="element none_dis" style="margin-top: 0">
                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>
              </div>

              <div class="w100">
                <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
                  value="Previous Page" />

                <ng-container *ngIf="imageValidated === true">
                  <input type="submit" (click)="shallExitApplication = constants.TRUE" class="save_btn"
                    value="Save & Exit" />

                  <input type="submit" (click)="shallExitApplication = constants.FALSE" class="action-button custom_btn"
                    value="Continue" />
                </ng-container>
              </div>
            </div>
          </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === constants.APPLICANT_INFO_STAGE">
          <div class="step_info">
            <span>Step {{ constants.APPLICANT_INFO_STAGE }}:
              {{
              applicationStepsList[constants.APPLICANT_INFO_STAGE - 1].label
              }}</span>
          </div>

          <form #personalDetailsForm="ngForm" (ngSubmit)="
              personalDetailsForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY && openContactInformationForm($event))
            " autocomplete="off" name="personalDetailsForm" id="msform" appFocus>
            <input type="hidden" name="hasFieldValueChanged"
              [(ngModel)]="personalDetailsFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName"
              [(ngModel)]="personalDetailsFormObject.callableFunctionName" #callableFunctionName="ngModel" />

            <ng-template #instructions>
              <ul>
                <li>
                  The personal details populated here are retrieved from your
                  NIN records and the same will be printed on your passport.
                </li>

                <li>
                  Kindly update Place of Birth, Country of Birth, Marital
                  Status, Occupation, Maiden name and Email, if required.
                </li>

                <li>
                  All fields that contain the Asterisk (*) sign are mandatory
                  and must be completed.
                </li>
              </ul>
            </ng-template>

            <div class="grid-one-third">
              <div class="grid-onethird-box">
                <details>
                  <summary>Instructions for Applicant</summary>
                  <div class="content">
                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                  </div>
                </details>

                <div class="grid-two">
                  <div class="form_box">
                    <label class="fieldlabels">Title <span class="medatory">*</span></label>
                    <div class="input_box">
                      <select name="title" [identify-value-change]="personalDetailsFormObject" class="effect"
                        required="required" [(ngModel)]="personalDetailsFormObject.title" #title="ngModel">
                        <option *ngFor="let object of titleList" [value]="object.value">
                          {{ object.text }}
                        </option>
                      </select>
                      <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                      <span class="tooltip"><span class="tooltipArrow"></span></span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="personalDetailsForm.submitted && title.invalid">
                      <span *ngIf="title.errors.required">
                        Title is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Surname <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="lastName" hint [identify-value-change]="personalDetailsFormObject"
                        name-directive class="effect" required="required" disabled="disabled" autocomplete="off"
                        [(ngModel)]="personalDetailsFormObject.lastName" #lastName="ngModel" minlength="2"
                        maxlength="30" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter your
                        surname or last name here. This should be same as your
                        official documents such as identification cards,
                        passports, or legal papers.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="personalDetailsForm.submitted && lastName.invalid">
                      <span *ngIf="lastName.errors.required">
                        Surname is required!
                      </span>
                      <span *ngIf="lastName.errors?.minlength">
                        Surname should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !lastName.errors?.minlength && lastName.errors.pattern
                        ">
                        Surname should start and end with alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">First Name <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="firstName" hint [identify-value-change]="personalDetailsFormObject"
                        name-with-number-directive class="effect" required="required" disabled="disabled"
                        autocomplete="off" [(ngModel)]="personalDetailsFormObject.firstName" #firstName="ngModel"
                        minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z0-9]$" />
                      <span class="tooltip"><span class="tooltipArrow"></span>First names (given
                        names) as stated in passport.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="personalDetailsForm.submitted && firstName.invalid">
                      <span *ngIf="firstName.errors.required">
                        First Name is required!
                      </span>
                      <span *ngIf="firstName.errors?.minlength">
                        First Name should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !firstName.errors?.minlength &&
                          firstName.errors.pattern
                        ">
                        First Name should start with alphabet and end with
                        alphabet or number!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Middle Name (Optional)</label>
                    <div class="input_box">
                      <input type="text" name="middleName" hint [identify-value-change]="personalDetailsFormObject"
                        name-directive class="effect" autocomplete="off" disabled="disabled"
                        [(ngModel)]="personalDetailsFormObject.middleName" #middleName="ngModel" minlength="2"
                        maxlength="30" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>In the middle name
                        field, enter your middle name as the appears on your
                        passport. The Middle name is optional .</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted && middleName.invalid
                      ">
                      <span *ngIf="middleName.errors?.minlength">
                        Middle Name should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !middleName.errors?.minlength &&
                          middleName.errors.pattern
                        ">
                        Middle Name should start and end with alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Date of Birth (dd/mm/yyyy)
                      <span class="medatory">*</span></label>
                    <div class="grid-date">
                      <div class="input_box">
                        <input type="text" name="dateOfBirthDay" [identify-value-change]="personalDetailsFormObject"
                          numberOnly disabled="disabled" [(ngModel)]="personalDetailsFormObject.dateOfBirthDay"
                          #dateOfBirthDay="ngModel" class="effect" required="required" autocomplete="off" maxlength="2"
                          placeholder="DD" />
                        <span class="focus-border"><i></i></span>
                      </div>

                      <div class="input_box">
                        <select name="dateOfBirthMonth" [identify-value-change]="personalDetailsFormObject"
                          disabled="disabled" [(ngModel)]="
                            personalDetailsFormObject.dateOfBirthMonth
                          " #dateOfBirthMonth="ngModel" class="effect" required="required" autocomplete="off">
                          <option *ngFor="let object of monthsList" [value]="object.value">
                            {{ object.text }}
                          </option>
                        </select>
                        <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                        <span class="focus-border"><i></i></span>
                      </div>

                      <div class="input_box">
                        <input type="text" name="dateOfBirthYear" [identify-value-change]="personalDetailsFormObject"
                          numberOnly disabled="disabled" [(ngModel)]="
                            personalDetailsFormObject.dateOfBirthYear
                          " #dateOfBirthYear="ngModel" class="effect" required="required" autocomplete="off"
                          minlength="4" maxlength="4" placeholder="YYYY" />
                        <span class="focus-border"><i></i></span>
                      </div>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted &&
                        (dateOfBirthDay.invalid ||
                          dateOfBirthMonth.invalid ||
                          dateOfBirthYear.invalid)
                      ">
                      <span *ngIf="
                          dateOfBirthDay.errors?.required ||
                          dateOfBirthMonth.errors?.required ||
                          dateOfBirthYear.errors?.required
                        ">
                        Date of Birth is required!
                      </span>
                      <span *ngIf="dateOfBirthYear.errors?.minlength">
                        Date of Birth's year should be 4 digits!
                      </span>
                      <span *ngIf="
                          !dateOfBirthYear.errors?.minlength &&
                          dateOfBirthDay.errors?.hasError
                        ">
                        {{ dateOfBirthDay.errors.errorMessage }}
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Gender <span class="medatory">*</span></label>
                    <div class="input_box">
                      <select name="gender" [identify-value-change]="personalDetailsFormObject" class="effect"
                        required="required" disabled="disabled" [(ngModel)]="personalDetailsFormObject.gender"
                        #gender="ngModel">
                        <option value="">Choose Option</option>
                        <option [value]="constants.MALE">Male</option>
                        <option [value]="constants.FEMALE">Female</option>
                      </select>
                      <span class="focus-border"><i></i></span>
                      <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="personalDetailsForm.submitted && gender.invalid">
                      <span *ngIf="gender.errors.required">
                        Gender is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Place of Birth <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="placeOfBirth" hint [identify-value-change]="personalDetailsFormObject"
                        class="effect" required="required" autocomplete="off"
                        [(ngModel)]="personalDetailsFormObject.placeOfBirth" #placeOfBirth="ngModel" minlength="2"
                        maxlength="50" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the
                        city/town/village in which you were born.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted && placeOfBirth.invalid
                      ">
                      <span *ngIf="placeOfBirth.errors.required">
                        Place of Birth is required!
                      </span>
                      <span *ngIf="placeOfBirth.errors?.minlength">
                        Place of Birth should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !placeOfBirth.errors?.minlength &&
                          placeOfBirth.errors.pattern
                        ">
                        Place of Birth should start and end with an alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Country of Birth <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="countryOfBirth" hint [identify-value-change]="personalDetailsFormObject"
                        class="effect" required="required" autocomplete="off"
                        [(ngModel)]="personalDetailsFormObject.countryOfBirth" #countryOfBirth="ngModel" minlength="2"
                        maxlength="40" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the country in
                        which you were born.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted && countryOfBirth.invalid
                      ">
                      <span *ngIf="countryOfBirth.errors.required">
                        Country of Birth is required!
                      </span>
                      <span *ngIf="countryOfBirth.errors?.minlength">
                        Country of Birth should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !countryOfBirth.errors?.minlength &&
                          placeOfBirth.errors.pattern
                        ">
                        Country of Birth should start and end with an alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Marital status <span class="medatory">*</span></label>
                    <div class="input_box">
                      <select name="maritalStatus" hint [identify-value-change]="personalDetailsFormObject"
                        class="effect" required="required" [(ngModel)]="personalDetailsFormObject.maritalStatus"
                        #maritalStatus="ngModel">
                        <option value="">Choose Option</option>
                        <option [value]="constants.SINGLE">Single</option>
                        <option [value]="constants.MARRIED">Married</option>
                        <option [value]="constants.WIDOWED">Widowed</option>
                        <option [value]="constants.DIVORCED">Divorced</option>
                      </select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Please select your
                        current marital status. This will be required to ensure
                        list of documents to be uploaded on later stage.</span>
                      <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted && maritalStatus.invalid
                      ">
                      <span *ngIf="maritalStatus.errors.required">
                        Marital status is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Occupation</label>
                    <div class="input_box">
                      <input type="text" name="occupation" hint [identify-value-change]="personalDetailsFormObject"
                        name-directive class="effect" autocomplete="off"
                        [(ngModel)]="personalDetailsFormObject.occupation" #occupation="ngModel" maxlength="50" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Provide details about
                        your employment or field of work. Example: Teacher,
                        Software Engineer, Nurse, etc.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted && occupation.invalid
                      ">
                      <span *ngIf="occupation.errors.required">
                        Occupation is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Maiden Name</label>
                    <div class="input_box">
                      <input type="text" name="motherMaidenName" hint
                        [identify-value-change]="personalDetailsFormObject" name-directive class="effect"
                        autocomplete="off" [(ngModel)]="personalDetailsFormObject.motherMaidenName"
                        #motherMaidenName="ngModel" minlength="2" maxlength="50"
                        [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Your maiden name
                        refers to the surname you used before marriage. Please
                        enter your previous last name here, if applicable.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        personalDetailsForm.submitted &&
                        motherMaidenName.invalid
                      ">
                      <span *ngIf="motherMaidenName.errors.required">
                        Maiden Name is required!
                      </span>
                      <span *ngIf="motherMaidenName.errors?.minlength">
                        Maiden Name should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !motherMaidenName.errors?.minlength &&
                          motherMaidenName.errors.pattern
                        ">
                        Maiden Name should start and end with an alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">E-mail <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="email" hint [identify-value-change]="personalDetailsFormObject" name="emailId"
                        [(ngModel)]="personalDetailsFormObject.emailId" #emailId="ngModel" class="effect"
                        maxlength="100" required="required" [pattern]="pattern.EMAIL_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide a
                        valid email address in the format yourname@example.com.
                        This email will serve as your primary point of contact
                        regarding your application and payment</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="personalDetailsForm.submitted && emailId.invalid">
                      <span *ngIf="emailId.errors.required">
                        E-mail is required!
                      </span>
                      <span *ngIf="emailId.errors.pattern">
                        E-mail should be valid!
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="grid-onethird-box upload_inst">
                <div class="element mt20 none_dis">
                  <h4>Instructions</h4>

                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>
              </div>
            </div>

            <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
              value="Previous Page" />

            <input type="submit" (click)="shallExitApplication = constants.TRUE" class="save_btn" value="Save & Exit" />

            <input type="submit" (click)="shallExitApplication = constants.FALSE" class="action-button custom_btn"
              value="Continue" />
          </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === constants.CONTACT_INFO_STAGE">
          <div class="step_info">
            <span>Step {{ constants.CONTACT_INFO_STAGE }}:
              {{
              applicationStepsList[constants.CONTACT_INFO_STAGE - 1].label
              }}</span>
          </div>

          <form #contactInformationForm="ngForm" (ngSubmit)="
              contactInformationForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY && openKinInformationForm($event))
            " autocomplete="off" name="contactInformationForm" id="msform" appFocus>
            <input type="hidden" name="hasFieldValueChanged"
              [(ngModel)]="contactInformationFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName"
              [(ngModel)]="contactInformationFormObject.callableFunctionName" #callableFunctionName="ngModel" />

            <ng-template #instructions>
              <ul>
                <li>
                  <span>Kindly fill your contact information correctly to ease
                    communication during your Passport application
                    process.</span>
                </li>

                <li>
                  <span>Enter a valid phone number in the format
                    +234-1234567891.</span>
                </li>

                <li>
                  All fields that contain the Asterisk (*) sign are mandatory
                  and must be completed.
                </li>
              </ul>
            </ng-template>

            <div class="grid-one-third">
              <div class="grid-onethird-box">
                <details>
                  <summary>Instructions for Applicant</summary>

                  <div class="content">
                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                  </div>
                </details>

                <div class="grid-two">
                  <div class="form_box">
                    <label class="fieldlabels">Home Town / Village
                      <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="contactCity" hint [identify-value-change]="contactInformationFormObject"
                        name-directive class="effect" required="required" autocomplete="off"
                        [(ngModel)]="contactInformationFormObject.contactCity" #contactCity="ngModel" minlength="2"
                        maxlength="40" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter the name
                        of the town or village where you were born.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && contactCity.invalid
                      ">
                      <span *ngIf="contactCity.errors.required">
                        Home Town is required!
                      </span>
                      <span *ngIf="contactCity.errors?.minlength">
                        Home Town should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !contactCity.errors?.minlength &&
                          contactCity.errors.pattern
                        ">
                        Home Town should start and end with an alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">State of Origin <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="stateOfOrigin" hint
                        [identify-value-change]="contactInformationFormObject" [(ngModel)]="stateOfOriginValue"
                        #stateOfOrigin="ngModel" minlength="2" maxlength="40" class="effect" required="required"
                        autocomplete="off" />
                      <span class="tooltip"><span class="tooltipArrow"></span>State of Origin is
                        the name of the state where you were originally born or
                        considered a permanent resident.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted &&
                        stateOfOrigin.invalid
                      ">
                      <span *ngIf="stateOfOrigin.errors.required">
                        State of Origin is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Contact Number <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="mobileNumber" hint [identify-value-change]="contactInformationFormObject"
                        blockCopyPaste numberOnly [validate-phone]="['mobileNumber', 'Contact Number']"
                        [(ngModel)]="contactInformationFormObject.mobileNumber" #mobileNumber="ngModel"
                        class="focus_border" required="required" autocomplete="off" minlength="8" maxlength="11"
                        ng2TelInput [ng2TelInputOptions]="ng2TelInputOptions" (intlTelInputObject)="
                          utils.setMobileNumberFields(
                            $event.s,
                            contactInformationFormObject,
                            [
                              'mobileNumberCountryISOCode',
                              'mobileNumberDialCode'
                            ]
                          )
                        " />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter a valid
                        contact number where you can be reached easily.
                      </span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && mobileNumber.invalid
                      ">
                      <span *ngIf="mobileNumber.errors.required">
                        Contact Number is required!
                      </span>
                      <span *ngIf="mobileNumber.errors?.minlength">
                        Contact Number should be of minimum 8 digits!
                      </span>
                      <span *ngIf="
                          !mobileNumber.errors?.minlength &&
                          mobileNumber.errors?.hasError
                        ">
                        {{ mobileNumber.errors.errorMessage }}
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Additional Number</label>
                    <div class="input_box">
                      <input type="text" name="phoneNumber" hint [identify-value-change]="contactInformationFormObject"
                        blockCopyPaste numberOnly [validate-phone]="['phoneNumber', 'Additional Number']"
                        [(ngModel)]="contactInformationFormObject.phoneNumber" #phoneNumber="ngModel"
                        class="focus_border" autocomplete="off" minlength="8" maxlength="11" ng2TelInput
                        [ng2TelInputOptions]="ng2TelInputOptionsWithDropdown" (countryChange)="
                          utils.setMobileNumberFields(
                            $event,
                            contactInformationFormObject,
                            ['phoneNumberCountryISOCode', 'phoneNumberDialCode']
                          )
                        " (intlTelInputObject)="
                          utils.setMobileNumberFields(
                            $event.s,
                            contactInformationFormObject,
                            ['phoneNumberCountryISOCode', 'phoneNumberDialCode']
                          )
                        " />
                      <span class="tooltip"><span class="tooltipArrow"></span>If applicable, enter
                        any secondary contact number where you can be contacted.
                        If you don't have an additional number, leave this field
                        blank.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && phoneNumber.invalid
                      ">
                      <span *ngIf="phoneNumber.errors.required">
                        Additional Number is required!
                      </span>
                      <span *ngIf="phoneNumber.errors?.minlength">
                        Additional Number should be of minimum 8 digits!
                      </span>
                      <span *ngIf="
                          !phoneNumber.errors?.minlength &&
                          phoneNumber.errors?.hasError
                        ">
                        {{ phoneNumber.errors.errorMessage }}
                      </span>
                    </span>
                  </div>
                </div>

                <h4 class="seprate">Permanent Address in Nigeria</h4>

                <div class="grid-two">
                  <div class="form_box">
                    <label class="fieldlabels">Address Line 1<span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="addressLine1" hint [identify-value-change]="contactInformationFormObject"
                        address-directive class="effect" required="required" autocomplete="off"
                        [(ngModel)]="contactInformationFormObject.addressLine1" #addressLine1="ngModel" minlength="10"
                        maxlength="50" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter the
                        address where you permanently reside. This should be
                        your primary residence, where you receive official
                        documents.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && addressLine1.invalid
                      ">
                      <span *ngIf="addressLine1.errors.required">
                        Address Line 1 is required!
                      </span>
                      <span *ngIf="addressLine1.errors?.minlength">
                        Address Line 1 should be of minimum 10 characters!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Address Line 2</label>
                    <div class="input_box">
                      <input type="text" name="addressLine2" hint [identify-value-change]="contactInformationFormObject"
                        address-directive class="effect" autocomplete="off"
                        [(ngModel)]="contactInformationFormObject.addressLine2" #addressLine2="ngModel" minlength="10"
                        maxlength="50" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter the
                        address where you permanently reside. This should be
                        your primary residence, where you receive official
                        documents.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && addressLine2.invalid
                      ">
                      <span *ngIf="addressLine2.errors.required">
                        Address Line 2 is required!
                      </span>
                      <span *ngIf="addressLine2.errors?.minlength">
                        Address Line 2 should be of minimum 10 characters!!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">State <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="addressState" hint [identify-value-change]="contactInformationFormObject"
                        [(ngModel)]="contactInformationFormObject.addressState" #addressState="ngModel" (ngModelChange)="
                          fetchStateLGA(
                            contactInformationFormObject,
                            'addressState',
                            contactInformationFormLGASelectService,
                            'addressLGA',
                            true
                          )
                        " class="effect" required="required">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              allStateSelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let state of allStateSelectService.filteredOptions
                              | async
                          " [value]="state.value">{{ state.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Please choose the
                        current state or location for contact purposes.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && addressState.invalid
                      ">
                      <span *ngIf="addressState.errors.required">
                        State is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="addressLGA" hint [identify-value-change]="contactInformationFormObject"
                        class="effect" required="required" [(ngModel)]="contactInformationFormObject.addressLGA"
                        #addressLGA="ngModel">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              contactInformationFormLGASelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let lga of contactInformationFormLGASelectService.filteredOptions
                              | async
                          " [value]="lga.value">{{ lga.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Please choose the
                        name of your LGA accurately according to the official
                        documents such as your government-issued ID or utility
                        bills.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && addressLGA.invalid
                      ">
                      <span *ngIf="addressLGA.errors.required">
                        LGA is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">City / Town <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="addressCity" hint [identify-value-change]="contactInformationFormObject"
                        class="effect" required="required" autocomplete="off"
                        [(ngModel)]="contactInformationFormObject.addressCity" #addressCity="ngModel" minlength="2"
                        maxlength="30" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the city of
                        your residence for contact purposes.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted && addressCity.invalid
                      ">
                      <span *ngIf="addressCity.errors.required">
                        City / Town is required!
                      </span>
                      <span *ngIf="addressCity.errors?.minlength">
                        City / Town should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !addressCity.errors?.minlength &&
                          addressCity.errors.pattern
                        ">
                        City / Town should start and end with alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Postal Code</label>
                    <div class="input_box">
                      <input type="text" name="addressPostalCode" hint
                        [identify-value-change]="contactInformationFormObject" numberOnly class="effect"
                        autocomplete="off" [(ngModel)]="
                          contactInformationFormObject.addressPostalCode
                        " #addressPostalCode="ngModel" minlength="6" maxlength="6" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the PIN code of
                        your location for contact purposes</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        contactInformationForm.submitted &&
                        addressPostalCode.invalid
                      ">
                      <span *ngIf="addressPostalCode.errors.required">
                        Postal Code is required!
                      </span>
                      <span *ngIf="addressPostalCode.errors?.minlength">
                        Postal Code should be of minimum 6 digits!
                      </span>
                    </span>
                  </div>
                </div>

                <h4 class="seprate">Current Address in Nigeria</h4>

                <label style="
                    margin-bottom: 10px;
                    width: auto;
                    display: inline-block;
                  " class="pointer font-normal">
                  <input style="
                      width: auto;
                      height: auto;
                      position: relative;
                      top: 2px;
                    " type="checkbox" name="isCurrentAddressSame" [(ngModel)]="
                      contactInformationFormObject.isCurrentAddressSame
                    " #isCurrentAddressSame="ngModel" (ngModelChange)="setCurrentAsPermanentAddress(false)" />
                  <span style="padding: 0 3px">Same as permanent address</span>
                </label>

                <div *ngIf="
                    contactInformationFormObject.isCurrentAddressSame === false
                  ">
                  <div class="grid-two">
                    <div class="form_box">
                      <label class="fieldlabels">Address Line 1<span class="medatory">*</span></label>
                      <div class="input_box">
                        <input type="text" name="currentAddressLine1" hint
                          [identify-value-change]="contactInformationFormObject" address-directive class="effect"
                          required="required" autocomplete="off" [(ngModel)]="
                            contactInformationFormObject.currentAddressLine1
                          " #currentAddressLine1="ngModel" minlength="10" maxlength="50" />
                        <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                          address where you are currently staying which includes
                          your house number, street name and locality.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          contactInformationForm.submitted &&
                          currentAddressLine1.invalid
                        ">
                        <span *ngIf="currentAddressLine1.errors.required">
                          Address Line 1 is required!
                        </span>
                        <span *ngIf="currentAddressLine1.errors?.minlength">
                          Address Line 1 should be of minimum 10 characters!!
                        </span>
                      </span>
                    </div>

                    <div class="form_box">
                      <label class="fieldlabels">Address Line 2</label>
                      <div class="input_box">
                        <input type="text" name="currentAddressLine2" hint
                          [identify-value-change]="contactInformationFormObject" address-directive class="effect"
                          autocomplete="off" [(ngModel)]="
                            contactInformationFormObject.currentAddressLine2
                          " #currentAddressLine2="ngModel" minlength="10" maxlength="50" />
                        <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                          address where you are currently staying which includes
                          your house number, street name and locality.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          contactInformationForm.submitted &&
                          currentAddressLine2.invalid
                        ">
                        <span *ngIf="currentAddressLine2.errors.required">
                          Address Line 2 is required!
                        </span>
                        <span *ngIf="currentAddressLine2.errors?.minlength">
                          Address Line 2 should be of minimum 10 characters!!
                        </span>
                      </span>
                    </div>

                    <div class="form_box">
                      <label class="fieldlabels">State <span class="medatory">*</span></label>
                      <div class="input_box">
                        <mat-select name="currentAddressState" hint
                          [identify-value-change]="contactInformationFormObject" [(ngModel)]="
                            contactInformationFormObject.currentAddressState
                          " #currentAddressState="ngModel" (ngModelChange)="
                            fetchStateLGA(
                              contactInformationFormObject,
                              'currentAddressState',
                              currentAddressLGASelectService,
                              'currentAddressLGA',
                              true
                            )
                          " class="effect" required="required">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="
                                allStateSelectService.selectFilterControl
                              " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                              autocomplete="off"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="
                              let state of allStateSelectService.filteredOptions
                                | async
                            " [value]="state.value">{{ state.text }}</mat-option>
                        </mat-select>
                        <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                          current state or location for contact purposes.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          contactInformationForm.submitted &&
                          currentAddressState.invalid
                        ">
                        <span *ngIf="currentAddressState.errors.required">
                          State is required!
                        </span>
                      </span>
                    </div>

                    <div class="form_box">
                      <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                      <div class="input_box">
                        <mat-select name="currentAddressLGA" hint [identify-value-change]="contactInformationFormObject"
                          class="effect" required="required" [(ngModel)]="
                            contactInformationFormObject.currentAddressLGA
                          " #currentAddressLGA="ngModel">
                          <mat-option>
                            <ngx-mat-select-search [formControl]="
                                currentAddressLGASelectService.selectFilterControl
                              " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                              autocomplete="off"></ngx-mat-select-search>
                          </mat-option>
                          <mat-option *ngFor="
                              let lga of currentAddressLGASelectService.filteredOptions
                                | async
                            " [value]="lga.value">{{ lga.text }}</mat-option>
                        </mat-select>
                        <span class="tooltip"><span class="tooltipArrow"></span>Enter the name of
                          your LGA accurately according to the official
                          documents such as your government-issued ID or utility
                          bills.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          contactInformationForm.submitted &&
                          currentAddressLGA.invalid
                        ">
                        <span *ngIf="currentAddressLGA.errors.required">
                          LGA is required!
                        </span>
                      </span>
                    </div>

                    <div class="form_box">
                      <label class="fieldlabels">City / Town <span class="medatory">*</span></label>
                      <div class="input_box">
                        <input type="text" name="currentAddressCity" hint
                          [identify-value-change]="contactInformationFormObject" class="effect" required="required"
                          autocomplete="off" [(ngModel)]="
                            contactInformationFormObject.currentAddressCity
                          " #currentAddressCity="ngModel" minlength="2" maxlength="30"
                          [pattern]="pattern.COMMON_FIELD_PATTERN" />
                        <span class="tooltip"><span class="tooltipArrow"></span>Enter the city of
                          your residence for contact purposes.</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          contactInformationForm.submitted &&
                          currentAddressCity.invalid
                        ">
                        <span *ngIf="currentAddressCity.errors.required">
                          City / Town is required!
                        </span>
                        <span *ngIf="currentAddressCity.errors?.minlength">
                          City / Town should be of minimum 2 characters!
                        </span>
                        <span *ngIf="
                            !currentAddressCity.errors?.minlength &&
                            currentAddressCity.errors?.pattern
                          ">
                          City / Town should start and end with alphabet!
                        </span>
                      </span>
                    </div>

                    <div class="form_box">
                      <label class="fieldlabels">Postal Code </label>
                      <div class="input_box">
                        <input type="text" name="currentAddressPostalCode" hint
                          [identify-value-change]="contactInformationFormObject" numberOnly class="effect"
                          autocomplete="off" [(ngModel)]="
                            contactInformationFormObject.currentAddressPostalCode
                          " #currentAddressPostalCode="ngModel" minlength="6" maxlength="6" />
                        <span class="tooltip"><span class="tooltipArrow"></span>Enter the PIN code
                          of your location for contact purposes</span>
                        <span class="focus-border"><i></i></span>
                      </div>
                      <span class="alert alert-danger" role="alert" *ngIf="
                          contactInformationForm.submitted &&
                          currentAddressPostalCode.invalid
                        ">
                        <span *ngIf="currentAddressPostalCode.errors.required">
                          Postal Code is required!
                        </span>
                        <span *ngIf="currentAddressPostalCode.errors?.minlength">
                          Postal Code should be of minimum 6 digits!
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid-onethird-box upload_inst">
                <div class="element mt20 none_dis">
                  <h4>Instructions</h4>

                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>
              </div>
            </div>

            <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
              value="Previous Page" />

            <input type="submit" (click)="shallExitApplication = constants.TRUE" class="save_btn" value="Save & Exit" />

            <input type="submit" (click)="shallExitApplication = constants.FALSE" class="action-button custom_btn"
              value="Continue" />
          </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === constants.KIN_INFO_STAGE">
          <div class="step_info">
            <span>Step {{ constants.KIN_INFO_STAGE }}:
              {{
              applicationStepsList[constants.KIN_INFO_STAGE - 1].label
              }}</span>
          </div>

          <form #kinInformationForm="ngForm" (ngSubmit)="
              kinInformationForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY && openSupportingDocumentsForm($event))
            " autocomplete="off" name="kinInformationForm" id="msform" appFocus>
            <input type="hidden" name="hasFieldValueChanged" [(ngModel)]="kinInformationFormObject.hasFieldValueChanged"
              #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName" [(ngModel)]="kinInformationFormObject.callableFunctionName"
              #callableFunctionName="ngModel" />

            <ng-template #instructions>
              <ul>
                <li>
                  <span>You are required to enter a valid next of kin
                    information.</span>
                </li>

                <li>
                  <span>Enter a valid contact number in the format
                    +234-1234567891.</span>
                </li>

                <li>
                  All fields that contain the Asterisk (*) sign are mandatory
                  and must be completed.
                </li>
              </ul>
            </ng-template>

            <div class="grid-one-third">
              <div class="grid-onethird-box">
                <details>
                  <summary>Instructions for Applicant</summary>

                  <div class="content">
                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                  </div>
                </details>

                <div class="grid-two">
                  <div class="form_box">
                    <label class="fieldlabels">Name<span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="kinName" hint [identify-value-change]="kinInformationFormObject"
                        name-with-number-directive class="effect" required="required" autocomplete="off"
                        [(ngModel)]="kinInformationFormObject.kinName" #kinName="ngModel" minlength="2" maxlength="30"
                        pattern="^[A-Za-z].*[A-Za-z0-9]$" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                        full name of a trusted contact who will serve as your
                        witness for your passport application.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="kinInformationForm.submitted && kinName.invalid">
                      <span *ngIf="kinName.errors.required">
                        Name of Next of Kin is required!
                      </span>
                      <span *ngIf="kinName.errors?.minlength">
                        Name of Next of Kin should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !kinName.errors?.minlength && kinName.errors.pattern
                        ">
                        Name of Next of Kin should start and end with alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Relationship <span class="medatory">*</span></label>
                    <div class="input_box">
                      <select name="kinRelation" hint [identify-value-change]="kinInformationFormObject" class="effect"
                        required="required" [(ngModel)]="kinInformationFormObject.kinRelation" #kinRelation="ngModel">
                        <option value="">Choose Option</option>
                        <option [value]="constants.FATHER">Father</option>
                        <option [value]="constants.MOTHER">Mother</option>
                        <option [value]="constants.SON">Son</option>
                        <option [value]="constants.DAUGHTER">Daughter</option>
                        <option [value]="constants.HUSBAND">Husband</option>
                        <option [value]="constants.WIFE">Wife</option>
                        <option [value]="constants.BROTHER">Brother</option>
                        <option [value]="constants.SISTER">Sister</option>
                        <option [value]="constants.FRIEND">Friend</option>
                        <option [value]="constants.RELATIVE">Relative</option>
                      </select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Select the
                        relationship that best describes your connection to next
                        of kin.</span>
                      <span class="focus-border"><i></i></span>
                      <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted && kinRelation.invalid
                      ">
                      <span *ngIf="kinRelation.errors.required">
                        Relationship is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Address Line 1<span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="addressLine1" hint [identify-value-change]="kinInformationFormObject"
                        address-directive class="effect" required="required" autocomplete="off"
                        [(ngModel)]="kinInformationFormObject.addressLine1" #addressLine1="ngModel" minlength="10"
                        maxlength="50" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                        address of next of kin which includes the house number,
                        street name and locality.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted && addressLine1.invalid
                      ">
                      <span *ngIf="addressLine1.errors.required">
                        Address Line 1 is required!
                      </span>
                      <span *ngIf="addressLine1.errors?.minlength">
                        Address Line 1 should be of minimum 10 characters!!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Address Line 2</label>
                    <div class="input_box">
                      <input type="text" name="addressLine2" hint [identify-value-change]="kinInformationFormObject"
                        address-directive class="effect" autocomplete="off"
                        [(ngModel)]="kinInformationFormObject.addressLine2" #addressLine2="ngModel" minlength="10"
                        maxlength="50" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                        address of next of kin which includes the house number,
                        street name and locality.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted && addressLine2.invalid
                      ">
                      <span *ngIf="addressLine2.errors.required">
                        Address Line 2 is required!
                      </span>
                      <span *ngIf="addressLine2.errors?.minlength">
                        Address Line 2 should be of minimum 10 characters!!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">State <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="addressState" hint [identify-value-change]="kinInformationFormObject"
                        [(ngModel)]="kinInformationFormObject.addressState" #addressState="ngModel" (ngModelChange)="
                          fetchStateLGA(
                            kinInformationFormObject,
                            'addressState',
                            kinInformationFormLGASelectService,
                            'addressLGA',
                            true
                          )
                        " class="effect" required="required">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              allStateSelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let state of allStateSelectService.filteredOptions
                              | async
                          " [value]="state.value">{{ state.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                        current state or location of your next of kin for
                        contact purposes.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted && addressState.invalid
                      ">
                      <span *ngIf="addressState.errors.required">
                        State is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="addressLGA" hint [identify-value-change]="kinInformationFormObject"
                        class="effect" required="required" [(ngModel)]="kinInformationFormObject.addressLGA"
                        #addressLGA="ngModel">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              kinInformationFormLGASelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let lga of kinInformationFormLGASelectService.filteredOptions
                              | async
                          " [value]="lga.value">{{ lga.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the name of
                        your LGA accurately according to the official documents
                        such as your government-issued ID or utility
                        bills.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="kinInformationForm.submitted && addressLGA.invalid">
                      <span *ngIf="addressLGA.errors.required">
                        LGA is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">City / Town <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="addressCity" hint [identify-value-change]="kinInformationFormObject"
                        class="effect" required="required" autocomplete="off"
                        [(ngModel)]="kinInformationFormObject.addressCity" #addressCity="ngModel" minlength="2"
                        maxlength="30" [pattern]="pattern.COMMON_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the city of
                        your next of kin's residence for contact purposes.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted && addressCity.invalid
                      ">
                      <span *ngIf="addressCity.errors.required">
                        City / Town is required!
                      </span>
                      <span *ngIf="addressCity.errors?.minlength">
                        City / Town should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !addressCity.errors?.minlength &&
                          addressCity.errors?.pattern
                        ">
                        City / Town should start and end with an alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Postal Code</label>
                    <div class="input_box">
                      <input type="text" name="addressPostalCode" hint
                        [identify-value-change]="kinInformationFormObject" numberOnly class="effect" autocomplete="off"
                        [(ngModel)]="kinInformationFormObject.addressPostalCode" #addressPostalCode="ngModel"
                        minlength="6" maxlength="6" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the PIN code of
                        your next of kin's location for contact purposes</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted &&
                        addressPostalCode.invalid
                      ">
                      <span *ngIf="addressPostalCode.errors.required">
                        Postal Code is required!
                      </span>
                      <span *ngIf="addressPostalCode.errors?.minlength">
                        Postal Code should be of minimum 6 digits!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Contact Number <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="mobileNumber" hint [identify-value-change]="kinInformationFormObject"
                        blockCopyPaste numberOnly [(ngModel)]="kinInformationFormObject.mobileNumber"
                        #mobileNumber="ngModel" class="focus_border" required="required" autocomplete="off"
                        minlength="8" maxlength="11" ng2TelInput [ng2TelInputOptions]="ng2TelInputOptions"
                        (intlTelInputObject)="
                          utils.setMobileNumberFields(
                            $event.s,
                            kinInformationFormObject,
                            [
                              'mobileNumberCountryISOCode',
                              'mobileNumberDialCode'
                            ]
                          )
                        " />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter a valid
                        phone number of kin where he/she can be reached
                        easily.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        kinInformationForm.submitted && mobileNumber.invalid
                      ">
                      <span *ngIf="mobileNumber.errors.required">
                        Contact Number is required!
                      </span>
                      <span *ngIf="mobileNumber.errors?.minlength">
                        Contact Number should be of minimum 8 digits!
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div class="grid-onethird-box upload_inst">
                <div class="element mt20 none_dis none_dis">
                  <h4>Instructions</h4>

                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>
              </div>
            </div>

            <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
              value="Previous Page" />

            <input type="submit" (click)="shallExitApplication = constants.TRUE" class="save_btn" value="Save & Exit" />

            <input type="submit" (click)="shallExitApplication = constants.FALSE" class="action-button custom_btn"
              value="Continue" />
          </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === constants.DOCUMENTS_UPLOADED_STAGE" class="support_document">
          <div class="step_info">
            <span>Step {{ constants.DOCUMENTS_UPLOADED_STAGE }}:
              {{
              applicationStepsList[constants.DOCUMENTS_UPLOADED_STAGE - 1]
              .label
              }}</span>
          </div>

          <form #supportingDocumentsForm="ngForm" (ngSubmit)="
              supportingDocumentsForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY &&
                  checkAndOpenAfterDocumentUploadStage($event))
            " autocomplete="off" name="supportingDocumentsForm" id="msform" appFocus>
            <input type="hidden" name="hasFieldValueChanged"
              [(ngModel)]="supportingDocumentsFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName"
              [(ngModel)]="supportingDocumentsFormObject.callableFunctionName" #callableFunctionName="ngModel" />

            <div class="mb10">
              <span style="color: red; font-weight: 600">PLEASE ENSURE UPLOADED DOCUMENTS ARE CORRECT & PROPERLY
                READABLE TO AVOID REJECTION</span>
            </div>

            <ng-template #instructions>
              <ul>
                <li>
                  <span>Please upload all the required documents as displayed here
                    for the processing of your Passport application.</span>
                </li>

                <li>
                  For proceeding to the next step, please ensure you are
                  uploading the correct document in the respective field.
                </li>

                <li>
                  All fields that contain the Asterisk (*) sign are mandatory
                  and must be completed.
                </li>
              </ul>
            </ng-template>

            <div class="grid-one-third">
              <div class="grid-onethird-box">
                <details class="support_document-details">
                  <summary>Instructions for Applicant</summary>

                  <div class="content">
                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                  </div>
                </details>

                <ng-container *ngFor="let applicableDocSectionObject of applicableDocsList">
                  <table class="DocUpload-tb">
                    <thead>
                      <tr>
                        <th>{{ applicableDocSectionObject.groupTypeLabel }}</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="
                          let applicableDocObject of applicableDocSectionObject.groupDocs;
                          let i = index
                        ">
                        <td class="upload-support-doc">
                          {{ i + 1 }}.
                          {{ applicableDocObject.docTypeLabel }}&nbsp;<span *ngIf="
                              applicableDocObject.isMandatory === constants.TRUE
                            " class="medatory">*</span>

                          <ng-container *ngIf="
                              applicableDocObject.docType ===
                              constants.GUARANTOR_FORM
                            ">
                            <span class="book_center" style="float: none; width: auto; font-size: 14px">
                              (<a [routerLink]="['/guarantor']" target="_blank" rel="noopener noreferrer"
                                style="color: #0087ff">Click here</a>
                              to Download Guarantor's Form)</span>
                          </ng-container>

                          <input type="hidden" [name]="
                              'base64Data' + applicableDocObject.applicantDocId
                            " [required]="
                              applicableDocObject.isMandatory === constants.TRUE
                            " [(ngModel)]="applicableDocObject.base64Data" #base64Data="ngModel" />

                          <span class="alert alert-danger" role="alert" *ngIf="
                              supportingDocumentsForm.submitted &&
                              base64Data.invalid
                            ">
                            <span *ngIf="base64Data.errors.required">
                              {{ applicableDocObject.docTypeLabel }} is
                              required!
                            </span>
                          </span>
                        </td>

                        <td class="actionable upload-support-doc-btn">
                          <ng-container *ngIf="applicableDocObject.isDocUploaded === false">
                            <span (click)="openFileUploadModal(applicableDocObject)" class="upload">Upload</span>
                          </ng-container>

                          <ng-container *ngIf="applicableDocObject.isDocUploaded === true">
                            <span class="check"><i class="fa fa-check-circle" aria-hidden="true"></i></span>

                            <span (click)="
                                openFilePreviewModal(applicableDocObject)
                              " class="preview">Preview</span>

                            <span (click)="deleteUploadedFile(applicableDocObject)" class="delete">Delete</span>
                          </ng-container>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>

                <label class="t_and_c pb10 pointer">
                  <input type="checkbox" name="termCondition" [(ngModel)]="
                      supportingDocumentsFormObject.isTermConditionSelect
                    " #termCondition="ngModel" required="required" />
                  <span style="padding: 0 3px">
                    I hereby confirm that all the documents I have provided are
                    genuine & properly readable.</span>
                  <span class="alert alert-danger" role="alert" *ngIf="
                      supportingDocumentsForm.submitted && termCondition.invalid
                    ">
                    <span *ngIf="termCondition.errors.required">
                      Confirmation is required
                    </span>
                  </span>
                </label>
              </div>

              <div class="grid-onethird-box upload_inst">
                <div class="element none_dis">
                  <h4>Instructions</h4>

                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>
              </div>
            </div>

            <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
              value="Previous Page" />

            <input type="submit" (click)="shallExitApplication = constants.TRUE" class="save_btn" value="Save & Exit" />

            <input type="submit" (click)="shallExitApplication = constants.FALSE" class="action-button custom_btn"
              value="Continue" />
          </form>
        </fieldset>

        <fieldset *ngIf="
            applicationStage === constants.PASSPORT_DELIVERY_STAGE &&
            enablePassportDeliveryStage === true
          " class="passport_delivery">
          <div class="step_info">
            <span>Step {{ constants.PASSPORT_DELIVERY_STAGE }}:
              {{
              applicationStepsList[constants.PASSPORT_DELIVERY_STAGE - 1]
              .label
              }}</span>
          </div>

          <ng-template #instructions>
            <ul>
              <li>
                <span>You are required to enter a valid receiver's information
                  related to your passport delivery.</span>
              </li>

              <li>
                The delivery fee is calculated based on the
                <b>processing state</b> chosen in step 1 and the
                <b>state</b> chosen here.
              </li>

              <li>
                <span>Enter a valid contact number in the format
                  +234-1234567891.</span>
              </li>

              <li>
                All fields that contain the Asterisk (*) sign are mandatory and
                must be completed.
              </li>
            </ul>
          </ng-template>

          <form #passportDeliveryForm="ngForm" (ngSubmit)="
              passportDeliveryForm.form.valid &&
                (menuClickedStage =
                  this.constants.ANY && openApplicationPreview($event))
            " autocomplete="off" name="passportDeliveryForm" id="msform" appFocus>
            <input type="hidden" name="hasFieldValueChanged"
              [(ngModel)]="passportDeliveryFormObject.hasFieldValueChanged" #hasFieldValueChanged="ngModel" />

            <input type="hidden" name="callableFunctionName"
              [(ngModel)]="passportDeliveryFormObject.callableFunctionName" #callableFunctionName="ngModel" />

            <div>
              <h4>How would you like to receive your passport?</h4>

              <div class="radio-grid-wrap">
                <div class="radio-grid">
                  <label class="flex_row radio-span pointer">
                    <input type="radio" name="deliveryOpted" [identify-value-change]="passportDeliveryFormObject"
                      #deliveryOpted="ngModel" [(ngModel)]="passportDeliveryFormObject.deliveryOpted"
                      [value]="constants.FALSE" class="image_upload_radio radio-btn" required="required" />
                    <span>Pick up your passport at the Immigration Processing
                      Centre.</span>
                  </label>
                </div>

                <div *ngIf="isDeliveryAvailable === constants.TRUE" class="radio-grid">
                  <label class="flex_row radio-span pointer">
                    <input type="radio" name="deliveryOpted" [identify-value-change]="passportDeliveryFormObject"
                      #deliveryOpted="ngModel" [(ngModel)]="passportDeliveryFormObject.deliveryOpted"
                      [value]="constants.TRUE" class="image_upload_radio radio-btn" required="required" />
                    <span>Courier Service (Additional Fee will be charged).</span>
                  </label>
                </div>

                <span class="alert alert-danger" role="alert" *ngIf="
                    passportDeliveryForm.submitted && deliveryOpted.invalid
                  ">
                  <span *ngIf="deliveryOpted.errors.required">
                    Please select any option for Passport Delivery!
                  </span>
                </span>
              </div>
            </div>

            <div *ngIf="
                isDeliveryAvailable === constants.TRUE &&
                passportDeliveryFormObject.deliveryOpted === constants.TRUE
              " class="grid-one-third">
              <div class="grid-onethird-box">
                <details>
                  <summary>Instructions for Applicant</summary>

                  <div class="content">
                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                  </div>
                </details>

                <div class="grid-two">
                  <div class="form_box">
                    <label class="fieldlabels">Name of Receiver<span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="receiverName" hint [identify-value-change]="passportDeliveryFormObject"
                        name-with-number-directive class="effect" required="required" autocomplete="off"
                        [(ngModel)]="passportDeliveryFormObject.receiverName" #receiverName="ngModel" minlength="2"
                        maxlength="30" pattern="^[A-Za-z].*[A-Za-z0-9]$" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter the name
                        of the individual authorized to receive your
                        passport.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted && receiverName.invalid
                      ">
                      <span *ngIf="receiverName.errors.required">
                        Name of Receiver is required!
                      </span>
                      <span *ngIf="receiverName.errors?.minlength">
                        Name of Receiver should be of minimum 2 characters!
                      </span>
                      <span *ngIf="
                          !receiverName.errors?.minlength &&
                          receiverName.errors.pattern
                        ">
                        Name of Receiver should start and end with alphabet!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Receiver's E-mail Address
                      <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="email" name="emailId" hint [identify-value-change]="passportDeliveryFormObject"
                        [(ngModel)]="passportDeliveryFormObject.emailId" #emailId="ngModel" class="effect"
                        maxlength="100" required="required" [pattern]="pattern.EMAIL_FIELD_PATTERN" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide a
                        valid email address of receiver in the format
                        yourname@example.com. This email will serve as your
                        primary point of contact regarding your passport
                        delivery.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert"
                      *ngIf="passportDeliveryForm.submitted && emailId.invalid">
                      <span *ngIf="emailId.errors.required">
                        Receiver's E-mail Address is required!
                      </span>
                      <span *ngIf="emailId.errors.pattern">
                        Receiver's E-mail Address should be valid!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Receiver's Contact Number
                      <span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="mobileNumber" hint [identify-value-change]="passportDeliveryFormObject"
                        blockCopyPaste numberOnly [validate-phone]="['mobileNumber', 'Contact Number']"
                        [(ngModel)]="passportDeliveryFormObject.mobileNumber" #mobileNumber="ngModel"
                        class="focus_border" required="required" autocomplete="off" minlength="8" maxlength="11"
                        ng2TelInput [ng2TelInputOptions]="ng2TelInputOptions" (intlTelInputObject)="
                          utils.setMobileNumberFields(
                            $event.s,
                            passportDeliveryFormObject,
                            [
                              'mobileNumberCountryISOCode',
                              'mobileNumberDialCode'
                            ]
                          )
                        " />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter a valid
                        contact number of receiver where he/she can be reached
                        easily.
                      </span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted && mobileNumber.invalid
                      ">
                      <span *ngIf="mobileNumber.errors.required">
                        Receiver's Contact Number is required!
                      </span>
                      <span *ngIf="mobileNumber.errors?.minlength">
                        Receiver's Contact Number should be of minimum 8 digits!
                      </span>
                      <span *ngIf="
                          !mobileNumber.errors?.minlength &&
                          mobileNumber.errors?.hasError
                        ">
                        {{ mobileNumber.errors.errorMessage }}
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Alternate Phone Number</label>
                    <div class="input_box">
                      <input type="text" name="phoneNumber" hint [identify-value-change]="passportDeliveryFormObject"
                        blockCopyPaste numberOnly [validate-phone]="['phoneNumber', 'Additional Number']"
                        [(ngModel)]="passportDeliveryFormObject.phoneNumber" #phoneNumber="ngModel" class="focus_border"
                        autocomplete="off" minlength="8" maxlength="11" ng2TelInput
                        [ng2TelInputOptions]="ng2TelInputOptions" (countryChange)="
                          utils.setMobileNumberFields(
                            $event,
                            passportDeliveryFormObject,
                            ['phoneNumberCountryISOCode', 'phoneNumberDialCode']
                          )
                        " (intlTelInputObject)="
                          utils.setMobileNumberFields(
                            $event.s,
                            passportDeliveryFormObject,
                            ['phoneNumberCountryISOCode', 'phoneNumberDialCode']
                          )
                        " />
                      <span class="tooltip"><span class="tooltipArrow"></span>If applicable, enter
                        any secondary contact number where receiver can be
                        contacted. If you don't have an additional number, leave
                        this field blank.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted && phoneNumber.invalid
                      ">
                      <span *ngIf="phoneNumber.errors.required">
                        Alternate Phone Number is required!
                      </span>
                      <span *ngIf="phoneNumber.errors?.minlength">
                        Alternate Phone Number should be of minimum 8 digits!
                      </span>
                      <span *ngIf="
                          !phoneNumber.errors?.minlength &&
                          phoneNumber.errors?.hasError
                        ">
                        {{ phoneNumber.errors.errorMessage }}
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Receiver's Address Line 1<span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="receiverAddressLine1" hint
                        [identify-value-change]="passportDeliveryFormObject" address-directive class="effect"
                        required="required" autocomplete="off" [(ngModel)]="
                          passportDeliveryFormObject.receiverAddressLine1
                        " #receiverAddressLine1="ngModel" minlength="10" maxlength="150" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter the
                        address where receiver permanently reside.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted &&
                        receiverAddressLine1.invalid
                      ">
                      <span *ngIf="receiverAddressLine1.errors.required">
                        Receiver's Address Line 1 is required!
                      </span>
                      <span *ngIf="receiverAddressLine1.errors?.minlength">
                        Receiver's Address Line 1 should be of minimum 10
                        characters!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Receiver's Address Line 2</label>
                    <div class="input_box">
                      <input type="text" name="receiverAddressLine2" hint
                        [identify-value-change]="passportDeliveryFormObject" address-directive class="effect"
                        autocomplete="off" [(ngModel)]="
                          passportDeliveryFormObject.receiverAddressLine2
                        " #receiverAddressLine2="ngModel" minlength="10" maxlength="150" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Please enter the
                        address where receiver permanently reside.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted &&
                        receiverAddressLine2.invalid
                      ">
                      <span *ngIf="receiverAddressLine2.errors.required">
                        Receiver's Address Line 2 is required!
                      </span>
                      <span *ngIf="receiverAddressLine2.errors?.minlength">
                        Receiver's Address Line 2 should be of minimum 10
                        characters!!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">State <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="receiverAddressState" hint [identify-value-change]="passportDeliveryFormObject"
                        [(ngModel)]="
                          passportDeliveryFormObject.receiverAddressState
                        " #receiverAddressState="ngModel" (ngModelChange)="
                          fetchStateLGA(
                            passportDeliveryFormObject,
                            'receiverAddressState',
                            passportDeliveryFormLGASelectService,
                            'receiverAddressLga',
                            true
                          );
                          fetchPassportDeliveryFeeDetails()
                        " class="effect" required="required">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              allStateSelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let state of allStateSelectService.filteredOptions
                              | async
                          " [value]="state.value">{{ state.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Please provide the
                        current state or location for passport delivery.
                      </span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted &&
                        receiverAddressState.invalid
                      ">
                      <span *ngIf="receiverAddressState.errors.required">
                        State is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                    <div class="input_box">
                      <mat-select name="receiverAddressLga" hint [identify-value-change]="passportDeliveryFormObject"
                        class="effect" required="required" [(ngModel)]="
                          passportDeliveryFormObject.receiverAddressLga
                        " #receiverAddressLga="ngModel">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="
                              passportDeliveryFormLGASelectService.selectFilterControl
                            " noEntriesFoundLabel="No data found" placeholderLabel="Search"
                            autocomplete="off"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="
                            let lga of passportDeliveryFormLGASelectService.filteredOptions
                              | async
                          " [value]="lga.value">{{ lga.text }}</mat-option>
                      </mat-select>
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the name of the
                        LGA accurately according to the state.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted &&
                        receiverAddressLga.invalid
                      ">
                      <span *ngIf="receiverAddressLga.errors.required">
                        LGA is required!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Landmark<span class="medatory">*</span></label>
                    <div class="input_box">
                      <input type="text" name="receiverAddressLandmark" hint
                        [identify-value-change]="passportDeliveryFormObject" address-directive class="effect"
                        required="required" autocomplete="off" [(ngModel)]="
                          passportDeliveryFormObject.receiverAddressLandmark
                        " #receiverAddressLandmark="ngModel" minlength="10" maxlength="150" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Mention a significant
                        nearby landmark to assist in locating the
                        destination.</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted &&
                        receiverAddressLandmark.invalid
                      ">
                      <span *ngIf="receiverAddressLandmark.errors.required">
                        Landmark is required!
                      </span>
                      <span *ngIf="receiverAddressLandmark.errors?.minlength">
                        Landmark should be of minimum 10 characters!
                      </span>
                    </span>
                  </div>

                  <div class="form_box">
                    <label class="fieldlabels">Postal Code </label>
                    <div class="input_box">
                      <input type="text" name="receiverAddressPostalCode" hint
                        [identify-value-change]="passportDeliveryFormObject" numberOnly class="effect"
                        autocomplete="off" [(ngModel)]="
                          passportDeliveryFormObject.receiverAddressPostalCode
                        " #receiverAddressPostalCode="ngModel" minlength="6" maxlength="6" />
                      <span class="tooltip"><span class="tooltipArrow"></span>Enter the PIN code of
                        the location for passport delivery</span>
                      <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="
                        passportDeliveryForm.submitted &&
                        receiverAddressPostalCode.invalid
                      ">
                      <span *ngIf="receiverAddressPostalCode.errors.required">
                        Postal Code is required!
                      </span>
                      <span *ngIf="receiverAddressPostalCode.errors?.minlength">
                        Postal Code should be of minimum 6 digits!
                      </span>
                    </span>
                  </div>
                </div>

                <label class="t_and_c pointer">
                  <input type="checkbox" name="termCondition" [(ngModel)]="
                      passportDeliveryFormObject.isTermConditionSelect
                    " #termCondition="ngModel" required="required" />
                  <span style="padding: 0 3px">
                    I have read and agreed to the
                    <a [href]="passportDeliveryTermsLink" target="_blank"
                      style="color: #0087ff; text-decoration: underline">Terms and Conditions</a>.</span>
                  <span class="alert alert-danger" role="alert" *ngIf="
                      passportDeliveryForm.submitted && termCondition.invalid
                    ">
                    <span *ngIf="termCondition.errors.required">
                      Confirmation is required
                    </span>
                  </span>
                </label>
              </div>

              <div class="grid-onethird-box upload_inst">
                <div *ngIf="isShowDeliveryFeesSection === false" class="element mt20 none_dis">
                  <h4>Instructions</h4>
                  <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                </div>

                <div *ngIf="isShowDeliveryFeesSection === true" class="grid-box">
                  <div class="fee">
                    <div *ngIf="deliveryFeeDetailsObject.codFee > 0">
                      COD Fee:
                      <span>{{ deliveryFeeDetailsObject.currencySymbol }}
                        {{
                        deliveryFeeDetailsObject.codFee
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div>
                      Passport Booklet Fee:
                      <span>{{ deliveryFeeDetailsObject.currencySymbol }}
                        {{
                        deliveryFeeDetailsObject.bookletFee
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div *ngIf="deliveryFeeDetailsObject.premiumFee > 0">
                      Passport Premium Fee:
                      <span>{{ deliveryFeeDetailsObject.currencySymbol }}
                        {{
                        deliveryFeeDetailsObject.premiumFee
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div>
                      Passport Delivery Fee:
                      <span>{{ deliveryFeeDetailsObject.currencySymbol }}
                        {{
                        deliveryFeeDetailsObject.deliveryFee
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <!-- Date of Change : 21-August 2024
                                        Reason: Removed the delivery service charge from users -->
                    <div class="d-none">
                      Passport Delivery Service Charge:
                      <span>{{ deliveryFeeDetailsObject.currencySymbol }}
                        {{
                        deliveryFeeDetailsObject.deliveryServiceCharge
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div *ngIf="
                        deliveryFeeDetailsObject.addressVerificationFee > 0
                      ">
                      Address Verification Fee:
                      <span>{{ deliveryFeeDetailsObject.currencySymbol }}
                        {{
                        deliveryFeeDetailsObject.addressVerificationFee
                        | number : "1.2" : "en-US"
                        }}</span>
                    </div>

                    <div>
                      <b>Total Fee:</b>
                      <span><b>{{ deliveryFeeDetailsObject.currencySymbol }}
                          {{
                          (deliveryFeeDetailsObject.deliveryServiceCharge
                          ? (deliveryFeeDetailsObject.totalFee -
                          deliveryFeeDetailsObject.deliveryServiceCharge)
                          : deliveryFeeDetailsObject.totalFee)
                          | number : "1.2" : "en-US"
                          }}</b></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn"
              value="Previous Page" />

            <input type="submit" (click)="shallExitApplication = constants.TRUE" class="save_btn" value="Save & Exit" />

            <input type="submit" (click)="shallExitApplication = constants.FALSE" class="action-button custom_btn"
              value="Continue" />
          </form>
        </fieldset>
      </div>
    </div>
  </section>
</main>

<app-footer></app-footer>