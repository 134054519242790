<app-header></app-header>

<div class="banner-img-name"></div>

<main class="body_section">

    <div class="main">

        <div class="mid-section">

            <h4>Passport Application Services: </h4>

            <p>All Citizens of Nigeria either by Birth, Descent, Adoption, Registration or Naturalization are eligible for a Nigerian Passport, provided they satisfy all conditions prescribed by law. Nigerian Citizens by Naturalization, Registration, Adoption and Surrogacy can only process their applications at the NIS Headquarters, Abuja. </p>
            
            <div class="mt15">
                <p style="color: #f96922; font-weight: bold; font-size: 16px;">We are introducing a premium service option for applicants who wish to fast-track their passport application. Click <a [href]="passportPremiumServiceInfoUrl" target="_blank" style="color: #0087ff; text-decoration: underline;">here</a> to learn more.</p>
            </div>

            <div class="rows_box">
            
                <div class="grid_three home_grid">
    
                    <div class="col_box">
                        <div (click)="gui.openNINVerificationPage(constants.FRESH)" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/passport.svg">
                                <div class="card_box_item">
                                    <h3>Apply for Fresh Passport</h3>
                                    <p>Click here to apply for a new passport.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col_box">
                        <div (click)="gui.openNINVerificationPage(constants.RENEW)" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/re-new-app.jpg">
                                <div class="card_box_item">
                                    <h3>Apply for Renewal/Re-issue</h3>
                                    <p>Click here to renew/re-issue your passport.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col_box">
                        <div (click)="gui.openNINVerificationPage(constants.REISSUE)" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/cod.svg">
                                <div class="card_box_item">
                                    <h3>Apply for Correction of Data</h3>
                                    <p>Click here to apply for the correction of data in your current passport.</p>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col_box">
                        <div (click)="openSearchTypeForm(constants.RESUME_APPLICATION)" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/editt.png">
                                <div class="card_box_item">
                                    <h3>Resume/Edit your Application</h3>
                                    <p>Click here to resume your pending application.</p>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col_box">
                        <div (click)="openSearchTypeForm(constants.PAYMENT_STATUS)" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/receipt.svg">
                                <div class="card_box_item">
                                    <h3>Application Status/Print Receipt</h3>
                                    <p>Click here to check your application status or print your receipt.</p>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="col_box">
                        <div (click)="openBookAppointmentForm()" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/icons3.png">
                                <div class="card_box_item">
                                    <h3>Book Appointment</h3>
                                    <p>Click here to book appointment for processing your passport application.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>

        <div class="mid-section mt20">

            <h4>Before you Proceed: </h4>

            <div class="rows_box" style="margin-top: 0;">

                <div class="grid_three">

                    <div class="col_box">
                        <div (click)="openCentreAvailabilityForm()" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/building.png">
                                <div class="card_box_item">
                                    <h3>Check Centre Availability</h3>
                                    <p>Click here to check the earliest available appointment date for each processing centre.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col_box">
                        <div (click)="openTestImageForm()" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/test-app.png">
                                <div class="card_box_item">
                                    <h3>Test Photo Upload</h3>
                                    <p>Click here to check your passport size photo compliance before you proceed to the passport application.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col_box">
                        <div (click)="openPassportPremiumInfoURL()" class="card_box_pass h-1">
                            <div class="top_pass">
                                <img src="assets/images/diamond-passport.png">
                                <div class="card_box_item">
                                    <h3>Premium Passport Service</h3>
                                    <p>Click here to know more about premium passport services.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            
        </div>

    </div>

</main>

<app-footer></app-footer>
