<div class="guidelines-container">

    <div class="modal-header">

        <h4>Photo Guidelines</h4>

        <i class="fa fa-times" aria-hidden="true" (click)="cancel()"></i>

    </div>

    <div class="modal-body">

        <div class="grid-guideline">
            <div class="grid-boxs ">
                <!-- <p><b>This image includes tips for taking a good passport photo when upload image.</b></p>
                <img src="assets/images/graphic-photo.jpg" alt="Guidelines photo" style="width:100%;"> -->
               <table>
                <thead>
                    <th> Image Requirements Composition</th>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Content
                        </td>
                        <td>
                            The image must contain the full face, neck, and shoulders of the applicant in frontal view with a neutral, non-smiling expression and with eyes open and unobstructed and directed at the camera. All facial features must be visible and unobstructed. No extraneous objects, additional people, parts of the body below the applicant’s shoulders, or other artifacts. The image must be from a recent (within 6 months) photo of the applicant.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Head Size
                        </td>
                        <td>
                            The head height or facial region size (measured from the top of the head, including the hair, to the bottom of the chin) must be between 50% and 69% of the height of the image. The eye height (measured from the bottom of the image to the level of the eyes) should be between 56% and 69% of the height of the image.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Head Orientation	
                        </td>
                        <td>
                            Subject must directly face the camera.Head must not be tilted up, down, to the side, or toward the shoulders. Head must be centered within frame.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Background
                        </td>
                        <td>
                            Subject must be surrounded by a plain, light-colored background with no distracting shadows on the subject or background.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Focus
                        </td>
                        <td>
                            The entire face must be in focus and not overly sharpened.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Brightness/Contrast	
                        </td>
                        <td>
                            Brightness and contrast should represent subject accurately.
                        </td>
                    </tr>
                    <tr>
                        <td>Color</td>
                        <td>The image must be in color (24 bits per pixel).Black and white photos are not acceptable. Color should reproduce natural skin tones. Color must be continuous tone no posterization.</td>
                    </tr>
                    <tr>
                        <td>
                            Exposure/Lighting	
                        </td>
                        <td>
                            Photo may not be over- or under-exposed. Avoid shadows on the face or background.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Resolution
                        </td>
                        <td>
                            Fine facial features should be discernible. No discernible pixels/pixelization, graininess, or dot patterns.
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Compression
                        </td>
                        <td>
                            The image must not be overly compressed (the compression ratio used should be less than or equal to 20:1).
                        </td>
                    </tr>
                    
                    <tr>
                        <td>
                            Decorative Items	
                        </td>
                        <td>
                            No sunglasses or other items that obscure the face. Hats or head coverings are only allowed if worn for religious reasons AND if they do not obscure any facial features.

                        </td>
                    </tr>
                    <tr>
                        <td>
                            Eyeglasses
                        </td>
                        <td>
                            Eyeglasses are no longer allowed in  Passport photo.
                        </td>
                    </tr>
                </tbody>
               </table> 
                
            </div>
            <div class="grid-boxs">
                <h3>
                    Photo Examples
                </h3>
                <p class="w100 mb10">Following are the few sample photographs for reference. Kindly upload/capture pictures keeping them in mind.</p>
                <!-- <ul class="guidelines_list">
                    <li>
                        Upload your recent colored photograph (not older than 6 months).
                    </li>
                    <li>
                        The image should be clear without any filters.
                    </li>    
                    <li>
                        Add only photograph clicked by someone else. No selfies allowed.
                    </li>
                    <li>
                        Take out any apparel which can hide the face (example - sunglasses, mask,cap or scarf, if worn).
                    </li>
                    <li>
                        Only white or off-white background is allowed and it should be without shadows, texture, or lines.  
                    </li>
        
                   
                </ul> -->
                
                <div class="tabs_vertical w100">
                    
                    <div class="wrapper">

                        <input class="radio_guide" id="one" name="group" type="radio" checked>
                        <input class="radio_guide" id="two" name="group" type="radio">
                        <input class="radio_guide" id="three" name="group" type="radio">
                        <input class="radio_guide" id="four" name="group" type="radio">
                        <input class="radio_guide" id="five" name="group" type="radio">
                        <input class="radio_guide" id="six" name="group" type="radio">
                        <input class="radio_guide" id="seven" name="group" type="radio">
                        <input class="radio_guide" id="eight" name="group" type="radio">
                        <input class="radio_guide" id="nine" name="group" type="radio">
                        <input class="radio_guide" id="ten" name="group" type="radio">
                        
                        <div class="tabs">
                            <label class="tab" id="one-tab" for="one">Shadows and Lighting</label>
                            <label class="tab" id="two-tab" for="two">Glasses</label>
                            <label class="tab" id="three-tab" for="three">Size and Position</label>
                            <label class="tab" id="four-tab" for="four">Resolution, Print size and Quality</label>
                            <label class="tab" id="five-tab" for="five">Digital Alterations and Retouching</label>
                            <label class="tab" id="six-tab" for="six">Pose and Expression</label>
                            <label class="tab" id="seven-tab" for="seven">Attire, Hats, and Hair</label>
                            <label class="tab" id="eight-tab" for="eight">Background</label>
                            <label class="tab" id="nine-tab" for="nine">Children</label>
                            <label class="tab" id="ten-tab" for="ten">Spacing Around the Picture</label>
                        </div>

                        <div class="panels">
                                <div class="panel" id="one-panel">
                                    <img src="assets/images/1.png" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable — Quality Photo</div>
                                    <p>
                                        Photo is clear and in color, reproduces skin tones accurately, and is properly exposed with no shadows
                                    </p>
                                </div>
                                <div class="panel" id="two-panel">
                                    <img src="assets/images/9.png" alt="image">
                                    <span class="tick_mark">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                    <div class="panel-title">Unacceptable— Glasses not allowed</div>
                                
                                <p> 
                                    Photo tip: Even when glasses are worn every day, you must remove your glasses for a photo.
                                </p>
                                </div>
                                <div class="panel" id="three-panel">
                                    <img src="assets/images/8.png" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">
                                        Acceptable— The head is centered and the correct size.
                                    </div>
                                    <p>Photo tip: If you are applying in person or by mail, your photo should be 2”x 2” (51 x 51 mm) with the head 
                                        centered and sized between 1” and 1.4” (25 and 35 mm). Your digital photo must be in 
                                        at least 600 by 600 pixels, and at least 54 kilobytes in size.
                                    </p>
                                </div>
                                <div class="panel" id="four-panel">
                                    <img src="assets/images/2.jpg" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable </div>
                                    <p>
                                        Photo is sharp without any visible pixels or printer dots. 
                                    </p>
                                </div>
                                <div class="panel" id="five-panel">
                                    <img src="assets/images/7.png" alt="image">
                                    <span class="tick_mark">
                                        <i class="fa fa-times" aria-hidden="true"></i>
                                    </span>
                                    <div class="panel-title">Unacceptable- Cropped Photo </div>
                                    <p>
                                        The Background is cropped out using a photo retouching tool, altering the outline of the head, face, and neck.
                                    </p>
                                    <p>
                                        Photo tip: Take your photo in front of a white or off-white background instead of cropping out the background.
                                    </p>
                                </div>
                                <div class="panel" id="six-panel">
                                    <img src="assets/images/10.jpg" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable- Expression </div>
                                    <p>
                                      Applicant is directly facing camera with a neutral expression. Eyes are clearly visible.
                                    </p>
                                </div>
                                <div class="panel" id="seven-panel">
                                    <img src="assets/images/6.png" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable </div>
                                    <p>
                                        Subject's full face is visible, no shadows or clothing obscure the face
                                    </p>
                                    <p>
                                        Photo tip: You may not wear hats or head coverings, except for religious or medical purposes.
                                    </p>
                                </div>
                                <div class="panel" id="eight-panel">
                                    <img src="assets/images/5.jpeg" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable- White Background  </div>
                                    <p>
                                        Background is white or off-white, without shadows, and is plain without texture, objects, or lines
                                    </p>
                                </div>
                                <div class="panel" id="nine-panel">
                                    <img src="assets/images/4.png" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable- Children  </div>
                                    <p>
                                        No other person is in the photo, and the child is facing the camera with eyes open.
                                    </p>
                                    <p>Photo tip: It is acceptable if your infant's eyes are not entirely open.</p>
                                </div>
                                <div class="panel" id="ten-panel">
                                    <img src="assets/images/3.png" alt="image">
                                    <span class="tick_mark"> <i class="fa fa-check" aria-hidden="true"></i></span>
                                    <div class="panel-title">Acceptable  </div>
                                    <p>
                                        Online Application— The customer is directly facing the camera, has a neutral expression, and there is ample space around the customer's head.
                                    </p>
                                    
                                </div>
                           

                            

                        </div>
                        
                    </div>

                </div>

            </div>
           
        </div>
       
    </div>
    
</div>
