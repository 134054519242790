import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationAddEditComponent } from './application-add-edit/application-add-edit.component';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { CODRequestComponent } from './codrequest/codrequest.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentComponent } from './payment/payment.component';
import { SearchApplicationComponent } from './search-application/search-application.component';
import { Route } from './common/route';
import { NinVerificationComponent } from './nin-verification/nin-verification.component';
import { DocumentReuploadComponent } from './document-reupload/document-reupload.component';
import { ImageTestComponent } from './image-test/image-test.component';
import { CODDataComparisonComponent } from './coddata-comparison/coddata-comparison.component';
import { ContactlessResponseComponent } from './contactless-reponse/contactless-response.component';
import { ContactlessPaymentComponent } from './contactless-payment/contactless-payment.component';

const route = new Route();

const routes: Routes = [

  { path: '', component: HomeComponent, pathMatch: 'full' },

  { path: route.APPLICATION_ADD_EDIT + '/:applicantId', component: ApplicationAddEditComponent, pathMatch: 'full' },

  { path: route.APPLICATION_VIEW + '/:applicantId', component: ApplicationViewComponent, pathMatch: 'full' },

  { path: route.APPLICATION_VIEW + '/:applicantId/:requestResource', component: ApplicationViewComponent, pathMatch: 'full' },

  { path: route.APPLICATION_REUPLOAD + '/:token', component: DocumentReuploadComponent, pathMatch: 'full' },

  { path: route.APPLICATION_SEARCH + '/:searchType', component: SearchApplicationComponent, pathMatch: 'full' },

  { path: route.APPLICATION_PAYMENT + '/:applicantId/:paymentType', component: PaymentComponent, pathMatch: 'full' },

  { path: route.PAYMENT_RECEIPT + '/:txnReferenceNumber', component: PaymentReceiptComponent, pathMatch: 'full' },

  { path: route.PAYMENT_SUCCESS + '/:txnRef', component: PaymentSuccessComponent, pathMatch: 'full' },

  { path: route.PAYMENT_FAILURE + '/:txnRef/:referenceNumber/:applicationId', component: PaymentFailureComponent, pathMatch: 'full' },

  { path: route.COD_REQUEST + '/:masterId/:applyingFor', component: CODRequestComponent, pathMatch: 'full' },

  { path: route.NIN_VERIFICATION + '/:applyingFor', component: NinVerificationComponent, pathMatch: 'full' },

  { path: route.COD_DATA_COMPARISON + '/:dataCorrectionId', component: CODDataComparisonComponent, pathMatch: 'full' },

  { path: route.IMAGE_TEST, component: ImageTestComponent, pathMatch: 'full' },

  { path: route.CONTACTLESS, component: ContactlessResponseComponent, pathMatch: 'full' },

  { path: route.CONTACTLESS_PAYMENT + '/:applicationId/:referenceNumber/:status', component: ContactlessPaymentComponent, pathMatch: 'full' },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }