import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { Toast } from '../common/toast';
import { LocalStorage } from '../common/local-storage';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-contactless-payment',
  templateUrl: './contactless-payment.component.html',
  styleUrls: ['./contactless-payment.component.css']
})
export class ContactlessPaymentComponent implements OnInit {
  applicationId: string | null = null;
  referenceNumber: string | null = null;
  status: number | null = null;
  private loggedUserId : string = this.constants.BLANK;

  private encryptedApplicantId: string = this.constants.BLANK;
  private applicationViewForm : HTMLFormElement;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpUtils: HttpUtils,
    public constants: Constants,
    private loaderService: LoaderService,
    public utils: Utils,
    public gui: GUI,
    private toast: Toast,
    private localStorage: LocalStorage
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.applicationId = params['applicationId'] || null;
      this.referenceNumber = params['referenceNumber'] || null;
      this.status = params['status'] || null;
      this.loggedUserId = this.localStorage.getLoggedUserId();
    });
  }
  ngOnInit(): void {
    this.checkApplicationFromEsb();
    this.applicationViewForm = document.forms['applicationViewForm'];
    this.startPayment();
  }

  private checkApplicationFromEsb(): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationFromEsb(this.applicationId, this.referenceNumber, this.status).then(serverResponse => {
      if (serverResponse) {
        // console.log("checkApplicationFromEsb(): ", serverResponse);
        //console.log("ESB Response: ", serverResponse);
        localStorage.setItem("applicationId", serverResponse?.aId);
        localStorage.setItem("referenceId", serverResponse?.refId);
        localStorage.setItem("contactlessStatus", serverResponse?.contactlessStatus);
        this.loggedUserId = serverResponse.userId;
        localStorage.setItem("USD_PASSPORT_LOGGED_USER_ID", this.utils.encryptString(this.loggedUserId));
        // this.initiatePayment();

        // this.gui.openApplicationViewV2(serverResponse.applicantId); 
        //this.utils.decryptString(serverResponse.applicantId));

        // this.gui.openPaymentPage(serverResponse.applicantId, this.constants.APPLICATION_PAYMENT);
      }

    })

    this.loaderService.stopLoader();
  }



private startPayment(): void {
  const transactionId = new Date().toISOString().replace(/[-T:.Z]/g, ''); // Similar to PHP's date('YmdHims')

  // Generate the hash

  
  const amount = 6000;


  const data = `${environment.raveMerchant}${amount.toFixed(2)}${transactionId}`;
  const hash = CryptoJS.HmacSHA1(data, environment.raveKey).toString();

  // Prepare payload for the request
  const payload = {
    serviceId: environment.raveService,
    txnRefNumber: transactionId,
    amount: amount.toFixed(2),
    countryId: 'US',
    hash: hash,
    extraInformation: "20241024090003KWS8U7G"
  };


  console.log("main payload", payload)
  try {
    // Call startRavePayment function from HttpUtils
    this.httpUtils.startRavePayment(payload).then(serverResponse => {
      if (serverResponse && serverResponse.redirect_url) {
        console.log("Response:", serverResponse);
        // window.location.href = serverResponse.redirect_url;
      } else {
        console.error("No redirect URL in response", serverResponse);
      }
    }).catch(error => {
      console.error("Error while starting payment:", error);
    });
  } catch (error) {
    console.error("Unexpected error:", error);
  }
}




  private initiatePayment(){
    const applicationsList = [JSON.parse(localStorage.getItem("applicationId"))];
    this.httpUtils.initiatePayment(this.localStorage.getLoggedUserId(), applicationsList).then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.openIOSPaymentPage();
      } else {
        this.loaderService.stopLoader();
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private openIOSPaymentPage(){
    this.applicationViewForm.action = environment.serverBaseUrl + 'v1/payments/openIOSPaymentPage';
    this.applicationViewForm.loggedUserId.value = this.loggedUserId;
    this.applicationViewForm.submit();
  }

}
