import { Component } from '@angular/core';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';
import { ActivatedRoute, Params } from '@angular/router';
import { HttpUtils } from '../common/http-utils';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { LoaderService } from '../common/loader-service';
import { SimpleModalService } from 'ngx-simple-modal';
import { FileUploadModalComponent } from '../file-upload-modal/file-upload-modal.component';
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { GUI } from '../common/gui';
import { ImageUploadModalComponent } from '../image-upload-modal/image-upload-modal.component';
import { DocumentsUploadHistoryModalComponent } from '../documents-upload-history-modal/documents-upload-history-modal.component';

@Component({
  selector: 'app-document-reupload-component',
  templateUrl: './document-reupload.component.html',
  styleUrls: ['./document-reupload.component.css']
})
export class DocumentReuploadComponent {

  private emailLinkToken: string = this.constants.BLANK;

  private applicantId: string = this.constants.BLANK;

  uploadPhotoFormObject: any = null;

  applicantDetailsObject: any = {};
  
  applicableDocsList: any[] = [];

  maximumDocQueryAllowed: number = this.constants.FALSE;

  allowImagesList = this.constants.ALLOW_IMAGE_LIST;
  
  imageValidated: boolean = false;

  isPageLoaded: boolean = false;

  isTermConditionSelect: boolean = false;

  photoUploaded: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils, public constants: Constants, private loaderService: LoaderService, 
              private simpleModalService: SimpleModalService, private gui: GUI) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.emailLinkToken = params['token'];
    });
    this.validateTokenAndFetchQueriedDocs()
  }

  private validateTokenAndFetchQueriedDocs(): void {
    this.loaderService.startLoader();
    this.emailLinkToken = encodeURIComponent(this.emailLinkToken);
    this.httpUtils.validateQueriedLink(this.emailLinkToken).then(serverResponse => {
      this.loaderService.stopLoader();
      if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
        this.maximumDocQueryAllowed = serverResponse.maximumDocQueryAllowed;
        this.applicantDetailsObject = serverResponse.details;
        this.applicantId = this.applicantDetailsObject.applicantId;
        this.uploadPhotoFormObject = serverResponse.applicantPhotoDoc;
        this.applicableDocsList = serverResponse.queriedDocsList;
        this.isPageLoaded = true;
      } else {
        this.toast.showErrorToastWithCallbackFunction(serverResponse.responseMsg, this.openDashboard.bind(null, this.gui));
      }
    });
  }

  private openDashboard(gui: GUI): void {
    gui.openHomePage();
  }

  openImageUploadModal(formObject: {}, fileDataField: string, fileTypeField: string) : void {
    const requestObject = {};
    requestObject['isFixedCropper'] = this.constants.TRUE;
    requestObject['allowFileTypeList'] = this.allowImagesList;
    requestObject['applicantId'] = this.applicantId;
    this.simpleModalService.addModal(ImageUploadModalComponent, {requestObject: requestObject}, {autoFocus: true}).subscribe((responseObject: any = {})=> {
      if(Object.keys(responseObject).length > 0){
        formObject[fileDataField] = responseObject.fileBase64Data;
        formObject[fileTypeField] = responseObject.fileType;
        this.imageValidated = this.utils.isEmpty(responseObject.fileBase64Data) === false;
        this.photoUploaded = true;
      }
    });
  }

  openFileUploadModal(applicableDocObject: any = {}) : void {
    this.simpleModalService.addModal(FileUploadModalComponent, {applicableDocObject}, {autoFocus: true}).subscribe((responseObject: any = {})=> {
      if(Object.keys(responseObject).length > 0 && this.utils.isEmpty(responseObject.base64Data) === false){
        applicableDocObject.docSubType = responseObject.docSubType;
        applicableDocObject.docSubTypeLabel = responseObject.docSubTypeLabel;
        applicableDocObject.base64Data = responseObject.base64Data;
        applicableDocObject.isPDFDoc = responseObject.isPDFDoc;
        applicableDocObject.isDocReuploaded = true;
      }
    });
  }

  openDocHistoryModal(docObject: any) : void {
    const requestObject = {};
    requestObject['docType'] = docObject.docType;
    requestObject['docTypeLabel'] = docObject.docTypeLabel;
    requestObject['applicantId'] = this.applicantId;
    this.simpleModalService.addModal(DocumentsUploadHistoryModalComponent, {requestObject: requestObject}, {autoFocus: true});
  }

  openFilePreviewModal(applicableDocObject: any = {}) : void {
    this.simpleModalService.addModal(FilePreviewModalComponent, {applicableDocObject}, {autoFocus: true,closeOnEscape: true}).subscribe();
  }
  
  deleteUploadedFile(applicableDocObject: any = {}): void {
    this.loaderService.startLoader();
    applicableDocObject.base64Data = this.constants.BLANK;
    applicableDocObject.isPDFDoc = false;
    applicableDocObject.isDocReuploaded = false;
    this.loaderService.stopLoader();
  }

  saveReuploadedDocs(): void {
    this.loaderService.startLoader()
    const documents = this.fetchUploadedDocs();
    const requestObject = {applicantId: this.applicantId, token: this.emailLinkToken, documents: documents};
    this.httpUtils.saveReuploadedDocs(requestObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.openInformationModal();
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg)
      }
    });
  }

  private fetchUploadedDocs(): any[] {
    const documents = [];

    for(let sectionObject of this.applicableDocsList) {
      for(let applicableDocObject of sectionObject.groupDocs) {
        documents.push({applicantDocId: applicableDocObject.applicantDocId, docSubType: applicableDocObject.docSubType, base64Data: applicableDocObject.base64Data});
      } 
    }

    if(this.uploadPhotoFormObject !== null){
      documents.push({applicantDocId: this.uploadPhotoFormObject.applicantDocId, docSubType: this.uploadPhotoFormObject.docSubType, base64Data: this.uploadPhotoFormObject.validatedBase64Data});
    }

    return documents;
  }

  private openInformationModal(): void {
    const message = 'Your updated documents have been successfully uploaded. We will review them and notify you via email shortly.';
    this.simpleModalService.addModal(InformationModalComponent, {message: message, isShowCheckBox: false, checkBoxLabel: this.constants.BLANK}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      this.gui.openHomePage();
    });
  }

}
