import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Route } from '../common/route';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-application-view-v1',
  templateUrl: './application-view-v1.component.html',
  styleUrls: ['./application-view-v1.component.css']
})
export class ApplicationViewV1Component {

  private encryptedApplicationId : string = this.constants.BLANK;

  private encryptedReferenceId : string = this.constants.BLANK;

  private encryptedIsSearchFromHome : string = this.constants.BLANK;

  isSearchFromHome : number = this.constants.FALSE;

  applicationDetailsObject : any = {};

  isShowPaymentDetails : boolean = false;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private route: Route, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants, private loaderService: LoaderService, public utils: Utils, private gui: GUI) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicationId = params['applicationId'];
      this.encryptedReferenceId = params['referenceId'];
      this.encryptedIsSearchFromHome = params["isSearchFromHome"];
    });

    if(this.utils.isEmpty(this.encryptedIsSearchFromHome) === false){
      this.isSearchFromHome = Number(this.utils.decryptString(this.encryptedIsSearchFromHome));
    }

    this.storeApplicationViewV1Data();
  }

  private storeApplicationViewV1Data(){
    this.loaderService.startLoader();
    const applicationId = this.utils.decryptString(this.encryptedApplicationId);
    const referenceId = this.utils.decryptString(this.encryptedReferenceId);
    this.httpUtils.fetchV1ApplicationData(applicationId, referenceId).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.applicationDetailsObject = serverResponse.data;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToPage() {
    window.scrollTo(0,0);

    if(this.isSearchFromHome === this.constants.TRUE) {
      this.gui.openHomePage();
      return;
    }

    this.gui.openDashboardPage();
  }

  printApplication() {
    window.print();
  }

  printReceipt(): void {
    const applicationId = this.applicationDetailsObject.applicationId;
    const referenceId = this.applicationDetailsObject.referenceId;
    const appRefId = applicationId + '|' + referenceId;
    const masterEncrypedApplicantId = this.utils.encryptString(appRefId);
    window.open(this.route.PAYMENT_RECEIPT + '/' + encodeURIComponent(masterEncrypedApplicantId), "_blank");
  }

}
