export const environment = {

  production: false,

  buildDate: '30/07/2024',

  authorizationKey: 'GoyBKWuIr8aqKKThRjA5',

  // serverBaseUrl: 'http://192.168.1.3:8080/usPassportBackend/',

  serverBaseUrl: 'http://localhost:8080/app/',
  // serverBaseUrl: 'https://staging.newworkssolution.com:8443/usPassportBackend/',
  // serverBaseUrl: 'https://passportintl.immigration.gov.ng/app/',

  esbUrl: 'https://esb.swglobalstaging.com/esb/api/',



  appointmentApplicationUrl: 'https://apt-stage.newworkssolution.com/',

  checkCentreAvailabilityUrl: 'https://apt-stage.newworkssolution.com/availability/',

  trackApplicationUrl: 'https://track.immigration.gov.ng',

  passportApplicationRequirementsUrl: 'https://immigration.gov.ng/learn-more/',

  ngnPassportApplicationUrl: 'https://staging.newworkssolution.com/',

  keycloakUrl: 'https://auth.swglobalstaging.com/auth/',

  keycloakRealm: 'immigration',

  keycloakClientId: 'immigration_auth',

  keycloakRedirectUrl: 'http://localhost:4200/login/',

  keycloakLogoutRedirectUrl: 'http://localhost:4200/',

  domain: 'localhost:4200',

  path: '/',

  enablePassportDeliveryStage: false,

  passportDeliveryTermsLink: 'https://bit.ly/gwx-terms-and-conditions',

  raveMerchant: 'OIS_APPOINTMENT_US',

  raveService:'OIS_APPOINTMENT_US',

  raveKey: 'N85wt6n8&JFNq8Gl',
  
  raveUrl: 'http://rave.swglobalstaging.com'






};
