import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule, PB_DIRECTION, SPINNER } from "ngx-ui-loader";
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { HttpClient, HttpClientModule, HttpXhrBackend } from '@angular/common/http';
import { SimpleModalModule } from 'ngx-simple-modal';
import { LoaderService } from './common/loader-service';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { ApplicationAddEditComponent } from './application-add-edit/application-add-edit.component';
import { DateOfBirthDirective } from './validators/date-of-birth.directive';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { NumberOnlyDirective } from './validators/number-only-directive';
import { FirstFocusDirective } from './validators/first-focus-directive';
import { ErrorFocusDirective } from './validators/error-focus-directive';
import { HintDirective } from './validators/hint-directive';
import { NameDirective } from './validators/name-directive';
import { NameWithNumberDirective } from './validators/name-with-number-directive';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { AddressDirective } from './validators/address-directive';
import { NgxDialogsModule } from 'ngx-dialogs';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ApplicationSearchComponent } from './application-search/application-search.component';
import { Constants } from './common/constants';
import { IdentifyValueChangeDirective } from './validators/identify-value-change-directive';
import { ResumeApplicationComponent } from './resume-application/resume-application.component';
import { ApplicationViewV1Component } from './application-view-v1/application-view-v1.component';
import { APP_INITIALIZER } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { environment } from 'src/environments/environment';
import { Tags } from './common/tags';
import { LogoutComponent } from './logout/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { PaymentFailureV1Component } from './payment-failure-v1/payment-failure-v1.component';
import { PaymentSuccessV1Component } from './payment-success-v1/payment-success-v1.component';
import { DisallowSpacesDirective } from './validators/disallow-spaces-directive';
import { InvalidAccessComponent } from './invalid-access/invalid-access.component';
import { AlphaNumericDirective } from './validators/alpha-numeric-directive';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { GuarantorFormComponent } from './guarantor-form/guarantor-form.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { HeaderNoSessionComponent } from './header-no-session/header-no-session.component';
import { NinVerificationComponent } from './nin-verification/nin-verification.component';
import { PhotoGuidelinesModalComponent } from './photo-guidelines-modal/photo-guidelines-modal.component';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { ImageUploadModalComponent } from './image-upload-modal/image-upload-modal.component';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HomeNewComponent } from './home-new/home-new.component';
import { NgxSuperCroppieModule } from 'ngx-super-croppie';
import { FooterNoSessionComponent } from './footer-no-session/footer-no-session.component';
import { ApplicationHelpComponent } from './application-help/application-help.component';
import { PaymentHelpComponent } from './payment-help/payment-help.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { CODRequestComponent } from './codrequest/codrequest.component';
import { CODDataComparisonComponent } from './coddata-comparison/coddata-comparison.component';
import { InformationModalComponent } from './information-modal/information-modal.component';
import { DocumentReuploadComponent } from './document-reupload/document-reupload.component';
import { DocumentsUploadHistoryModalComponent } from './documents-upload-history-modal/documents-upload-history-modal.component';
import { MatMenuModule } from '@angular/material/menu';
import { CodRequestModelComponent } from './cod-request-model/cod-request-model.component';
import { HomeNewV2Component } from './home-new-v2/home-new-v2.component';
import { ImageTestComponent } from './image-test/image-test.component';
import { CaptchaComponent } from './captcha/captcha.component';
import { LoginComponent } from './login/login.component';
import { NoticeModalComponent } from './notice-modal/notice-modal.component';
import { ContactlessResponseComponent } from './contactless-reponse/contactless-response.component';
import { ContactlessPaymentComponent } from './contactless-payment/contactless-payment.component';
import { NewContactlessResponseComponent } from './new-contactless-response/new-contactless-response.component';
import { RavePaymentComponent } from './rave-payment/rave-payment.component';

const constants = new Constants();
const tags = new Tags();

const ngxUiLoaderDefaultConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.ballSpinClockwise,
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 5,
  pbColor: "green",
  blur: 10,
  fgsColor: "green",
  masterLoaderId: constants.DEFAULT_LOADER_ID,
  text: 'Please Wait.....',
  textColor: 'green'
};

const defaultTooltipOptions: TooltipOptions = {
  'show-delay': 500
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// function initializeKeycloak(keycloak: KeycloakService) {
//   return () =>
//   keycloak.init({
//     config: {
//       url: environment.keycloakUrl,
//       realm: environment.keycloakRealm,
//       clientId: environment.keycloakClientId
//     },
//     initOptions: {
//       onLoad: 'check-sso',
//       silentCheckSsoRedirectUri: environment.keycloakLogoutRedirectUrl + 'assets/silent-check-sso.html',
//       checkLoginIframe: false,
//       redirectUri: environment.keycloakRedirectUrl,
//       token: Cookie.get(tags.KEYCLOAK_TOKEN),
//       refreshToken: Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN)
//     }
//   });
// }



// async function initializeKeycloak(keycloak: KeycloakService) {
//   return async () => {
//     try {
//       const keycloakObject = await keycloak.init({
//         config: {
//           url: environment.keycloakUrl,
//           realm: environment.keycloakRealm,
//           clientId: environment.keycloakClientId,
//         },
//         initOptions: {
//           onLoad: 'check-sso',
//           silentCheckSsoRedirectUri: `${environment.keycloakRedirectUrl}assets/silent-check-sso.html`,
//           checkLoginIframe: false,
//           redirectUri: environment.keycloakRedirectUrl,
//           token: Cookie.get(tags.KEYCLOAK_TOKEN) || undefined,
//           refreshToken: Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN) || undefined,
//         },
//       });

//       const isKeycloakRunning = await checkKeycloakIsRunning();
//       return isKeycloakRunning ? keycloakObject : null;
//     } catch (error) {
//       console.error('Keycloak initialization error:', error);
//       return null;
//     }
//   };
// }

// function initializeKeycloak(keycloak: KeycloakService) {
//   console.log("url", environment.keycloakUrl)
//   console.log("realm", environment.keycloakRealm)
//   console.log("client", environment.keycloakClientId)
//   return () => keycloak.init({
//     config: {
//       url: environment.keycloakUrl,
//       realm: environment.keycloakRealm,
//       clientId: environment.keycloakClientId
//     },
//     initOptions: {
//       onLoad: 'check-sso',
//       silentCheckSsoRedirectUri: `${environment.keycloakRedirectUrl}assets/silent-check-sso.html`,
//       checkLoginIframe: false,
//       redirectUri: environment.keycloakRedirectUrl,
//       token: Cookie.get(tags.KEYCLOAK_TOKEN) || undefined,
//       refreshToken: Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN) || undefined,
//     }
//   });
// }

// function initializeKeycloak(keycloak: KeycloakService) {
//   return async () => {
//     try {
//       // Initialize Keycloak
//       const keycloakObject = await keycloak.init({
//         config: {
//           url: environment.keycloakUrl,
//           realm: environment.keycloakRealm,
//           clientId: environment.keycloakClientId
//         },
//         initOptions: {
//           onLoad: 'check-sso',
//           silentCheckSsoRedirectUri: `${environment.keycloakLogoutRedirectUrl}assets/silent-check-sso.html`,
//           checkLoginIframe: false,
//           redirectUri: environment.keycloakRedirectUrl,
//           token: Cookie.get(tags.KEYCLOAK_TOKEN) || undefined,
//           refreshToken: Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN) || undefined
//         }
//       });

//       // Check if Keycloak is running
//       const isKeycloakRunning = await checkKeycloakIsRunning();

//       // Return the Keycloak object if running, otherwise return null
//       return isKeycloakRunning ? keycloakObject : null;

//     } catch (error) {
//       console.error('Keycloak initialization error:', error);
//       return null;
//     }
//   };
// }

// function initializeKeycloak(keycloak: KeycloakService) {



//   return async () => {
//     try {
//       const keycloakObject = await keycloak.init({
//         config: {
//           url: environment.keycloakUrl,
//           realm: environment.keycloakRealm,
//           clientId: environment.keycloakClientId
//         },
//         initOptions: {
//           onLoad: 'check-sso',  // Use 'login-required' if you want to force a login
//           silentCheckSsoRedirectUri: environment.keycloakLogoutRedirectUrl + 'assets/silent-check-sso.html',
//           // checkLoginIframe: false,
//           redirectUri: environment.keycloakRedirectUrl,
//           // Use tags.KEYCLOAK_TOKEN and tags.KEYCLOAK_REFRESH_TOKEN directly if cookies are not working
//           token: tags.KEYCLOAK_TOKEN,
//           refreshToken: tags.KEYCLOAK_REFRESH_TOKEN
//         }
//       });

//       const isKeycloakRunning = await checkKeycloakIsRunning();

//       return isKeycloakRunning ? keycloakObject : null;

//     } catch (error) {
//       console.error('Keycloak initialization error:', error);
//       return null;
//     }
//   };
// }

// function initializeKeycloak(keycloak: KeycloakService) {
//   return () => {
//     const token = Cookie.get(tags.KEYCLOAK_TOKEN) || tags.KEYCLOAK_TOKEN;
//     const refreshToken = Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN) || tags.KEYCLOAK_REFRESH_TOKEN;

//     console.log('Token Used:', token); // Log the token being used
//     console.log('Refresh Token Used:', refreshToken);

//     // Ensure token and refresh token are only passed if they are valid
//     return keycloak.init({
//       config: {
//         url: environment.keycloakUrl,
//         realm: environment.keycloakRealm,
//         clientId: environment.keycloakClientId
//       },
//       initOptions: {
//         onLoad: 'check-sso',
//         silentCheckSsoRedirectUri: environment.keycloakLogoutRedirectUrl + 'assets/silent-check-sso.html',
//         // checkLoginIframe: false,
//         redirectUri: environment.keycloakRedirectUrl,
//         // token: token || undefined,
//         // refreshToken: refreshToken || undefined
//       }
//     });
//   };
// }

function initializeKeycloak(keycloak: KeycloakService) {

  // console.log("Cookie Key for Token:", tags.KEYCLOAK_TOKEN); // Check the key name
  // console.log("Cookie Key for Refresh Token:", tags.KEYCLOAK_REFRESH_TOKEN);
  // console.log("Token from Cookie:", Cookie.get(tags.KEYCLOAK_TOKEN)); // Check if the cookie is accessible
  // console.log("Refresh Token from Cookie:", Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN));
  // console.log("All Tags Token Value:", tags?.KEYCLOAK_TOKEN); // Check the token directly from tags

  return async () => {
    try {
      const keycloakObject = await keycloak.init({
        config: {
          url: environment.keycloakUrl,
          realm: environment.keycloakRealm,
          clientId: environment.keycloakClientId
        },
        initOptions: {
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: environment.keycloakLogoutRedirectUrl + 'assets/silent-check-sso.html',
          checkLoginIframe: false,
          redirectUri: environment.keycloakRedirectUrl,
          // Use tags.KEYCLOAK_TOKEN and tags.KEYCLOAK_REFRESH_TOKEN directly if cookies are not working
          token: Cookie.get(tags.KEYCLOAK_TOKEN),
          refreshToken: Cookie.get(tags.KEYCLOAK_REFRESH_TOKEN)
        }
      });

      const isKeycloakRunning = await checkKeycloakIsRunning();

      return isKeycloakRunning ? keycloakObject : null;

    } catch (error) {
      console.error('Keycloak initialization error:', error);
      return null;
    }
  };
}

async function checkKeycloakIsRunning() {
  let result = false;

  const httpClient = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));
  const callingURL = environment.keycloakUrl + 'realms/' + environment.keycloakRealm;

  await httpClient.get(callingURL).toPromise().then(response => {
    console.log('Keycloak server is up and running');
    result = true;
  }).catch(error => {
    console.error('Keycloak server is down and getting error as: ', error);
  });

  return result;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    HeaderComponent,
    FooterComponent,
    ConfirmationModalComponent,
    ApplicationAddEditComponent,
    DateOfBirthDirective,
    NumberOnlyDirective,
    ApplicationViewComponent,
    PaymentFailureComponent,
    PaymentSuccessComponent,
    FirstFocusDirective,
    ErrorFocusDirective,
    HintDirective,
    NameDirective,
    NameWithNumberDirective,
    AddressDirective,
    ApplicationSearchComponent,
    IdentifyValueChangeDirective,
    ResumeApplicationComponent,
    ApplicationViewV1Component,
    LogoutComponent,
    DashboardComponent,
    SearchModalComponent,
    PaymentFailureV1Component,
    PaymentSuccessV1Component,
    DisallowSpacesDirective,
    InvalidAccessComponent,
    AlphaNumericDirective,
    PaymentReceiptComponent,
    GuarantorFormComponent,
    HeaderNoSessionComponent,
    NinVerificationComponent,
    PhotoGuidelinesModalComponent,
    FileUploadModalComponent,
    ImageUploadModalComponent,
    FilePreviewModalComponent,
    HomeNewComponent,
    FooterNoSessionComponent,
    ApplicationHelpComponent,
    PaymentHelpComponent,
    CODRequestComponent,
    CODDataComparisonComponent,
    InformationModalComponent,
    DocumentReuploadComponent,
    DocumentsUploadHistoryModalComponent,
    CodRequestModelComponent,
    HomeNewV2Component,
    ImageTestComponent,
    CaptchaComponent,
    LoginComponent,
    NoticeModalComponent,
    ContactlessResponseComponent,
    ContactlessPaymentComponent,
    NewContactlessResponseComponent,
    RavePaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderDefaultConfig),
    NgxUiLoaderRouterModule,
    TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions),
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    SimpleModalModule,
    NgxBarcodeModule,
    Ng2TelInputModule,
    NgxDialogsModule,
    NgxCaptchaModule,
    KeycloakAngularModule,
    PDFExportModule,
    PdfViewerModule,
    NgxSuperCroppieModule,
    MatExpansionModule,
    PerfectScrollbarModule,
    MatMenuModule
  ],
  providers: [
    LoaderService,
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

}
