import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constants } from './constants';
import { Toast } from './toast';
import { Router } from '@angular/router';
import { Tags } from './tags';
import { LocalStorage } from './local-storage';
import { environment } from 'src/environments/environment';
import { Route } from './route';
import { LoaderService } from './loader-service';
import { SimpleModalService } from 'ngx-simple-modal';

@Injectable({
    providedIn: 'root'
})
export class HttpUtils {

    constructor(private router: Router, private route: Route, private ngZone: NgZone, private httpClient: HttpClient, private constants: Constants, private toast: Toast, private tags: Tags, private localStorage: LocalStorage, private loaderService: LoaderService, private simpleModalService: SimpleModalService) {

    }

    private fetchRequestHeaders() {
        let headers = new HttpHeaders();
        headers = headers.append(this.tags.APPLICATION_TOKEN, this.localStorage.getStorageKey(this.tags.APPLICATION_TOKEN));
        return headers;
    }

    private async sendPostRequest(url: string, requestBody: {}) {
        url = environment.serverBaseUrl + url;

        const headers = this.fetchRequestHeaders();

        return this.httpClient.post(url, requestBody, { headers }).toPromise()
            .then(response => {
                if (response[this.tags.RESPONSE_CODE] === this.constants.STATUS_AUTH_TOKEN_NOT_VALID) {
                    this.loaderService.stopLoader();
                    this.simpleModalService.removeAll();
                    this.toast.showErrorToast(response[this.tags.RESPONSE_MESSAGE]);
                    this.ngZone.run(() => this.router.navigate([this.route.HOME]));
                    return;
                }
                return response;
            }).catch(error => {
                console.log('Server error come', error);
                return this.fetchErrorObject(error.message);
            });
    }

    // private async sendPostRequest2(url: string, requestBody: {}) {
    //     url = environment.esbUrl + url;

    //     const headers = this.fetchRequestHeaders();

    //     return this.httpClient.post(url, requestBody, { headers }).toPromise()
    //         .then(response => {
    //             if (response[this.tags.RESPONSE_CODE] === this.constants.STATUS_AUTH_TOKEN_NOT_VALID) {
    //                 this.loaderService.stopLoader();
    //                 this.simpleModalService.removeAll();
    //                 this.toast.showErrorToast(response[this.tags.RESPONSE_MESSAGE]);
    //                 this.ngZone.run(() => this.router.navigate([this.route.HOME]));
    //                 return;
    //             }
    //             return response;
    //         }).catch(error => {
    //             console.log('Server error come', error);
    //             return this.fetchErrorObject(error.message);
    //         });
    // }

    private async sendRequest(url: string, method: string, requestBody: {} = {}): Promise<any> {
        url = environment.esbUrl + url;

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                body: method === 'GET' ? null : JSON.stringify(requestBody), // Only attach body for non-GET requests
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Server error');
            }

            return await response.json();
        } catch (error) {
            console.error('Server error occurred:', error);
            return this.fetchErrorObject(error.message);
        }
    }




    // private async sendPostRequest2(requestBody: {}) {
    //     const url = "https://esb.swglobalstaging.com/esb/api/passport/contactless";

    //     return this.httpClient.post(url, requestBody).toPromise()
    //         .then(response => {

    //             return response;
    //         }).catch(error => {
    //             console.log('Server error come', error);
    //             return this.fetchErrorObject(error.message);
    //         });
    // }



    private fetchErrorObject(errorMessage: string) {
        const errorResponseObject: any = { [this.tags.RESPONSE_CODE]: this.constants.INTERNAL_SERVER_ERROR, [this.tags.RESPONSE_MESSAGE]: errorMessage };
        return errorResponseObject;
    }

    async fetchCountryStateList() {
        const requestBody = {};

        return await this.sendPostRequest('v1/config/fetchCountryStates', requestBody);
    }

    async fetchStateProcessingOfficeList(processingState: string) {
        const requestBody = {};

        return await this.sendPostRequest('v1/config/fetchStatePassportOffices/' + processingState, requestBody);
    }

    async fetchPassportFeeDetails(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/config/fetchPassportFee', requestBody);
    }

    async fetchPassportDeliveryFeeDetails(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/config/fetchDeliveryFee', requestBody);
    }

    async fetchStateLGAList(stateId: string) {
        const requestBody = {};

        return await this.sendPostRequest('v1/config/fetchStateLGA/' + stateId, requestBody);
    }

    async fetchApplicationViewData(applicantId: string, requestedResource: string) {
        const requestBody = {};

        const apiPath = requestedResource === this.constants.REQUEST_RESOURCE_BACK_OFFICE ? 'fetchPreviewData' : 'printApplication';

        return await this.sendPostRequest('v1/application/' + apiPath + '/' + applicantId, requestBody);
    }

    async confirmApplicationSubmission(applicantId: string, isSaveNow: number) {
        const requestBody = { applicantId: applicantId, isSaveNow: isSaveNow };

        return await this.sendPostRequest('v1/application/confirmApplication', requestBody);
    }


    async submitToEsb(payload: any): Promise<any> {
        const requestBody = payload;
        const url = 'passport/contactless';
        return await this.sendRequest(url, 'POST', requestBody);
    }


    async fetchContactlessStatus(appId: string, refId: string): Promise<any> {
        const url = `passport/contactless/status/${appId}/${refId}`;
        return await this.sendRequest(url, 'GET');
    }

    async fetchApplicationFromEsb(appId: string, refId: string, status: number): Promise<any> {
        const url = `passport/contactless/${appId}/${refId}/${status}`;
        return await this.sendRequest(url, 'GET');
    }



    async searchApplication(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/application/searchApplication', requestBody);
    }

    async fetchApplicationPaymentDetailsV2(applicantId: string, paymentType: string) {
        const requestBody = {};

        return await this.sendPostRequest('v1/payments/allPaymentGatewaysV2/' + applicantId + '/' + paymentType, requestBody);
    }

    async makeApplicationPayment(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/payments/generateAcknowledgement', requestBody);
    }

    async checkPaymentStatus(txnRef: string) {
        const requestBody = {};

        return await this.sendPostRequest('v1/payments/checkPaymentStatus/' + txnRef, requestBody);
    }

    async fetchApplicationPaymentReceiptDetails(txnReferenceNumber: string) {
        const requestBody = {};

        return await this.sendPostRequest('v1/payments/printAcknowledgement/' + txnReferenceNumber, requestBody);
    }

    async fetchServerToken() {
        const requestBody = {};

        return await this.sendPostRequest('v1/auth/serverToken', requestBody);
    }

    async validatePassportNumber(paspassportNumber: string) {
        const requestBody = {};

        return await this.sendPostRequest('v1/application/validatePassport/' + paspassportNumber, requestBody);
    }

    async fetchApplicationEnvironment() {
        const requestBody = {};

        return await this.sendPostRequest('v1/application/validatePassport/', requestBody);
    }

    // v2 API's start from here

    async generateEmailToken(email: string) {
        const requestBody = { email };

        return await this.sendPostRequest('v2/application/generateEmailToken', requestBody);
    }

    async validateEmailToken(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/validateEmailToken', requestBody);
    }

    async validateNIN(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/validateNIN', requestBody);
    }

    async saveNIN(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/saveNIN', requestBody);
    }

    async validatePhoto(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/validateImage', requestBody);
    }

    async validateImageBeforeUpload(base64Data: string) {
        const requestBody = { base64Data };

        return await this.sendPostRequest('v2/application/validateImageBeforeUpload', requestBody);
    }

    async savePhoto(applicantId: string, base64Data: string) {
        const requestBody = { applicantId, base64Data };

        return await this.sendPostRequest('v2/application/saveImage', requestBody);
    }

    async fetchV2ApplicationData(applicantId: string) {
        const requestBody = { applicantId };

        return await this.sendPostRequest('v2/application/applicantStageData', requestBody);
    }

    async saveV2PersonalDetailsData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/bioData', requestBody);
    }

    async saveV2ContactInformationData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/contactInfo', requestBody);
    }

    async saveV2KinInformationData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/kinInfo', requestBody);
    }

    async saveV2ProcessingInfoData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/processingInfo', requestBody);
    }

    async fetchApplicableDocs(applicantId: string) {
        const requestBody = { applicantId };

        return await this.sendPostRequest('v2/application/fetchApplicableDocs', requestBody);
    }

    async saveApplicableDocs(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/saveApplicableDocs', requestBody);
    }

    async fetchDocBase64Data(applicantDocId: string) {
        const requestBody = { applicantDocId };

        return await this.sendPostRequest('v2/application/fetchDocBase64Data', requestBody);
    }

    async validateQueriedLink(token: string) {
        const requestBody = { token };

        return await this.sendPostRequest('v2/application/validateQueriedLink', requestBody);
    }

    async saveReuploadedDocs(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/saveReuploadedDocs', requestBody);
    }

    async fetchDocHistory(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/fetchDocHistory', requestBody);
    }

    async fetchCurrentPassportData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/cod/fetchExistingPassportDetails', requestBody);
    }

    async saveCurrentPassportData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/cod/saveCurrentPassportData', requestBody);
    }

    async fetchCODComparisonData(dataCorrectionId: string) {
        const requestBody = { dataCorrectionId };

        return await this.sendPostRequest('v2/cod/fetchCODComparisonData', requestBody);
    }

    async saveCODComparisonData(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/cod/saveCODData', requestBody);
    }

    async fetchValidityList(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/config/fetchValidityList', requestBody);
    }

    async sendGuidelineMonitor(referenceId: string) {
        const requestBody = { referenceId };

        return await this.sendPostRequest('v2/application/monitor/guidelines', requestBody);
    }

    async fetchBookletList(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v1/config/fetchBookletList', requestBody);
    }

    async savePassportDeliveryDetails(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest('v2/application/deliveryInfo', requestBody);
    }

    // async fetchDeliveryStateList() {
    //     const requestBody = {};
    //     return await this.sendPostRequest('v1/config/fetchDeliveryStates', requestBody);  
    // }

}
