import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { environment } from 'src/environments/environment';
import { LocalStorage } from '../common/local-storage';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {

  private serverEncryptedTxnRef:string='';

  paymentDetailsObject: any = {};

  private paymentSuccessForm:HTMLFormElement;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constant: Constants, private utils: Utils, 
              private loaderService: LoaderService, private gui: GUI, private localStorage: LocalStorage) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.serverEncryptedTxnRef = params['txnRef'];
    });
    this.checkPaymentStatus();
  }

  ngOnInit(): void {
    this.paymentSuccessForm = document.forms['paymentSuccessForm'];
  }

  private checkPaymentStatus(): void {
    this.loaderService.startLoader();

    // Todo
    // const serverDecryptedTxnRef = this.utils.decryptServerEncryptedString(this.serverEncryptedTxnRef);
    const serverDecryptedTxnRef = this.serverEncryptedTxnRef;
    this.httpUtils.checkPaymentStatus(serverDecryptedTxnRef).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constant.STATUS_SUCCESS){
        this.paymentDetailsObject = serverResponse;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.gui.openPaymentFailurePage(this.serverEncryptedTxnRef, serverResponse.referenceNumber, serverResponse.applicationId);
      }
    });
  }

  openDashboard(): void {
    this.gui.openHomePage();
  }

  viewApplication(): void {
    if(this.paymentDetailsObject.paymentType === this.constant.APPLICATION_PAYMENT){
      this.gui.openApplicationView(this.paymentDetailsObject.applicantId, this.constant.KIN_INFO_STAGE);
    } else if(this.paymentDetailsObject.paymentType === this.constant.COD_PAYMENT){
      this.gui.openCODView(this.paymentDetailsObject.passcode);
    }
  }

  printApplication(): void {
    window.print();
  }

  bookAppointment(): void {
    const base64String = btoa(this.paymentDetailsObject.applicationId + '|' + this.paymentDetailsObject.referenceNumber); 
    const url = environment.appointmentApplicationUrl + '?a=' + base64String;
    window.open(url, '_blank');
  }

  openPremiumApplication(): void {
    const url = environment.passportPremiumServiceUrl;
    window.open(url, '_blank');
  }

  downloadPDF(downloadType: string): void {
    this.paymentSuccessForm.action = environment.serverBaseUrl + 'v1/application/downloadApplicationPDF';
    this.paymentSuccessForm.downloadType.value = downloadType;
    this.paymentSuccessForm.submit();
  }

}