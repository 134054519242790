<app-header></app-header>

<div class="wrapper">

    <section class="main">

        <form action="" method="post" name="dashboardForm" autocomplete="off">
            <input type="hidden" name="loggedUserId" value="" />
        </form>

        <div class="new_app">
            <h3 class="pb-0" style="width: initial;">{{pageTitle}}</h3>

            <input *ngIf="routeName === constants.RESUME_APPLICATION" class="custom_btn pay_btn pay-height" type="button" value="Pay" (click)="openSearchModel()">
        </div>

        <div class="tabset">

            <div class="tab-panels">

                <section *ngIf="routeName === constants.RESUME_APPLICATION" id="incomplete" class="tab-panel">

                    <div class="height_fixx">

                        <table width="100%" class="dashboard-table">
                            <thead>
                                <th width="2%"><input type="checkbox" [(ngModel)]="selectedAllApplications" (change)="selectAllCompletedApplications($event)"/></th>
                                <th width="3%">#</th>
                                <th width="30%">Applicant Name</th>
                                <th width="10%">Applying For</th>
                                <th width="10%">Date Of Birth</th>
                                <th width="12%">Application No.</th>
                                <th width="15%">Reference No.</th>
                                <th width="7%" style="text-align:right;">Amount</th>
                                <th>Action</th>
                            </thead>

                            <tbody>
                                <tr *ngFor="let applicationObject of applicationsList; let i = index;" [ngStyle]="{'background': applicationObject.applicationExpiryStatus === constants.TRUE ? '#f0d8d8' : '#ffffff'}">
                                    <td data-label="Select Application" scope="row" class="mark">
                                        <ng-container *ngIf="applicationObject.applicationExpiryStatus === constants.FALSE && applicationObject.applicationStatus === constants.STATUS_SUCCESS && applicationObject.isContactless == this.constants.FALSE">
                                            <input type="checkbox" [(ngModel)]="applicationObject.selected" (change)="checkIfAllCompletedApplicationsSelected($event, applicationObject)" [disabled]="applicationObject.applicationExpiryStatus === constants.TRUE || applicationObject.isContactless == this.constants.TRUE"/>
                                        </ng-container>
                                    </td>
                                    <td data-label="S No.">{{i + 1}}</td>
                                    <td data-label="Applicant Name">{{applicationObject.fullName}}</td>
                                    <td data-label="Applying For"><span>{{applicationObject.applyingForLabel}}</span></td>
                                    <td data-label="date of Birth"><span>{{applicationObject.dateOfBirth}}</span></td>
                                    <td data-label="application No.">{{applicationObject.applicationIdV1}}</td>
                                    <td data-label="reference No."><span>{{applicationObject.referenceIdV1}}</span></td>
                                    <td data-label="Total Amount" style="text-align:right;" >{{applicationObject.currencySymbol}} {{applicationObject.applicationFee}}</td>
                                    <td data-label="Action" *ngIf="applicationObject.applicationStatus === constants.STATUS_INITIATE">
                                        <span *ngIf="applicationObject.applicationExpiryStatus === constants.FALSE" (click)="editApplication(applicationObject.applicantId)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">Edit</a></span>
                                        <span (click)="showDeleteConfirmation(applicationObject.applicantId, applicationObject.applicationIdV1, constants.TRUE)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">Delete</a></span>
                                    </td>

                                    <td data-label="Action" *ngIf="applicationObject.applicationStatus === constants.STATUS_SUCCESS" class="white-nowrap">
                                        <span (click)="viewApplication(applicationObject.applicantId)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">View</a></span>
                                        <span (click)="showDeleteConfirmation(applicationObject.applicantId, applicationObject.applicationIdV1, constants.FALSE)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">Delete</a></span>
                                        <span *ngIf="applicationObject.applicationExpiryStatus === constants.FALSE && applicationObject.isAddedToCart === constants.TRUE" (click)="checkPaymentStatus(applicationObject.applicantId)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">Verify Payment</a></span>
                                    </td>
                                </tr>

                                <tr *ngIf="applicationsList?.length === 0">
                                    <td colspan="7" style="text-align:left;">No record found</td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </section>

                <section *ngIf="routeName === constants.PAYMENT_STATUS" id="paid" class="tab-panel">

                    <div class="height_fixx">

                        <table width="100%" class="dashboard-table">

                            <thead>
                                <th width="3%">#</th>
                                <th width="30%">Applicant Name</th>
                                <th width="10%">Applying For</th>
                                <th width="10%">Date Of Birth </th>
                                <th width="12%">Application No.</th>
                                <th width="14%">Reference No.</th>
                                <th width="6%" style="text-align:right;">Amount</th>
                                <th width="10%">Payment Date</th>
                                <th>Action</th>
                            </thead>

                            <tbody>
                                <tr *ngFor="let paidApplication of applicationsList; let i = index;" [ngStyle]="{'background': paidApplication.applicationExpiryStatus === constants.TRUE ? '#f0d8d8' : '#ffffff'}">
                                    <td data-label="S No.">{{i + 1}}</td>
                                    <td data-label="Applicant Name">{{paidApplication.fullName}}</td>
                                    <td data-label="Applying For">{{paidApplication.applyingForLabel}}</td>
                                    <td data-label="date of Birth"><span>{{paidApplication.dateOfBirth}}</span></td>
                                    <td data-label="application No.">{{paidApplication.applicationIdV1}}</td>
                                    <td data-label="reference No."><span>{{paidApplication.referenceIdV1}}</span></td>
                                    <td data-label="total Amount" style="text-align:right;">{{paidApplication.currencySymbol}} {{paidApplication.applicationFee}}</td>
                                    <td data-label="payment Date">{{paidApplication.paymentDate}}</td>
                                    <td data-label="Action" class="white-nowrap">
                                        <span (click)="viewApplication(paidApplication.applicantId)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">View</a></span>
                                        <span *ngIf="paidApplication.applicationExpiryStatus === constants.FALSE" (click)="checkPaymentRefundStatus(paidApplication.applicantId, paidApplication.applicationIdV1, paidApplication.referenceIdV1)" style="margin-left: 8px;"><a href="javascript:void(0);" class="dashboard_links">Book Appointment</a></span>
                                    </td>
                                </tr>

                                <tr *ngIf="applicationsList?.length === 0">
                                    <td colspan="9" style="text-align:left;">No record found</td>
                                </tr>
                            </tbody>

                        </table>

                    </div>

                </section>

                <div class="mob_legends legends">
                    <span><i class="fa fa-circle" style="color: #f0d8d8; background-color: #f0d8d8; border: 1px solid #aaa22c;"></i>&nbsp;Expired Application</span>
                </div>

                <ng-container *ngIf="routeName === constants.RESUME_APPLICATION">
                    <div class="mark_btn" >
                        <input type="button" (click)="initiatePayment()" [disabled]="selectedApplicationsCount === 0" class="action-button custom_btn" value="Pay {{masterCurrencySymbol}}{{selectedApplicationsAmount}}" style="margin: 0;"/>

                        <p *ngIf="selectedApplicationsCount > 0">You have selected {{selectedApplicationsCount}} applications</p>
                    </div>
                </ng-container>

            </div>

        </div>

    </section>

</div>

<app-footer></app-footer>
