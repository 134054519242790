import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.css']
})
export class PaymentFailureComponent {

  private serverEncryptedTxnRef : string = this.constant.BLANK;

  private serverEncryptedReferenceNumber : string = this.constant.BLANK;

  private serverEncryptedApplicationId : string = this.constant.BLANK;

  txnRef : string = this.constant.BLANK;

  referenceNumber : string = this.constant.BLANK;

  applicationId : string = this.constant.BLANK;

  serverResponseCode : number = this.constant.FALSE;

  failedReason : string = this.constant.BLANK;

  private paymentType : string = this.constant.BLANK;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constant: Constants, private utils: Utils, 
              private loaderService: LoaderService, private localStorage: LocalStorage, private simpleModalService: SimpleModalService, private gui: GUI) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.serverEncryptedTxnRef = params["txnRef"];
      this.serverEncryptedReferenceNumber = params["referenceNumber"];
      this.serverEncryptedApplicationId = params["applicationId"];
    });
    this.txnRef = this.utils.decryptServerEncryptedString(this.serverEncryptedTxnRef);
    this.referenceNumber = this.utils.decryptServerEncryptedString(this.serverEncryptedReferenceNumber);
    this.applicationId = this.utils.decryptServerEncryptedString(this.serverEncryptedApplicationId);
    this.paymentType = this.utils.decryptString(this.localStorage.getStorageKey(this.constant.PAYMENT_TYPE));
    this.checkPaymentStatus();
  }

  private checkPaymentStatus(): void {
    this.loaderService.startLoader();
    this.httpUtils.checkPaymentStatus(this.txnRef).then(serverResponse => {
      this.loaderService.stopLoader();
      this.serverResponseCode = serverResponse.responseCode;
      if(this.serverResponseCode === this.constant.STATUS_SUCCESS){
        this.gui.openPaymentSuccessPage(this.serverEncryptedTxnRef);
      } else {
        this.failedReason = serverResponse.responseMsg;
        if(this.utils.isEmpty(this.applicationId) === true){
          this.applicationId = serverResponse.applicationId;
        }
      }
    });
  }

  openDashboard(): void {
    this.simpleModalService.addModal(ConfirmationModalComponent, {message: this.getConfMes()}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.gui.openHomePage();
      }
    });
  }

  private getConfMes(): string {
    let message = "APPLICATION NUMBER <b>"+this.applicationId+"</b> ";

    if(this.referenceNumber !== this.constant.NOT_FOUND){
      message += "& REFERENCE NUMBER <b>"+this.referenceNumber+"</b> ";
    }

    message += "(IMPORTANT - PLEASE WRITE DOWN THE "

    if(this.referenceNumber !== this.constant.NOT_FOUND){
      message += "REFERENCE NUMBER AND ";
    }

    message += "APPLICATION NUMBER. YOU MAY REQUIRE IT LATER)";

    return message;
  }

  retryPayment(): void {
    this.gui.openPaymentPage(this.utils.encryptString(this.applicationId), this.paymentType);
  }

}