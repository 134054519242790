import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { GUI } from '../common/gui';
import { NgForm } from '@angular/forms';
import { CaptchaComponent } from '../captcha/captcha.component';
import { LocalStorage } from '../common/local-storage';

@Component({
  selector: 'app-nin-verification',
  templateUrl: './nin-verification.component.html',
  styleUrls: ['./nin-verification.component.css']
})
export class NinVerificationComponent {

  @ViewChild(CaptchaComponent) captchaComponent : CaptchaComponent;

  @ViewChild('ninVerificationForm') ninVerificationForm : NgForm;

  private encryptedApplyingFor: string = this.constants.BLANK;

  private loggedUserId: string = this.constants.BLANK;

  applyingFor: string = this.constants.BLANK;

  pageTitle: string  = this.constants.BLANK;

  ninVerificationFormObject: any = {};
  
  ninDetailsObject : any = {};
  
  monthsList: any = [];


  userRecords: any = [
    {
      id: 1,
      masterId: "APL241021.1902.000260",
      lastName: "ADEREMl",
      firstName: "AYODELE",
      middleName: "ANTHONY",
      oldDocument: "В50022520",
      nin: "50022520000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 2,
      masterId: "APL241021.1902.000260",
      lastName: "ADESUYAN",
      firstName: "OREOLUWA",
      middleName: "ADEBAMBO",
      oldDocument: "B00602878",
      nin: "00602878000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 3,
      masterId: "APL241021.1902.000260",
      lastName: "АНМАD",
      firstName: "MUYIDEEN",
      middleName: "",
      oldDocument: "B51027828",
      nin: "51027828000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 4,
      masterId: "APL241021.1902.000260",
      lastName: "АКАГЧОЕ",
      firstName: "MICHAEL",
      middleName: "OLUSEYE",
      oldDocument: "B50022218",
      nin: "50022218000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 5,
      masterId: "APL241021.1902.000260",
      lastName: "ALABI",
      firstName: "ABDULMAJEED",
      middleName: "BOLAJl",
      oldDocument: "B00766811",
      nin: "00766811000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 6,
      masterId: "APL241021.1902.000260",
      lastName: "ANYANWU",
      firstName: "ESTHER",
      middleName: "NWANNEDINMA",
      oldDocument: "B50279308",
      nin: "50279308000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 7,
      masterId: "APL241021.1902.000260",
      lastName: "ASOMBA",
      firstName: "BLESSlNG",
      middleName: "CHIDINMA",
      oldDocument: "B00367160",
      nin: "00367160000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 8,
      masterId: "APL241021.1902.000260",
      lastName: "AYORO",
      firstName: "TIMOTHY",
      middleName: "ERHUVWU",
      oldDocument: "В00706427",
      nin: "00706427000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 9,
      masterId: "APL241021.1902.000260",
      lastName: "BARAKI",
      firstName: "ISMAILA",
      middleName: "",
      oldDocument: "B00568053",
      nin: "00568053000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 10,
      masterId: "APL241021.1902.000260",
      lastName: "EBOIGBE",
      firstName: "SYLVESTER",
      middleName: "OISE",
      oldDocument: "В00933508",
      nin: "00933508000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 11,
      masterId: "APL241021.1902.000260",
      lastName: "EDНЕВЕ",
      firstName: "PETERS",
      middleName: "EMUMEWHOMA",
      oldDocument: "B00187765",
      nin: "00187765000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 12,
      lastName: "EMUZE",
      firstName: "DAMILARE",
      middleName: "ELIZABETH",
      oldDocument: "В50485015",
      nin: "50485015000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 13,
      lastName: "ETERI",
      firstName: "ROLAND",
      middleName: "ONOME",
      oldDocument: "B01268039",
      nin: "01268039000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 14,
      lastName: "FADAYOMI",
      firstName: "OLASUNKANMI",
      middleName: "SUNDAY",
      oldDocument: "В01049806",
      nin: "01049806000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 15,
      lastName: "FATUSIN",
      firstName: "FELIX",
      middleName: "OLANUBI",
      oldDocument: "B50161348",
      nin: "50161348000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 16,
      masterId: "APL241021.1902.000260",
      lastName: "FlSHER",
      firstName: "OLADEJl",
      middleName: "AMAKIRI",
      oldDocument: "В50000476",
      nin: "50000476000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 17,
      masterId: "APL241021.1902.000260",
      lastName: "IFENNE",
      firstName: "IHOTU",
      middleName: "ILAME",
      oldDocument: "B01343708",
      nin: "01343708000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 18,
      masterId: "APL241021.1902.000260",
      lastName: "LALEYE",
      firstName: "OLUSOLA",
      middleName: "AYODEJI",
      oldDocument: "В00780909",
      nin: "00780909000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 19,
      masterId: "APL241021.1902.000260",
      lastName: "LAWSON",
      firstName: "lBIMlNA",
      middleName: "JANE",
      oldDocument: "B50178207",
      nin: "50178207000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 20,
      masterId: "APL241021.1902.000260",
      lastName: "MUDASIRU",
      firstName: "АКЕЕМ",
      middleName: "OLALEKAN",
      oldDocument: "В00707134",
      nin: "00707134000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 21,
      masterId: "APL241021.1902.000260",
      lastName: "NJIDDA",
      firstName: "HAUWA KULU",
      middleName: "",
      oldDocument: "B50696813",
      nin: "50696813000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 22,
      masterId: "APL241021.1902.000260",
      lastName: "NWAOKIKE",
      firstName: "CHlNWE",
      middleName: "PRECIOUS",
      oldDocument: "В00744180",
      nin: "00744180000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 23,
      masterId: "APL241021.1902.000260",
      lastName: "OGBE",
      firstName: "DOMINIC",
      middleName: "",
      oldDocument: "В01753172",
      nin: "01753172000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 24,
      masterId: "APL241021.1902.000260",
      lastName: "OGBU",
      firstName: "IJEOMА",
      middleName: "MERCY",
      oldDocument: "B03632825",
      nin: "03632825000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 25,
      masterId: "APL241021.1902.000260",
      lastName: "OKOROAFO",
      firstName: "CHlDl",
      middleName: "ALEX",
      oldDocument: "B02408572",
      nin: "02408572000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 26,
      masterId: "APL241021.1902.000260",
      lastName: "OKORONKWO",
      firstName: "GOLD",
      middleName: "AJUWA",
      oldDocument: "В50220797",
      nin: "50220797000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 27,
      masterId: "APL241021.1902.000260",
      lastName: "OSAGHAE",
      firstName: "BOB",
      middleName: "BERNARD",
      oldDocument: "B50112246",
      nin: "50112246000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 28,
      masterId: "APL241021.1902.000260",
      lastName: "OSIEGBU",
      firstName: "ONYEKACHUKWU",
      middleName: "AYOMIDE",
      oldDocument: "В00329998",
      nin: "00329998000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 29,
      masterId: "APL241021.1902.000260",
      lastName: "OYEWUMl",
      firstName: "AYODEJI",
      middleName: "AUGUSTlNE",
      oldDocument: "B50342899",
      nin: "50342899000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 30,
      masterId: "APL241021.1902.000260",
      lastName: "OYINLOYE",
      firstName: "RACHAEL",
      middleName: "",
      oldDocument: "В00386820",
      nin: "00386820000",
      gender: "F",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 31,
      masterId: "APL241021.1902.000260",
      lastName: "SALISU",
      firstName: "SEMlU",
      middleName: "GBADEGESHIN",
      oldDocument: "B50155715",
      nin: "50155715000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "SINGLE"
    },
    {
      id: 32,
      masterId: "APL241021.1902.000260",
      lastName: "SALISU",
      firstName: "TlJANI",
      middleName: "ADEGBOOLA",
      oldDocument: "В00929717",
      nin: "00929717000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 33,
      masterId: "APL241021.1902.000260",
      lastName: "TIJJANI",
      firstName: "USMAN",
      middleName: "",
      oldDocument: "В50715426",
      nin: "50715426000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    },
    {
      id: 34,
      masterId: "APL241021.1902.000260",
      lastName: "YAKUB",
      firstName: "ISA",
      middleName: "BISI",
      oldDocument: "B51250910",
      nin: "51250910000",
      gender: "M",
      dateOfBirth: "12/11/1960",
      maritalStatus: "MARRIED"
    }
];
  
  showNINDetailsSection: boolean = false;

  private isAdminLoggedIn: number = this.constants.FALSE;

  constructor(private activatedRoute: ActivatedRoute, private utils: Utils, public constants: Constants, private httpUtils: HttpUtils, private loaderService: LoaderService, private toast: Toast, private gui: GUI, 
              private localStorage: LocalStorage) { 
    this.activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplyingFor = params['applyingFor'];
    });
    this.applyingFor = this.utils.decryptString(this.encryptedApplyingFor);
    this.setPageTitle();
    this.setFormDefaultValues();
    this.checkIsAdminLoggedIn();
  }

  private checkIsAdminLoggedIn(): void {
    this.loggedUserId = this.localStorage.getLoggedUserId();
    this.isAdminLoggedIn =  this.utils.isEmpty(this.loggedUserId) === false ? this.constants.TRUE : this.constants.FALSE; 
  }

  private setPageTitle(): void {
    if(this.applyingFor === this.constants.FRESH) {
      this.pageTitle = 'NIN Verification for Fresh Passport Application';
    } else if(this.applyingFor === this.constants.RENEW) {
      this.pageTitle = 'NIN Verification for Renewal/Re-issue Passport Application';
    } else if(this.applyingFor === this.constants.REISSUE){
      this.pageTitle = 'NIN Verification for Correction of Data';
    }
  }
  
  private setFormDefaultValues(): void {
    this.monthsList = this.utils.getMonthsArray();
    this.ninVerificationFormObject.applyingFor = this.applyingFor;
    this.ninVerificationFormObject.dateOfBirthMonth = this.constants.BLANK;
    this.showNINDetailsSection = false;
  }

  verifyCaptcha(): void {
    this.captchaComponent.verifyCaptcha();
  }

  // validateNIN(): void {
  //   this.ninVerificationFormObject.dateOfBirth = this.ninVerificationFormObject.dateOfBirthDay + '/' + this.ninVerificationFormObject.dateOfBirthMonth + '/' + this.ninVerificationFormObject.dateOfBirthYear;
    
  //   this.httpUtils.validateNIN(this.ninVerificationFormObject).then(serverResponse =>{
  //     this.loaderService.stopLoader();
  //     if(serverResponse.responseCode == this.constants.STATUS_SUCCESS){
  //       this.ninDetailsObject = serverResponse.details;
  //       this.showNINDetailsSection = true;
  //     }else{
  //       this.toast.showErrorToast(serverResponse.responseMsg);
  //     }
  //   });
  // }

  validateNIN(): void {
    // Concatenate date of birth fields into a single value
    this.ninVerificationFormObject.dateOfBirth = 
      this.ninVerificationFormObject.dateOfBirthDay + '/' + 
      this.ninVerificationFormObject.dateOfBirthMonth + '/' + 
      this.ninVerificationFormObject.dateOfBirthYear;
  
    // Filter userRecords array to find a user with a matching NIN
    const matchingUser = this.userRecords.find(user => user.nin === this.ninVerificationFormObject.nin);
  
    if (matchingUser) {
      // NIN found in local records, simulating success
      this.ninDetailsObject = matchingUser; // Pass the matched user's details
      this.showNINDetailsSection = true; // Show the section
      this.loaderService.stopLoader();
    } else {
      // If NIN not found locally, proceed to server validation
      this.httpUtils.validateNIN(this.ninVerificationFormObject).then(serverResponse => {
        this.loaderService.stopLoader();
        
        if (serverResponse.responseCode === this.constants.STATUS_SUCCESS) {
          this.ninDetailsObject = serverResponse.details; // Use server details if NIN is valid
          this.showNINDetailsSection = true;
        } else {
          this.toast.showErrorToast(serverResponse.responseMsg); // Show error if NIN not found on server
        }
      }).catch(error => {
        this.loaderService.stopLoader();
        this.toast.showErrorToast("Error validating NIN. Please try again.");
      });
    }
  }
  
  

  saveNINDetails(): void {
    this.loaderService.startLoader();
    this.ninVerificationFormObject.isFilledByAdminForCustomer = this.isAdminLoggedIn;
    this.ninVerificationFormObject.loggedUserId = this.loggedUserId;

    this.httpUtils.saveNIN(this.ninVerificationFormObject).then(serverResponse =>{
      this.loaderService.stopLoader();
      if(serverResponse.responseCode == this.constants.STATUS_SUCCESS){
        const masterId = serverResponse.masterId;
        if(this.applyingFor === this.constants.FRESH) {
          this.gui.openApplicationAddEditPage(masterId);
        } else {
          this.gui.openPassportVerificationPage(masterId, this.applyingFor);
        }
      } else{
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }


  reset(): void {
    this.ninVerificationForm.resetForm();
    this.captchaComponent.resetCaptcha();
    this.setFormDefaultValues();
  }

}
