import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { GUI } from './gui';
import { LocalStorage } from './local-storage';
import { Tags } from './tags';
import { Utils } from './utils';


@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {

    constructor(protected readonly router: Router, protected readonly keycloak: KeycloakService, private localStorage: LocalStorage, private tags: Tags, private utils: Utils, private gui: GUI) {
        super(router, keycloak);
    }

    public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.authenticated) {
            // await this.keycloak.login({
            //     redirectUri: window.location.origin + state.url,
            // });
            this.gui.openLogoutPage();
            return;
        }

        const keycloakToken = Cookie.get(this.tags.KEYCLOAK_TOKEN);
        const keycloakRefreshToken = Cookie.get(this.tags.KEYCLOAK_REFRESH_TOKEN);
        const loggedUserId = this.localStorage.getLoggedUserId();

        if(this.utils.isEmpty(keycloakToken) === true || this.utils.isEmpty(keycloakRefreshToken) === true || this.utils.isEmpty(loggedUserId) === true){
            this.gui.openLogoutPage();
            return;
        }

        const requiredRoles = route.data.roles;

        if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
            return true;
        }

        return requiredRoles.every((role) => this.roles.includes(role));
    }

}
