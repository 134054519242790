<app-header></app-header>

<div class="banner-img-name"></div>

<main class="body_section">

    <div class="main">

        <h3 style="padding-bottom: 0;">Apply For Change Request</h3>

        <fieldset class="request-detail">

            <div class="step_info">
                <span>Data Comparison & Review</span>
            </div>

            <div class="form-card">
                <p class="note red">Note: YOUR EXISTING PASSPORT DATA IS BEING COMPARED TO YOUR NIN RECORD.</p>
                <form #codComparisonForm="ngForm" (ngSubmit)="codComparisonForm.form.valid && openInformationModal()" autocomplete="off" name="codComparisonForm" id="msform" class="change-request-form">
                    <table class="request_table">
                        <tr>
                            <td><b>Name of Fields</b></td>
                            <td><b>Data from Current Passport</b></td>
                            <td><b>Data from NIN Records</b></td>
                            <td><b>Matched Data</b></td>
                        </tr>
                        <tr *ngFor="let comparisonItems of codComparisonItemsList">
                            <td>{{comparisonItems.fieldLabel}}</td>
                            <td>{{comparisonItems.dataOnPassport}}</td>
                            <td><b>{{comparisonItems.dataOnNIN}}</b></td>
                            <td *ngIf="comparisonItems.comparisonResult === true">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </td>
                            <td *ngIf="comparisonItems.comparisonResult === false">
                                <i class="fa fa-times-circle" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </table>
                    <div class="grid-two">
                        <div class="form_box request_form_box">
                            <label class="fieldlabels">Reason for Change</label>
                            <div class="input_box">
                                <select name="reasons" hint [(ngModel)]="codComparisonFormObject.reason" #reasons="ngModel" required="required" class="effect"  [disabled]="disableReason">                                 
                                    <option *ngFor="let reason of codReasonList" [value]="reason.key">{{reason.value}}</option>
                                </select>
                                <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                <span class="tooltip"><span class='tooltipArrow'></span></span>
                                <span class="focus-border "><i></i></span>
                            </div>
                            <span class="alert alert-danger" role="alert" *ngIf="codComparisonForm.submitted && reasons.invalid">
                                <span *ngIf="reasons.errors.required">
                                    Please select reason!
                                </span>
                            </span>
                        </div>
                    </div>

                    <div class="t_and_c pb10" >
                        <label class="pointer">
                            <input type="checkbox" name="termCondition" [(ngModel)]="isTermConditionSelect" #termCondition="ngModel" required="required"><span style="padding: 0 3px;"> I hereby confirm that all the information I have provided is genuine &amp; correct</span>
                            <span class="alert alert-danger" role="alert" *ngIf="codComparisonForm.submitted && termCondition.invalid">
                                <span *ngIf="termCondition.errors.required">
                                    Confirmation is required
                                </span>
                            </span>
                        </label>
                    </div>

                    <input type="submit" value="Continue" class="action-button custom_btn">
                </form>
            </div>
        </fieldset>
    </div>
</main>

<app-footer></app-footer>
