import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { GUI } from '../common/gui';
import { LoaderService } from '../common/loader-service';
import { Utils } from '../common/utils';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';

@Component({
  selector: 'app-contactless-payment',
  templateUrl: './contactless-payment.component.html',
  styleUrls: ['./contactless-payment.component.css']
})
export class ContactlessPaymentComponent implements OnInit {
  applicationId: string | null = null;
  referenceNumber: string | null = null;
  status: number | null = null;
  private encryptedApplicantId: string = this.constants.BLANK;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpUtils: HttpUtils,
    public constants: Constants,
    private loaderService: LoaderService,
    public utils: Utils,
    public gui: GUI
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.applicationId = params['applicationId'] || null;
      this.referenceNumber = params['referenceNumber'] || null;
      this.status = params['status'] || null;
    });
  }


  ngOnInit(): void {
    this.checkApplicationFromEsb();
  }

  private checkApplicationFromEsb(): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationFromEsb(this.applicationId, this.referenceNumber, this.status).then(serverResponse => {

      if (serverResponse) {
        localStorage.setItem("applicationId", serverResponse?.aId);
        localStorage.setItem("referenceId", serverResponse?.refId);
        localStorage.setItem("contactlessStatus", serverResponse?.contactlessStatus);
        this.gui.openPaymentPage(serverResponse.applicantId, this.constants.APPLICATION_PAYMENT);
      }

    })

    this.loaderService.stopLoader();
  }


}
