import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Route } from '../common/route';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-new-contactless-success',
  templateUrl: './new-contactless-response.component.html',
  styleUrls: ['./new-contactless-response.component.css']
})
export class NewContactlessResponseComponent implements OnInit {

  applicationId: string = "";
  referenceId: string = "";
  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants,
              private utils: Utils, private loaderService: LoaderService, private route: Route, public gui: GUI) {
    this.activatedRoute.params.subscribe((params: Params) => {
      // this.encryptedApplicantId = params["applicantId"];
      // this.encryptedTxnRefNumber = params["txnRefNumber"];
    });
    // this.checkPaymentStatus();
  }
  private getApplicationInfo(): void {
    this.applicationId = JSON.parse(localStorage.getItem("applicationId")) || null;
    this.referenceId = JSON.parse(localStorage.getItem("referenceId")) || null;

    if (this.applicationId === null && this.referenceId === null) {
      this.gui.openHomePage();

    }
  }
  ngOnInit(): void {
    this.getApplicationInfo();
  }

  ngOnDestroy(): void {
    this.clearApplicationInfo();
  }


  private clearApplicationInfo(): void {
    localStorage.removeItem("applicationId");
    localStorage.removeItem("referenceId");
    localStorage.removeItem("contactlessStatus");
  }

}
