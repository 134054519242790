import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Constants {

    STATUS_INITIATE = 100;

    STATUS_REJECTED = 150;

    STATUS_SUCCESS = 200;

    STATUS_QUERY = 203;

    STATUS_AUTH_TOKEN_NOT_VALID = 1200;

    INTERNAL_SERVER_ERROR = 500;

    TRUE = 1;

    FALSE = 0;

    ANY = -1;


    PASSPORT_STEP = 1;

    UPLOAD_PHOTO_STAGE = 2;

    PERSONAL_INFO_STEP = 3;

    CONTACT_INFO_STEP = 4;

    NEXT_OF_KIN_INFO_STEP = 5;

    DOCUMENTS_UPLOADED_STAGE = 6;

    PASSPORT_DELIVERY_STAGE = 7;


    PAYMENT_ALREADY_COMPLETE = 1107;

    PAYMENT_ALREADY_INITIATED = 1108;

    RESEND_VERIFICATION_LINK_TIME = 60; //THIS TIME IS IN SECONDS

    MIN_DATE_OF_BIRTH_YEAR = 1900;

    PASSPORT_TYPE = 10;

    MAX_APPLICATIONS_ALLOWED_FOR_PAYMENT = 5;

    FILE_TYPE_PDF = "application/pdf";

    ALLOWED_FILE_SIZE = 2;

    USER_IMAGE_REQUIRED_SIZE_IN_MB = 2;

    USER_IMAGE_REQUIRED_WIDTH = 600;

    USER_IMAGE_REQUIRED_HEIGHT = 800;

    TOASTER_DELAY = 3000;

    CONTACTLESS_FEE = 100;

    BLANK = ''

    MINOR = 'MINOR';

    ADULT = 'ADULT';

    SENIOR_CITIZEN = 'SENIOR_CITIZEN';

    MR = 'MR';

    MRS = 'MRS';

    MISS = 'MISS';

    MALE = 'MALE';

    FEMALE = 'FEMALE';

    SINGLE = 'SINGLE';

    MARRIED = 'MARRIED';

    WIDOWED = 'WIDOWED';

    DIVORCED = 'DIVORCED';

    FATHER = 'FATHER';

    MOTHER = 'MOTHER';

    SPOUSE = 'SPOUSE';

    BROTHER = 'BROTHER';

    SISTER = 'SISTER';

    SON = 'SON';

    DAUGHTER = 'DAUGHTER';

    FRIEND = 'FRIEND';

    RELATIVE = 'RELATIVE';

    ENCRYPT_DECRYPT_KEY = 'USD_PASSPORT_ENCRYPT_DECRYPT_KEY';

    ENCRYPT_DECRYPT_MASTER_KEY = '89085G5GH85QW75E';

    ENCRYPT_DECRYPT_MASTER_PASSWORD = 'PS852AA96DRK3L93978Q9L85672IWD63';

    ENCRYPT_DECRYPT_MASTER_SALT = 'NIS_USER';

    FRESH = 'FRESH';

    RENEW = 'RENEW';

    REISSUE = 'REISSUE';

    STANDARD_PASSPORT = 'STANDARD_E_PASSPORT';

    STANDARD_PASSPORT_RENEWAL = 'STANDARD_E_PASSPORT_RENEWAL_REISSUE';

    STANDARD_PASSPORT_COD = 'STANDARD_E_PASSPORT_COD';

    EXPIRED = 'EXPIRED';

    PAGE_EXHAUSTED = 'PAGE_EXHAUSTED';

    LOST_REPLACEMENT_REQUEST = 'LOST_REPLACEMENT_REQUEST';

    DAMAGED_PASSPORT = 'DAMAGED_PASSPORT';

    RESUME_APPLICATION = 'RESUME_APPLICATION';

    PAYMENT_STATUS = 'PAYMENT_STATUS';

    APPLICATION_ID = 'applicationId';

    REF_NUM_SEQUENCE = 'refNumSequence';

    MOBILE_NUMBER = 'mobileNumber';

    DATE_OF_BIRTH = 'dateOfBirth';

    EMAIL_ID = 'emailId';

    DEFAULT_SELECT_OPTION_LABEL = 'Choose Option';

    NOT_FOUND_KEY = '920b8d07262e4e924669c0e38ef57e0da66b68c9d387248d83a9aa19be7c96c4';

    NOT_PROVIDED = 'Not Provided';

    DEFAULT_LOADER_ID = 'master';

    RADIO_FIELD = 'radio';

    CHECKBOX_FIELD = 'checkbox';

    TEXT_FIELD = 'text';

    SELECT_FIELD = 'select';

    USA_COUNTRY_CODE = 'US';

    APPLICATION_DOWNLOAD = 'APPLICATION_DOWNLOAD';

	RECEIPT_DOWNLOAD = 'RECEIPT_DOWNLOAD';

    REQUEST_RESOURCE_BACK_OFFICE = 'BACK_OFFICE';

    INCOMPLETE = 'INCOMPLETE';

    UNPAID = 'UNPAID';

    PAID = 'PAID';

    CHINA = 'cn';

    KENYA = 'KE';

    ALLOW_IMAGE_LIST = 'image/jpg,image/png,image/jpeg';

    MARRIAGE = 'MARRIAGE';

    DIVORCE = 'DIVORCE';

    DECEASED_SPOUSE = 'DECEASED_SPOUSE';

    OTHERS = 'OTHERS';

}
