<app-header></app-header>

<div class="banner-img-name mb10"></div>


<main class="body_section">

    <div class="main">
        <fieldset class="">
            <div class="step_info">
                <span>Test Photo Upload</span>
            </div>

            <div>
                <form #imageUploadForm="ngForm" (ngSubmit)="imageUploadForm.form.valid && captchaComponent.verifyCaptcha()" name="imageUploadForm" id="msform" autocomplete="off" style="padding: 0;">
                    <ng-template #instructions>
                        <ul class="details_photo">
                            <li> Image size should be less than {{constants.USER_IMAGE_REQUIRED_SIZE_IN_MB}}MB.  </li>
                            <li> Image resolution should be 600x800 px.</li>
                            <li> Must have white background.  </li>
                            <li> Hair should not cover the face or ears.  </li>
                            <li> Do not use any apparel-headphone, mask etc. </li>
                            <li> Should hove proper lighting-neither too dark, nor too bright.</li>
                            <li> ICAO guidelines have relaxation for kids under the age of 5. Kids must look toward the camera and the face should be centered</li>
                        </ul>
                    </ng-template>
                    
                    <div class="modal-body">
                        <div class="centered">
                            <details>
                                <summary>Photo Instructions </summary>
                            
                                <div class="content">
                                    <img src="assets/images/dummy.png" alt="dummy">
                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template> 
                                </div>
                            </details>
                            <div class="grid-photo-two">
                                <div class="grid-boxs">
                                    <div *ngIf="showDummyImage === true" class="photo_box icon_dummy">
                                        <i class="fa fa-file-image-o" aria-hidden="true"></i>
                                    </div>
                                    <ngx-super-croppie
                                        *ngIf="enableImageCropper"
                                        #ngxSuperCroppie
                                        [croppieOptions]="croppieOptions"
                                        [url]="uploadedFileBase64Data"
                                        [orientation]="orientation"
                                        [zoom]="zoom"
                                        [resultOptions]="resultOptions"
                                        (result)="updateCroppedImage($event)"
                                    ></ngx-super-croppie>
                                    <p style="padding-top: 13px;position: relative;">
                                        <span class="alert alert-danger" style="position: absolute; top: 0;" role="alert" *ngIf="imageUploadForm.submitted && base64Data.invalid">
                                            <span *ngIf="base64Data.errors.required">
                                                Photo is required!
                                            </span>
                                        </span>
                                    </p>
                                    <div class="file_upload1 w100 mb10">
                                        <input type="hidden" name="base64Data" [(ngModel)]="uploadedFileBase64Data" #base64Data="ngModel" required="required">
                                        
                                        <button type="button" class="filt--upload">
                                            <input type="file" [accept]="requestObject.allowFileTypeList" (change)="uploadFile($event)" id="myFile" class="upload up">
                                            <span id="upload" class="upl">Upload Photo</span>
                                        </button>
                                    </div>
                                </div>
            
                                <div class="grid-boxs">
                                    <div class="instructionss">
                                        <div class="element none_dis">
                                            <div class="grid-two">
                                                <div class="grid-boxs">
                                                    <h4>Passport Photo Dimensions</h4>
                                                    <img src="assets/images/dummy.png" alt="dummy">
                                                </div>
                                                <div class="grid-boxs">
                                                    <h4 >Instructions:</h4>
                                                    <ng-template [ngTemplateOutlet]="instructions"></ng-template>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div *ngIf="compliants.length > constants.FALSE" class="issue_box">
                                        <h4 style="color:red;"> <span style="font-size: 15px;"><i class="fa fa-times-circle " aria-hidden="true"></i></span> ICAO Compliance Issue(s) </h4>
                                        <ul class="issues">
                                            <li *ngFor="let compliant of compliants" >{{compliant}}</li>
                                        </ul>
                                    </div>
                                    
                                    <div *ngIf="isValidImage === true" class="issue_box">
                                        <h4 style="color:#0d7836;"><span style="font-size: 15px;"><i class="fa fa-check-circle" aria-hidden="true"></i></span> ICAO Compliance Status </h4>
                                        <ul class="issues">
                                            <li>Uploaded image is valid as per the ICAO Compliances.
                                                <ul>
                                                    <li><a href="javascript:void(0)" (click)="gui.openNINVerificationPage(constants.FRESH)" style="color: #F96922;font-weight: bold;">Click Here</a> to proceed for Fresh Passport Application.</li>
                                                    <li><a href="javascript:void(0)" (click)="gui.openNINVerificationPage(constants.RENEW)" style="color: #F96922;font-weight: bold;">Click Here</a> to proceed for Renewal/Re-issue of your Passport Application.</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="captcha captchaForm relative mb10">
                            <app-captcha [currentForm]="imageUploadForm" (callbackFunction)="confirm()" ></app-captcha>
                        </div>
                    </div>

                    
            
                    <div class="modal-footer">
                        <button type="submit" class="btn">Check Image</button>
                        <button type="button" class="back_btn" (click)="openDashboard()">Back</button>
                    </div>
                </form>
            </div>
        

        </fieldset>

    </div>

</main>

<app-footer></app-footer>
