import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { GUI } from '../common/gui';
import { FilePreviewModalComponent } from '../file-preview-modal/file-preview-modal.component';
import { environment } from 'src/environments/environment';
import { ServerResponse } from 'http';

@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.css']
})
export class ApplicationViewComponent implements OnInit {

  private encryptedApplicantId: string = this.constants.BLANK;

  private applicantId: string = this.constants.BLANK;

  private encryptedRequestResource: string = this.constants.BLANK;

  requestResource: string = this.constants.BLANK;

  barCodeValue: string = this.constants.BLANK;

  isTermConditionSelect: boolean = false;

  isCaptchaVerified: boolean = false;

  confirmationButtonClicked: boolean = false;

  isPaymentDetailsExist: boolean = false;

  showPremiumPaymentAlert: boolean = false;

  passportTypeDetailsObject: any = {};

  personalDetailsObject: any = {};

  contactDetailsObject: any = {};

  kinDetailsObject: any = {};

  feeDetailsObject: any = {};


  isContactlessStatus: boolean = false;

  paymentDetailsObject: any = {};

  serverContactlessStatus: boolean = false;

  passportDeliveryDetailsObject: any = {};

  applicableDocsList: any[] = [];

  codFieldsList: any = [];

  private applicationViewForm: HTMLFormElement;

  enablePassportDeliveryStage: boolean = environment.enablePassportDeliveryStage;

  passportPremiumServiceUrl: string = environment.passportPremiumServiceUrl;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants, private loaderService: LoaderService,
    private simpleModalService: SimpleModalService, private localStorage: LocalStorage, private tags: Tags, public utils: Utils, public gui: GUI) {
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
      this.encryptedRequestResource = params['requestResource'];
    });
    if (this.utils.isEmpty(this.encryptedApplicantId) === false) {
      this.applicantId = this.utils.decryptString(this.encryptedApplicantId);
    }
    if (this.utils.isEmpty(this.encryptedRequestResource) === false) {
      this.requestResource = this.utils.decryptString(this.encryptedRequestResource);
    }
    this.getContactlessStatus();

    this.personalDetailsObject.base64Data = this.constants.DEFAULT_IMAGE;
    this.storeApplicationViewData();

  }

  ngOnInit(): void {
    this.applicationViewForm = document.forms['applicationViewForm'];
  }

  private storeApplicationViewData(): void {
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationViewData(this.applicantId, this.requestResource).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if (serverResponseCode === this.constants.STATUS_SUCCESS) {
        this.passportTypeDetailsObject = serverResponse.passportDetails;
        this.personalDetailsObject = serverResponse.personalInfo;
        this.personalDetailsObject.paymentStatusImageURL = "assets/images/" + this.personalDetailsObject.paymentStatusImageName;
        this.contactDetailsObject = serverResponse.contactInfo;
        this.kinDetailsObject = serverResponse.kinInformation;
        this.feeDetailsObject = serverResponse.feeDetails;
        this.codFieldsList = serverResponse.codFields;
        this.passportDeliveryDetailsObject = serverResponse.deliveryInfo;

        this.barCodeValue = this.personalDetailsObject.applicationId;
        this.paymentDetailsObject = serverResponse.paymentDetails;
        this.isPaymentDetailsExist = serverResponse.paymentDetails !== null;
        this.applicableDocsList = serverResponse.applicableDocsList;
        this.getServerContactlessStatus();

        // this.personalDetailsObject.approvalStatus = serverResponse.approvalStatus;
        // this.personalDetailsObject.approvalStatusLabel = serverResponse.approvalStatusLabel;
        this.personalDetailsObject.approvalStatusLabelClass = this.utils.getStatusClass(this.personalDetailsObject.approvalStatus);
        this.showPremiumPaymentAlert = this.personalDetailsObject.paymentStatus === this.constants.STATUS_SUCCESS && this.passportTypeDetailsObject.isPremium === this.constants.TRUE && this.passportTypeDetailsObject.premiumPaymentStatus !== this.constants.STATUS_SUCCESS;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToPage(): void {
    this.gui.openHomePage();
  }

  editApplication(): void {
    this.gui.openApplicationAddEditPage(this.applicantId);
  }

  showConfirmationForApplicationSubmission(isSaveNow: number): void {
    this.confirmationButtonClicked = true;
    if (this.isTermConditionSelect === false) {
      return;
    }
    let message = "APPLICATION NUMBER <b>" + this.personalDetailsObject.applicationId + "</b> & REFERENCE NUMBER <b>" + this.personalDetailsObject.referenceNumber + "</b> (IMPORTANT - PLEASE WRITE DOWN THE REFERENCE NUMBER AND APPLICATION NUMBER. YOU MAY REQUIRE IT LATER)";
    if (isSaveNow === this.constants.TRUE) {
      message = "<span>Note that by pressing OK, your application will be submitted and there will be no correction of data after payment. Are you sure you want to continue?</span>"
    }
    this.simpleModalService.addModal(ConfirmationModalComponent, { message: message }, { autoFocus: true }).subscribe((isConfirmed: boolean) => {
      if (isConfirmed === true) {

        this.confirmApplicationSubmission(isSaveNow);
      }
    });
  }



  private formatDate(date: any): string | null {
    if (!date) return null;
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }


  private extractNumber(value: string): number | null {
    if (!value) return null;
    const match = value.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }




  private extractPassportSize(passportSize: string): number | null {
    if (!passportSize) return null;
    const match = passportSize.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  private createPayload(): any {
    return {
      'application_id': this.personalDetailsObject?.applicationId,
      'applicant_id': this.personalDetailsObject?.applicantId,
      'reference_id': this.personalDetailsObject?.referenceNumber,
      'last_name': this.personalDetailsObject?.lastName,
      'first_name': this.personalDetailsObject?.firstName,
      'middle_name': this.personalDetailsObject?.middleName,
      'date_of_birth': this.formatDate(this.personalDetailsObject?.dateOfBirth),
      'sex': this.personalDetailsObject?.gender,
      'place_of_birth': this.personalDetailsObject?.placeOfBirth,
      'nin': this.personalDetailsObject?.ninNumber,
      'email': this.personalDetailsObject?.emailId,
      'phone': `${this.contactDetailsObject?.mobileNumberDialCode}${this.contactDetailsObject?.mobileNumber}`,
      'processing_office': this.passportTypeDetailsObject?.processingOffice,
      'processing_state': this.passportTypeDetailsObject?.processingState,
      'processing_country': this.passportTypeDetailsObject?.processingCountry,
      'application_type': this.passportTypeDetailsObject?.applicationTyp,
      'reason': 0,
      'passport_size': this.extractPassportSize(this.passportTypeDetailsObject?.bookletType),
      'passport_type': this.personalDetailsObject?.passportType,
      'validity': this.extractNumber(this.passportTypeDetailsObject?.passportValidity),
      'old_document_number': this.passportTypeDetailsObject?.passportNumber,
      'amount': this.feeDetailsObject?.totalFee,
      'currency': this.feeDetailsObject?.currencyCode,
      'payment_date': this.formatDate(this.personalDetailsObject?.paymentDate) || null,
      'expiry_date': this.formatDate(this.personalDetailsObject?.expiryDate) || null,
      'applicant_photo': this.personalDetailsObject?.base64Data,
      'contactless_status': this.isContactlessStatus ? 1 : 0,
    };
  }



  private confirmApplicationSubmission(isSaveNow: number): void {
    this.loaderService.startLoader();
    const payload = this.createPayload();
    if (this.isContactlessStatus) {
      this.httpUtils.submitToEsb(payload);
      this.updateContactlessStatus();
    }

    this.httpUtils.confirmApplicationSubmission(this.applicantId, isSaveNow).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if (serverResponseCode === this.constants.STATUS_SUCCESS) {
        if (isSaveNow === this.constants.FALSE) {
          if (this.isContactlessStatus) {
            this.storeApplicationInfo();
            this.gui.openContactlessPage();

          } else {
            this.gui.openHomePage();

          }

        } else {
          this.gui.openPaymentPage(this.encryptedApplicantId, this.constants.APPLICATION_PAYMENT);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }



  private storeApplicationInfo(): void {
    localStorage.setItem("applicationId", this.personalDetailsObject?.applicationId);
    localStorage.setItem("referenceId", this.personalDetailsObject?.referenceNumber);

  }


  private getContactlessStatus(): void {
    this.isContactlessStatus = JSON.parse(localStorage.getItem("contactlessStatus")) || false;
  }


  private getServerContactlessStatus(): void {
    this.httpUtils.fetchContactlessStatus(
      this.personalDetailsObject?.applicationId,
      this.personalDetailsObject?.referenceNumber
    ).then(serverResponse => {
      console.log("service reponse", serverResponse);
      if (serverResponse?.responseCode) {
        localStorage.setItem("contactlessStatus", JSON.stringify(false));
      }
      else {
        localStorage.setItem("contactlessStatus", serverResponse.contactlessStatus);
        this.isContactlessStatus = serverResponse.contactlessStatus;
      }
    }).catch(err => console.log("service reponse2222", err)
    );
  }


  private updateContactlessStatus(): void {
    localStorage.setItem("contactlessStatus", JSON.stringify(false));
  }






  printApplication(): void {
    window.print();
  }

  openPaymentPage(): void {
    this.gui.openPaymentPage(this.encryptedApplicantId, this.constants.APPLICATION_PAYMENT);
  }

  bookAppointment(): void {
    const base64String = btoa(this.personalDetailsObject.applicationId + "|" + this.personalDetailsObject.referenceNumber);
    const url = environment.appointmentApplicationUrl + "?a=" + base64String;
    window.open(url, "_blank");
  }

  downloadPDF(): void {
    this.applicationViewForm.action = environment.serverBaseUrl + "v1/application/downloadApplicationPDF";
    this.applicationViewForm.downloadType.value = this.constants.RECEIPT_DOWNLOAD;
    this.applicationViewForm.submit();
  }

  openFilePreviewModal(applicableDocObject: any = {}): void {
    this.simpleModalService.addModal(FilePreviewModalComponent, { applicableDocObject }, { autoFocus: true, closeOnEscape: true }).subscribe();
  }

  openPremiumApplication(): void {
    const url = environment.passportPremiumServiceUrl;
    window.open(url, '_blank');
  }

}